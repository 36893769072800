export const uploadFile = async (path: string) => {
  const response = await fetch(path)
  const blob = await response.blob()

  return {
    src: URL.createObjectURL(blob),
    originalSrc: URL.createObjectURL(blob),
    file: new File([blob], path, blob),
  }
}
