import { AuthMethod } from 'src/constants/authentication'

const APPLE_HIDDEN_EMAIL = 'privaterelay.appleid.com'

export const isEmailHiddenByApple = (email: string, tokenType: AuthMethod) => {
  if (!email || tokenType !== AuthMethod.apple) {
    return false
  }
  return email.includes(APPLE_HIDDEN_EMAIL)
}
