import { Instance, types } from 'mobx-state-tree'

import { StyleType } from 'src/constants/styles'

export interface IStyle extends Instance<typeof Style> {}

/**
 * Модель стиля фильтра
 */
export const Style = types.model('Style', {
  id: types.string,
  image_url: types.optional(types.string, ''), // @todo camelcase
  artwork: types.optional(types.string, ''),
  model: types.optional(types.string, ''),
  paid: types.optional(types.boolean, true),
  type: types.optional(
    types.enumeration('StyleType', [...Object.values(StyleType)]),
    StyleType.Server
  ),
  collection_name: types.optional(types.string, ''),
})

export default Style
