// Mobile
export const LANDING = '/'

// Desktop
export const AUTH = '/'
export const APP = '/'
export const CONFIRM_EMAIL = '/confirm/email/:userId'
export const AUTH_APPLE = '/apple'
export const ONBOARDING = '/onboarding'
// export const APPLE = '.well-known/apple-developer-merchantid-domain-association'

// Checkout flow #1
export const CHECKOUT_FLOW_SIGN = '/checkout/sign' // base route for sign page
export const CHECKOUT_FLOW_END = '/checkout/end' // base route for end page

export const CHECKOUT_FLOW_APPLE = '/checkout/apple'

const ROUTES = {
  LANDING,

  AUTH,
  APP,
  CONFIRM_EMAIL,
  AUTH_APPLE,
  ONBOARDING,

  CHECKOUT_FLOW_SIGN,
  CHECKOUT_FLOW_END,
  CHECKOUT_FLOW_APPLE,
}

export default ROUTES
