import { flow, Instance } from 'mobx-state-tree'

import { Resolution } from 'src/constants/editor/resolution'
import { EditorImageState } from 'src/constants/editor/image'
import { EXAMPLES_ID_HD, EXAMPLES_ID } from 'src/constants/example-images'

import { ThirdImageExpansion } from '../index'
import { compress } from '../utils'

export default (self: Instance<typeof ThirdImageExpansion>) => ({
  setResolution: flow(function* (r: Resolution) {
    self.state = EditorImageState.processing // maybe we need to have another state here

    try {
      const index = self.rootStore.editor.exampleImages.selectedIndex

      self.resolution = r

      // TODO: maybe think about more elegant solution
      // @ts-ignore
      if (Object.values(EXAMPLES_ID_HD).includes(self.id)) {
        self.changeExampleOriginResolution(
          self.rootStore.editor.exampleImages.imagesHd[index]
        )
      }

      // @ts-ignore
      if (Object.values(EXAMPLES_ID).includes(self.id)) {
        self.changeExampleOriginResolution(
          self.rootStore.editor.exampleImages.images[index]
        )
      }

      if (!self.id) {
        yield self.compressAndUpload({ doesResolutionChange: true })
      }

      const blob = yield compress({
        file: self.file,
        resolution: self.resolution,
      })

      if (self.activeStyle) {
        self.originalSrc = URL.createObjectURL(blob)
        yield self.process()
      }
    } catch (e) {
      self.resolution = r === Resolution.HD ? Resolution.SD : Resolution.HD
      self.state = EditorImageState.processed
      throw e
    }
  }),
})
