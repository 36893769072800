import React, { Component } from 'react'

import { Button, ButtonSize, ButtonView } from 'src/components/button'
import { capitalizeFirstLetter } from 'src/utils/string'
import { Content, PlanInfo, PlanVersion, UploadsLeft } from './styles'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { PlanAlias } from 'src/constants/payment-flow'

export type UploadsCounterProps = ReturnType<typeof storesToProps>

class UploadsCounter extends Component<UploadsCounterProps> {
  public handleUpgrade = async () => {
    const { subscription } = this.props
    try {
      await subscription.openPurchaseModal('unsplash')
    } catch (e) {
      throw e
    }
  }

  public planInfo = () => {
    const {
      account: { subscription },
    } = this.props
    let planInfo: string

    if (!subscription) {
      planInfo = 'Free plan'
    } else {
      if (
        subscription.active ||
        subscription.platform === 'android' ||
        subscription.platform === 'ios'
      ) {
        planInfo = `${capitalizeFirstLetter(subscription.plan)} plan`
      } else {
        planInfo = 'Free plan'
      }
    }
    return planInfo
  }

  public render() {
    const { account } = this.props

    return (
      <Content>
        <PlanInfo>
          <PlanVersion>{this.planInfo()}</PlanVersion>
          <UploadsLeft>Uploads left today: {account?.realCounter}</UploadsLeft>
        </PlanInfo>
        {/* {account.subscription?.plan.toLocaleLowerCase() !== PlanAlias.pro && (
          <Button
            view={ButtonView.CTA}
            size={ButtonSize.Small}
            onClick={this.handleUpgrade}
          >
            Upgrade
          </Button>
        )} */}
      </Content>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  modal: store.modal,
  subscription: store.subscription,
})

export default connector(storesToProps)(UploadsCounter)
