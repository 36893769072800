import React from 'react'

import { observer } from 'mobx-react'

import {
  InputPlaceholder,
  StyleWrapper,
  StylePlaceholder,
  Wrapper,
} from './styles'

export const UnsplashPlaceholder: React.FC = () => {
  return (
    <Wrapper>
      <InputPlaceholder />

      <StyleWrapper>
        {[...new Array(12)].map((_, i) => (
          <StylePlaceholder key={i} />
        ))}
      </StyleWrapper>
    </Wrapper>
  )
}

export default observer(UnsplashPlaceholder)
