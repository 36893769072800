import Auth from './sections/auth'
import Processing from './sections/processing'
import Styles from './sections/styles'
import Subscriptions from './sections/subscriptions'
import Uploads from './sections/uploads'
import User from './sections/user'
import Tags from './sections/tags'

import { IAPIService } from './types'

class Kit {
  public auth: Auth
  public styles: Styles
  public uploads: Uploads
  public processing: Processing
  public user: User
  public subscription: Subscriptions
  public tags: Tags

  protected service: IAPIService

  public constructor(service: IAPIService) {
    this.service = service

    this.auth = new Auth(service)
    this.styles = new Styles(service)
    this.uploads = new Uploads(service)
    this.processing = new Processing(service)
    this.user = new User(service)
    this.subscription = new Subscriptions(service)
    this.tags = new Tags(service)
  }
}

export default Kit
