import React, { Component } from 'react'

import { GOOGLE_ID } from 'src/constants/analytics/analytics-account-keys'

class GoogleAnalytics extends Component {
  public componentDidMount() {
    window.dataLayer = window.dataLayer || []
    // tslint:disable-next-line:only-arrow-functions
    window.gtag = function () {
      dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', GOOGLE_ID)
  }

  public render() {
    return <></>
  }
}

export default GoogleAnalytics
