import { css } from 'src/styles'

/**
 * Первичный стиль кнопок
 * Используются:
 */
export default css`
  ${({ theme }) => {
    return `
      color: ${theme.buttonsColors.default.color.primary};
      background-color: ${theme.buttonsColors.default.background.primary};
      
      &:hover:not(:disabled) {
        color: ${theme.buttonsColors.hover.color.primary};
        background-color: ${theme.buttonsColors.hover.background.primary};
        
        svg {
          fill: ${theme.buttonsColors.hover.color.primary};
        }
      }
      
      &:disabled {
        opacity: 0.36;
      }
      
      .loader {
        border-color: ${theme.buttonsColors.default.color.primary};
        border-bottom-color: transparent;
      }
    `
  }}
`
