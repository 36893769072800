import { Instance, types } from 'mobx-state-tree'

import { ISubscriptionResponse } from 'src/api/sections/subscriptions/types'
import { SubType } from 'src/constants/subscription'
import { PlanAlias } from 'src/constants/payment-flow'

export interface ISubscription extends Instance<typeof Subscription> {}

export const Subscription = types
  .model('Subscription', {
    id: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    plan: types.maybeNull(types.string),
    expirationDate: types.maybeNull(types.string),
    platform: '',
    isValid: false,
  })
  .views((self) => ({
    get active() {
      return (
        self.status === SubType.Active ||
        (self.status === SubType.Canceled &&
          new Date(self.expirationDate).getTime() > Date.now()) ||
        self.isValid
      )
    },
    get canCancel() {
      return self.status === SubType.Active
    },
    get canUpgrade() {
      return self.plan !== PlanAlias.pro
    },
    get formattedDate() {
      const date = new Date(self.expirationDate)

      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const month = date.toLocaleString('en-US', { month: 'short' })
      const year = date.getFullYear()

      return `${month} ${day}, ${year}`
    },
  }))

export const getSubscriptionModel = ({
  status,
  plan,
  is_valid,
  expiration_date,
  platform,
  subscription_id,
}: ISubscriptionResponse) => {
  return Subscription.create({
    status,
    platform,
    plan,
    isValid: is_valid,
    id: subscription_id,
    expirationDate: expiration_date,
  })
}

export default Subscription
