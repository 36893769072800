import styled from 'src/styles'

export const Wrapper = styled.div`
  .container {
    width: 100%;
    height: 48px;
  }

  .character {
    width: 44px;

    padding: 12px 14px;
    margin-right: 5px;

    color: ${(props) => props.theme.palette.label.primary};
    background-color: ${(props) => props.theme.palette.fill.tertiary};
    border: 1px solid rgba(0, 0, 0, 0);

    border-radius: 12px;

    ${(props) => props.theme.typography.objToCss('bold20')}
    cursor: text;
  }

  .character--selected {
    color: ${(props) => props.theme.palette.label.primary};
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.accent};
    box-shadow: 0 0 0 2px ${(props) => props.theme.accent};
  }
`
