// @ts-nocheck
import localforage from 'localforage'

const isIndexedDBEnabled =
  (window.RequestFileSystem || window.webkitRequestFileSystem) &&
  (window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB)
let incognitoFile: File | null = null

export const setIndexedDBFile = async (fileToSave: File) => {
  if (isIndexedDBEnabled) {
    await localforage.setItem('imageFileToUpload', fileToSave)
  } else {
    incognitoFile = fileToSave
  }
}

export const getIndexedDBFile = async (): Promise<File | null> => {
  return isIndexedDBEnabled
    ? await localforage.getItem('imageFileToUpload')
    : incognitoFile
}

export const setIndexedDBFileFromUrl = async (
  url: string,
  filename: string
) => {
  const blob = await fetch(url).then((response) => response.blob())
  const file = new File([blob], filename, { type: 'image/jpeg' })

  await setIndexedDBFile(file)
}
