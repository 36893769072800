import React from 'react'

import AppleLogin from 'react-apple-login'

import { API_URL } from 'src/api/env'
import { APPLE_WEB_CLIENT_ID } from 'src/constants/authentication'
import { ButtonSize, ButtonView } from 'src/components/button'
import { Button } from 'src/components/button-google-login/styles'
import appleIcon from 'assets/icons/apple.svg'

export interface IButtonAppleLoginProps {
  id?: string
  className?: string
  timeZoneOffset?: number
  redirectUrl?: string
  deviceId: string
  loading?: boolean
  onClick?: () => void
}

// Service waits info about `platform`, `device_id` and `app` joined by '_' symbol.
const STATE_PLATFORM_DEVICE_ID_APP = (
  deviceId: string,
  redirectUrl: string,
  timeZoneOffset: number
): string =>
  `%7B"platform": "web", "app": "prisma", "appsflyer_id": "AppsflyerID1", "device_id": "${deviceId}", "redirect_url": "${redirectUrl}", "timezone_offset": "${timeZoneOffset}"%7D`

// Important: apple won't work with localhost API_URL.
/**
 * Кнопка авторизации через AppleID
 * Находится в модальном окне авторизации
 * @param id string - html-атрибут ID
 * @param className string - css класс
 * @param timeZoneOffset string - временная зона
 * @param redirectUrl string - ??
 * @param deviceId string - идентификатор девайся, необходим для авторизации на бэке
 * @param loading - параметр, определяющий, идёт ли какой-либо процесс загузки
 * @param onClick - родительский callback
 * @constructor
 */
export const ButtonAppleLogin: React.FC<IButtonAppleLoginProps> = ({
  id,
  className,
  timeZoneOffset,
  redirectUrl,
  deviceId,
  loading,
  onClick,
}: IButtonAppleLoginProps) => (
  <AppleLogin
    clientId={APPLE_WEB_CLIENT_ID}
    redirectURI={API_URL + (redirectUrl || '/apple')}
    responseMode="form_post"
    scope="name email"
    responseType="code"
    state={STATE_PLATFORM_DEVICE_ID_APP(deviceId, redirectUrl, timeZoneOffset)}
    render={({ onClick: click }) => (
      <Button
        id={id}
        className={className}
        view={ButtonView.Secondary}
        size={ButtonSize.Medium}
        startIcon={appleIcon}
        onClick={() => {
          if (onClick) {
            onClick()
          }
          click()
        }}
        isLoading={loading}
        fullWidth
      >
        Login with Apple
      </Button>
    )}
  />
)

export default ButtonAppleLogin
