import styled from 'src/styles'
import { flicker } from 'src/containers/library/style-block/style-cell/styles'

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.cellar};
`

export const ButtonPlaceholder = styled.div`
  width: 120px;
  height: 40px;
  border-radius: 10px;

  background-color: ${({ theme }) => theme.palette.background.base};
  animation: ${flicker} 1s linear infinite;
`

export const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`

export const PlanVersion = styled.div`
  ${({ theme }) => theme.typography.objToCss('bold13')}
`

export const UploadsLeft = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg13')}
`
