import { css } from 'src/styles'

/**
 * Кнопки CTA (Call-to-Action)
 * Используются: кнопка логина
 */
export default css`
  ${({ theme }) => {
    return `
      color: ${theme.buttonsColors.default.color.CTA};
      background-color: ${theme.buttonsColors.default.background.CTA};
      
      &:hover:not(:disabled) {
        color: ${theme.buttonsColors.hover.color.CTA};
        background-color: ${theme.buttonsColors.hover.background.CTA};
      }
     
      &:disabled {
        opacity: 0.36;
        
        svg {
          opacity: 0.36;
        }
      }
    `
  }}
`
