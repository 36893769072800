import styled from 'src/styles'
import { ModalTitleIcon } from 'src/components/modal/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 24px - 80px - 50px);
`

export const UnsplashHeader = styled.div`
  ${({ theme }) => theme.typography.objToCss('bold14')}
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.black[100]};
  padding: 40px 202px 26px;

  @media (max-width: 1279px) {
    padding: 40px 12% 26px;
    flex-wrap: wrap;
    gap: 10px 0;
  }
`

export const SubscribeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 32px 28px 202px;
  background-color: ${({ theme }) => theme.palette.background.cellar};

  @media (max-width: 1279px) {
    padding: 28px 32px 28px 12%;
  }
`

export const CloseIcon = styled(ModalTitleIcon)`
  position: static;
`

export const Login = styled.p`
  ${({ theme }) => theme.typography.objToCss('reg14')}
  cursor: pointer;

  & span {
    color: ${({ theme }) => theme.accent.accent};
    ${({ theme }) => theme.typography.objToCss('bold14')}
  }
`

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin-right: 20px;

    @media (max-width: 1200px) {
      display: none;
    }
  }
`
