import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree'

import API from 'src/api'
import { stripe } from 'src/components/stripe'
import { ModalName } from 'src/containers/modal-root'
import { ICreateSubscriptionOptions } from 'src/api/sections/subscriptions/types'
import { PAYMENT_INTENT_STATUSES } from 'src/constants/subscription'

import { IStore } from '../index'

import { preFillSubscriptionOptions } from './utils'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'

export const Subscription = types
  .model('Subscription')
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    openOutOfImportsModal(source: string) {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.alertShow, {
        source,
        context: 'out_of_imports',
      })
      self.rootStore.modal.open(ModalName.outOfImports)
    },
    openUpgradeHdModal() {
      self.rootStore.modal.open(ModalName.upgradeHD)
    },
    openPurchaseModal(source: string) {
      AnalyticsController.sendEventWrapper(
        EVENT_NAMES.PURCHASE.upgradePlanTap,
        {
          source,
          current_subscription_type:
            self.rootStore.account.subscription?.plan || 'free',
        }
      )
      self.rootStore.modal.open(ModalName.paymentFlow, { source })
    },
    openPurchaseModalFromSubscription(source: string) {
      AnalyticsController.sendEventWrapper(
        EVENT_NAMES.PURCHASE.upgradePlanTap,
        {
          source,
          current_subscription_type:
            self.rootStore.account.subscription?.plan || 'free',
        }
      )
      self.rootStore.modal.open(ModalName.paymentFlow, { source }).catch(() => {
        self.rootStore.modal.open(ModalName.account)
      })
    },
    purchase: flow(function* (
      options: ICreateSubscriptionOptions,
      planId: string
    ) {
      try {
        const opt = preFillSubscriptionOptions(options)

        const { paymentMethod, error } = yield stripe.createPaymentMethod(opt)
        if (error)
          return Object.assign(error, { status: PAYMENT_INTENT_STATUSES.error })

        const {
          clientSecret,
          paymentIntentStatus,
        } = yield API.subscription.createSubscription(paymentMethod.id, planId)
        if (
          paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresPaymentMethod
        ) {
          const result = yield stripe.confirmCardPayment(clientSecret, {
            payment_method: opt,
          })

          if (result.error)
            return Object.assign(result.error, {
              status: PAYMENT_INTENT_STATUSES.error,
            })
          return result.paymentIntent
        }

        if (
          paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresConfirmation
        ) {
          const result = yield stripe.confirmCardPayment(clientSecret, {
            payment_method: opt,
          })

          if (result.error)
            return Object.assign(result.error, {
              status: PAYMENT_INTENT_STATUSES.error,
            })
          return result.paymentIntent
        }
        if (paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresAction) {
          const result = yield stripe.confirmCardPayment(clientSecret, {
            payment_method: opt,
          })

          if (result.error)
            return Object.assign(result.error, {
              status: PAYMENT_INTENT_STATUSES.error,
            })
          return result.paymentIntent
        }

        self.rootStore.account.init()

        return Object.assign({}, paymentMethod, {
          status: PAYMENT_INTENT_STATUSES.succeeded,
        })
      } catch (e) {
        return {
          type: 'create_subscription_error',
          status: PAYMENT_INTENT_STATUSES.error,
        }
      }
    }),
    purchaseApplePay: flow(function* (options: any, planId: string) {
      try {
        const {
          clientSecret,
          paymentIntentStatus,
        } = yield API.subscription.createSubscription(
          options.paymentMethod.id,
          planId
        )

        if (
          paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresPaymentMethod
        ) {
          const result = yield stripe.confirmCardPayment(clientSecret, {
            payment_method: options.paymentMethod.id,
          })

          if (result.error)
            return Object.assign(result.error, {
              status: PAYMENT_INTENT_STATUSES.error,
            })
          return result.paymentIntent
        }

        if (
          paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresConfirmation
        ) {
          const result = yield stripe.confirmCardPayment(clientSecret, {
            payment_method: options.paymentMethod.id,
          })

          if (result.error)
            return Object.assign(result.error, {
              status: PAYMENT_INTENT_STATUSES.error,
            })
          return result.paymentIntent
        }

        if (paymentIntentStatus === PAYMENT_INTENT_STATUSES.requiresAction) {
          const result = yield stripe.confirmCardPayment(clientSecret)

          if (result.error)
            return Object.assign(result.error, {
              status: PAYMENT_INTENT_STATUSES.error,
            })
          return result.paymentIntent
        }

        self.rootStore.account.init()

        return Object.assign({}, options.paymentMethod, {
          status: PAYMENT_INTENT_STATUSES.succeeded,
        })
      } catch (e) {
        return {
          type: 'create_subscription_error',
          status: PAYMENT_INTENT_STATUSES.error,
        }
      }
    }),
    reset() {
      applySnapshot(self, {})
    },
  }))
