import React from 'react'

import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'

import { Content, Link, BoldLink, Wrapper } from './styles'

/**
 * Футер с ссылками на политику конфиденциальности и правила пользования
 * Находится внизу окна редактора
 * @constructor
 */
export const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Content>
        <Link
          target="blank"
          href="/terms_of_service.html"
          onClick={() =>
            AnalyticsController.sendEventWrapper(
              EVENT_NAMES.GENERAL.legalTermsTap,
              {
                source: AEventSource.footer,
              }
            )
          }
        >
          Terms of Use
        </Link>
        <span>・</span>
        <Link
          target="blank"
          href="/privacy_policy.html"
          onClick={() =>
            AnalyticsController.sendEventWrapper(
              EVENT_NAMES.GENERAL.legalPrivacyTap,
              {
                source: AEventSource.footer,
              }
            )
          }
        >
          Privacy Policy
        </Link>
        <span>
          ・ © 2016 - {new Date().getFullYear()} Prisma Labs, Inc. |&nbsp;
        </span>
        <BoldLink href="mailto:feedback-web@prisma-ai.com">
          Send feedback
        </BoldLink>
      </Content>
    </Wrapper>
  )
}

export default Footer
