// AMPLITUDE
export const AMPLITUDE_KEY = '33a18db3c1f2cbb9306d75d2274a4da5'

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDhTVsbzmQn6W0rCLvLZpsBz5MnSxf2hIU',
  authDomain: 'prisma-web-8ff4c.firebaseapp.com',
  databaseURL: 'https://prisma-web-8ff4c.firebaseio.com',
  projectId: 'prisma-web-8ff4c',
  storageBucket: 'prisma-web-8ff4c.appspot.com',
  messagingSenderId: '829755162912',
  appId: '1:829755162912:web:9cc9b92c6a2add27f05745',
  measurementId: 'G-GCBEBYT991',
}

// FACEBOOK

export const FACEBOOK_PIXEL_ID = '1026062987854743'
export const FACEBOOK_PIXEL_ID_CHECKOUT_FLOW = '505782873788325'

// GOOGLE

export const GOOGLE_ID = 'UA-157888408-1'

// APPSFLYER

export const APPSFLYER_ID = '46c239ce-408e-49a9-8b13-d8c72c2dd9e3'
