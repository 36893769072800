import styled from 'src/styles'

import { StepState } from './types'

export const StyledStepper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Step = styled.div<{ state: StepState }>`
  ${(props) => props.theme.typography.objToCss('bold13')}
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 50%;

  background-color: ${(props) =>
    props.state === StepState.Inactive
      ? props.theme.palette.fill.secondary
      : props.theme.accent};
`

export const StepDivider = styled.div`
  background-color: ${(props) => props.theme.palette.fill.secondary};

  width: 44px;
  height: 2px;

  margin: 0 4px;
`
