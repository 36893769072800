import styled from 'src/styles'

import Svg from 'src/components/svg'
import { flicker } from 'src/containers/library/style-block/style-cell/styles'
import { Button as ButtonOrigin } from 'src/components/button'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  min-height: 64px;
  margin-bottom: 20px;
  background-color: transparent;
`

export const Logo = styled(Svg)`
  fill: ${(props) => props.theme.palette.label.primary};
`

export const Content = styled.div<{ isSpaceBetween: boolean }>`
  width: 100%;

  display: flex;
  justify-content: ${({ isSpaceBetween }) =>
    isSpaceBetween ? 'space-between' : 'flex-end'};

  margin: 0 auto;
  padding: 0 20px;
`

export const ButtonPlaceholder = styled.div`
  width: 120px;
  height: 40px;
  border-radius: 10px;

  background-color: ${(props) => props.theme.palette.background.base};
  animation: ${flicker} 1s linear infinite;
`

export const SaveImageWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 20px;
  }
`

export const Button = styled(ButtonOrigin)`
  &:disabled {
    opacity: 1;
    color: #808080;
  }
`
