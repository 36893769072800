import React from 'react'
import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'
import { Modal } from 'src/components/modal'

import { ModalTitleIcon } from 'src/components/modal/styles'
import { Body, LockImage, Title, Text } from './styles'
import { ButtonSize, ButtonView } from 'src/components/button'
import { Button, Dismiss } from '../modal-out-of-imports/styles'

import closeIcon from 'assets/icons/close_new.svg'
import unlockImageRetina from 'assets/images/modals/unlock@2x.png'
import unlockImage from 'assets/images/modals/unlock.png'

type ModalUpgradeHDProps = ReturnType<typeof storesToProps>

class ModalUpgradeHd extends React.Component<ModalUpgradeHDProps> {
  public handleUpgrade = async () => {
    const { subscription, modal } = this.props
    modal.close()
    await subscription.openPurchaseModal('upgrade_hd')
  }

  public render() {
    const { modal } = this.props

    return (
      <Modal width={420} onClose={modal.close}>
        <ModalTitleIcon
          onClick={modal.close}
          position={'top: 40px; right: 40px;'}
          icon={closeIcon}
        />
        <Body>
          <picture>
            <source srcSet={`${unlockImageRetina} 2x, ${unlockImage} 1x`} />
            <LockImage src={unlockImage} width={100} height={100} />
          </picture>
          <Title>Upgrade for HD</Title>
          <Text>
            HD processing is not available in your <br />
            current plan. Please upgrade to <br />
            unlock this feature.
          </Text>
          <Button
            view={ButtonView.Primary}
            size={ButtonSize.Medium}
            onClick={this.handleUpgrade}
          >
            Upgrade now
          </Button>
          <Dismiss onClick={modal.close}>Dismiss</Dismiss>
        </Body>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  subscription: store.subscription,
  modal: store.modal,
})

export default connector(storesToProps)(ModalUpgradeHd)
