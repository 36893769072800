import { IStyle } from 'src/models/library/style'
import { StyleType } from '../../constants/styles'
import { ILibrary } from 'src/models/library/library'
import { IV2GetLibraryAllResponseCollection } from '../../api/sections/styles/types'

export const librarySearch = (
  foundStylesBackend: string[],
  blocks: ILibrary[],
  isLoggedIn: boolean,
  paidPlan: string
): IStyle[] => {
  // container has all found styles available as keys, they are prefilled with null
  const foundStylesContainer = foundStylesBackend.reduce((prev, curr) => {
    prev[curr] = null
    return prev
  }, {} as Record<string, { style: IStyle; isAvailable: boolean } | null>)

  const accountHasNoRights = !isLoggedIn || !paidPlan

  let foundUnlockedStyles = false
  blocks.forEach((block) => {
    block.styles.forEach((style) => {
      const foundStyle = foundStylesContainer[style.id]
      if (foundStyle !== undefined) {
        const isFreeStyle = style.type !== StyleType.v1
        const isLockedStyle = accountHasNoRights && !isFreeStyle
        if (!isLockedStyle) {
          foundUnlockedStyles = true
        }

        // add style to container only when it's first found style or when it's unlocked
        if (foundStyle === null || !isLockedStyle) {
          foundStylesContainer[style.id] = {
            style,
            isAvailable: !isLockedStyle,
          }
        }
      }
    })
  })

  const foundStyles = Object.values(foundStylesContainer).filter((val) => val)
  if (foundUnlockedStyles) {
    foundStyles.sort((a, b) => +b.isAvailable - +a.isAvailable)
  }
  return foundStyles.map(({ style }) => style)
}

export const applyCollectionNameToStyles = ({
  id,
  name,
  styles,
}: IV2GetLibraryAllResponseCollection) => {
  return {
    id,
    name,
    styles: styles.map((style) => ({ ...style, collection_name: name })),
  }
}
