import React from 'react'

import { ISVG } from '*.svg'

export enum ButtonView {
  CTA = 'cta',
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
}

export enum ButtonSize {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm',
}

export interface ButtonProps {
  id?: string
  className?: string

  startIcon?: ISVG
  endIcon?: ISVG

  children?: React.ReactNode

  onClick?: (e: React.MouseEvent) => void

  view?: ButtonView
  size?: ButtonSize

  fullWidth?: boolean

  disabled?: boolean
  isLoading?: boolean
}
