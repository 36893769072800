import styled from '../../styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const InputWrapper = styled.div`
  display: flex;

  color: ${(props) => props.theme.palette.label.tertiary};

  & svg {
    fill: #777777;
    margin-left: 5px;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.label.primary};
    & svg {
      fill: ${({ theme }) => theme.palette.label.primary};
    }
  }
`

export const SaveImageInput = styled.span`
  box-sizing: border-box;
  margin: 0;
  width: auto;
  min-width: 5px;
  font-family: Arial, sans-serif;
  border: none;
  background: transparent;
  outline: none;
  resize: none;
  white-space: nowrap;
  overflow: hidden;
  ${({ theme }) => theme.typography.objToCss('bold13')}
  color: inherit;

  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
    font-family: Arial, sans-serif;
    color: ${({ theme }) => theme.palette.label.primary};
  }

  &:focus {
    font-weight: 400;
    ~ svg {
      display: none;
    }
  }
`

export const ImageInfo = styled.span`
  ${({ theme }) => theme.typography.objToCss('reg13')}
  margin-top: 4px;
  color: ${(props) => props.theme.palette.label.tertiary};
`
