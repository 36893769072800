import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { Button, ButtonView, ButtonSize } from 'src/components/button'
import { ModalName } from 'src/containers/modal-root'
import { AEventSource } from 'src/constants/analytics/event-sources'

import logoSvg from 'assets/images/app-identity/prisma_logo_name.svg'

import {
  ButtonPlaceholder,
  Content,
  Logo,
  Wrapper,
  LogoWrapper,
} from './styles'

export type HeaderProps = ReturnType<typeof storesToProps>

class Header extends Component<HeaderProps> {
  public render() {
    const { session, modal, authentication, isAllLoaded } = this.props

    return (
      <Wrapper>
        <Content>
          <LogoWrapper>
            <Logo icon={logoSvg} />
          </LogoWrapper>

          {isAllLoaded ? (
            session.isLoggedIn ? (
              <Button
                view={ButtonView.Secondary}
                size={ButtonSize.Small}
                onClick={() => modal.open(ModalName.account)}
              >
                Settings
              </Button>
            ) : (
              <Button
                view={ButtonView.CTA}
                size={ButtonSize.Small}
                onClick={() =>
                  authentication.startFlow({
                    source: AEventSource.editor,
                  })
                }
              >
                Login
              </Button>
            )
          ) : (
            <ButtonPlaceholder />
          )}
        </Content>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  modal: store.modal,
  session: store.session,
  authentication: store.authentication,
  isAllLoaded: store.isAllLoaded,
})

export default connector(storesToProps)(Header)
