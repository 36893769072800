import styled from '../../../styles'

export const AnimationWrapper = styled.div`
  position: relative;
  overflow-y: hidden;

  &.slide-up {
    .slide-enter {
      opacity: 0;
      transform: translateY(-100%) scale(0.7);
      position: absolute;
    }

    .slide-enter-active {
      opacity: 1;
      width: 100%;
      transition: all 500ms linear;
      transform: translateY(0) scale(1);
    }
    .slide-exit {
      opacity: 1;
    }
    .slide-exit-active {
      opacity: 0;
      transition: all 500ms linear;
      transform: translateY(100%) scale(0.7);
    }
  }

  &.slide-down {
    .slide-enter {
      opacity: 0;
      transform: translateY(100%) scale(0.7);
      position: absolute;
    }

    .slide-enter-active {
      opacity: 1;
      width: 100%;
      transition: all 500ms linear;
      transform: translateY(0) scale(1);
    }

    .slide-exit {
      opacity: 1;
    }

    .slide-exit-active {
      opacity: 0;
      transition: all 500ms linear;
      transform: translateY(-100%) scale(0.7);
    }
  }
`
