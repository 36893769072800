import { types } from 'mobx-state-tree'

import { AuthType } from 'src/constants/authentication'
import { SubscriptionPrice } from 'src/constants/subscription/prices'

export const BaseAuthenticationModel = types.model('AuthenticationModel', {
  type: types.optional(
    types.enumeration('AuthType', [...Object.values(AuthType)]),
    AuthType.default
  ),
  subPrice: types.optional(
    types.enumeration('SubscriptionPrice', [
      ...Object.values(SubscriptionPrice),
    ]),
    SubscriptionPrice.default
  ),

  email: types.optional(types.string, ''),
  userId: types.optional(types.string, ''),
  allowEmailSend: types.optional(types.boolean, true),

  error: types.optional(types.string, ''),

  source: types.optional(types.string, ''),
  signInSource: types.optional(types.string, ''),

  loadingApple: false,
})
