import { css } from 'src/styles'

/**
 * Текстовый стиль кнопок
 * Используются:
 */
export default css`
  ${({ theme }) => {
    return `
      color: ${theme.buttonsColors.default.color.text};
      background-color: ${theme.buttonsColors.default.background.text};
      
      &:hover:not(:disabled) {
        color: ${theme.buttonsColors.hover.color.text};
        background-color: ${theme.buttonsColors.hover.background.text};
        
        svg {
          fill: ${theme.buttonsColors.hover.color.text};
        }
      }
      
      &:disabled {
        color: ${theme.buttonsColors.disabled.color.text};
      }
    `
  }}
`
