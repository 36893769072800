import { applySnapshot, getRoot, Instance, types } from 'mobx-state-tree'

import { IStore } from '../index'

import activeStyle from './actions/active-style'
import upload from './actions/upload'
import resolution from './actions/resolution'
import exampleImage from './actions/example-image'

import { ExampleImages } from './models/example-images'
import { Image } from './models/image'

export interface IEditor extends Instance<typeof Editor> {}

export const BaseEditor = types
  .model('Editor', {
    /**
     * Information about image, that we show to user in editor
     */
    image: types.optional(Image, {}),

    /**
     * Responsible for example images block on initial screen of editor/float block.
     */
    exampleImages: types.optional(ExampleImages, {}),
  })
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, {
        exampleImages: self.exampleImages,
      })
    },
  }))

export const FirstEditorExpansion = BaseEditor.actions(exampleImage)

export const Editor = FirstEditorExpansion.actions(activeStyle)
  .actions(upload)
  .actions(resolution)

export default Editor
