import styled from 'src/styles'
import { Button as ButtonBase } from 'src/components/button'

export const Button = styled(ButtonBase)`
  svg {
    width: 22px;
    height: 22px;
    margin: -2px 2px 0 0;
  }
`
