import React, { Component } from 'react'

import { Provider } from 'mobx-react'

import { Router as ReactRouter } from 'react-router-dom'

import history from 'src/history'
import Router from 'src/router'
import storeInstance from 'src/store/instance'
import { theme, ThemeProvider } from 'src/styles'
import GlobalStyles from 'src/styles/global'
import AmplitudeAnalytics from 'src/components/analytics/amplitude'
import FacebookAnalytics from 'src/components/analytics/facebook'
import FirebaseAnalytics from 'src/components/analytics/firebase'
import GoogleAnalytics from 'src/components/analytics/google'
import AppsflyerAnalytics from 'src/components/analytics/appsflyer'
import CookieManager from 'src/components/analytics/cookie-manager'
import ModalRoot from 'src/containers/modal-root'

import 'assets/css/font.css'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'
import StorageItem from '../../utils/storage-item'

const newUserStorageItem = new StorageItem<boolean>(localStorage, 'new_user')

class App extends Component {
  componentDidMount() {
    if (!newUserStorageItem.getParsed()) {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.newUser)
      AnalyticsController.updateUserPropertiesWrapper({
        new_user_time: +new Date(),
      })
      newUserStorageItem.set(true)
    }
  }

  public render() {
    return (
      <>
        <CookieManager />
        <FirebaseAnalytics />
        <GoogleAnalytics />
        <FacebookAnalytics />
        <AppsflyerAnalytics />
        <AmplitudeAnalytics />
        <ThemeProvider theme={theme}>
          <>
            <Provider store={storeInstance.get()}>
              <ReactRouter history={history}>
                <Router />
                <ModalRoot />
              </ReactRouter>
            </Provider>
            <GlobalStyles />
          </>
        </ThemeProvider>
      </>
    )
  }
}

export default App
