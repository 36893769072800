import { flow, Instance } from 'mobx-state-tree'

import { AnalyticsController } from 'src/utils/analytics'
import { AuthMethod } from 'src/constants/authentication'

import { BaseSession } from '../index'
import { getDeviceIdFromStorage } from 'src/utils/authentication'

export default (self: Instance<typeof BaseSession>) => ({
  setUserId(id: string) {
    self.userID = id
  },
  setSession(userId: string, tokenValue: string, tokenType: AuthMethod) {
    self.userID = userId
    self.token = tokenValue
    self.tokenType = tokenType

    AnalyticsController.setUserId(userId)
  },
  setDeviceID: flow(function* setDeviceID() {
    self.deviceID = yield getDeviceIdFromStorage()
  })
})
