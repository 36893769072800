import SectionBase from 'src/api/sections/base'
import { IStylesSearchResponse, IV2GetLibraryAllResponse } from './types'

export default class Styles extends SectionBase {
  public getStyles = (): Promise<IV2GetLibraryAllResponse> =>
    this.service.request({
      url: '/v2/library/all',
      method: 'GET',
    })

  public search = (query: string): Promise<IStylesSearchResponse> =>
    this.service.request({
      url: `/v2/library/search?${query}`,
      method: 'GET',
    })
}
