import { applySnapshot, getRoot, Instance, types } from 'mobx-state-tree'

import { Resolution } from 'src/constants/editor/resolution'
import { Style } from 'src/models/library'
import { EditorImageState } from 'src/constants/editor/image'
import mergeImages from 'src/utils/image-blend'

import activeStyle from './actions/active-style'
import compressAndUpload from './actions/compress'
import resolution from './actions/resolution'
import upload from './actions/upload'
import process from './actions/process'
import base from './actions/base'

export interface IImage extends Instance<typeof Image> {}

export const BaseImage = types
  .model('BaseImage', {
    /**
     * This src will be shown to user in editor. It can be
     * - base image
     * - processed SD image
     * - processed HD image
     */
    src: types.optional(types.maybeNull(types.string), null),
    originalSrc: types.optional(types.maybeNull(types.string), null),
    originalSrcHD: types.optional(types.maybeNull(types.string), null),
    /**
     * These ids will be used for processing
     */
    uploadId: types.optional(types.maybeNull(types.string), null),
    uploadIdHD: types.optional(types.maybeNull(types.string), null),

    /**
     * Selected resolution
     */
    resolution: types.optional(
      types.enumeration('Resolution', [...Object.values(Resolution)]),
      Resolution.SD
    ),

    /**
     * Style
     */
    activeStyle: types.optional(types.maybeNull(Style), null),
    activeStyleHistory: types.array(Style),

    /**
     * State
     */
    state: types.optional(
      types.enumeration('ImageState', [...Object.values(EditorImageState)]),
      EditorImageState.initial
    ),

    /**
     * Indicates: was current image processed?
     */
    isFirstProcessing: true,

    /**
     * Images properties: width and height
     */
    sizes: types.optional(types.maybeNull(types.string), null),
    /**
     * Image in bytes
     */
    fileSize: types.optional(types.maybeNull(types.string), null),
    /**
     * Need for saving image with rather intensity
     */
    intensity: types.optional(types.maybeNull(types.number), 1),
    /**
     * save style ID on error for restart processing
     */
    errorStyle: types.optional(types.maybeNull(Style), null),
  })
  .volatile(() => ({
    file: null,
  }))
  .views((self) => ({
    get rootStore(): any {
      return getRoot(self)
    },
    get id() {
      return self.resolution === Resolution.SD ? self.uploadId : self.uploadIdHD
    },
    get imageDataUrl() {
      const imagesToMerge = []
      if (self.originalSrc) imagesToMerge.push({ src: self.originalSrc })
      if (self.src && self.src !== self.originalSrc && self.intensity)
        imagesToMerge.push({ src: self.src, opacity: self.intensity })

      return mergeImages(imagesToMerge)
    },
  }))
  .actions((self) => ({
    setImage(image: IImage) {
      applySnapshot(self, image)
    },
    setSizes(value: string) {
      self.sizes = value
    },
    setFileSize(value: string) {
      self.fileSize = value
    },
    setIntensity(value: number) {
      self.intensity = value
    },
    setUploadingState() {
      self.state = EditorImageState.uploading
    },
    setProcessedState() {
      self.state = EditorImageState.processed
    },
    setErrorState() {
      self.state = EditorImageState.error
    },
    setUploadedState() {
      self.state = EditorImageState.uploaded
    },
    setRetryState() {
      self.state = EditorImageState.processing
    },
  }))

export const FirstImageExpansion = BaseImage.actions(base)

export const SecondImageExpansion = FirstImageExpansion.actions(
  activeStyle
).actions(compressAndUpload)

export const ThirdImageExpansion = SecondImageExpansion.actions(upload).actions(
  process
)

export const Image = ThirdImageExpansion.actions(resolution)
