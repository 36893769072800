import React from 'react'
import {
  Title,
  LoginRequiredBodySubscriptionTitle,
  LoginRequiredBody,
  LoginRequiredImage,
  Login,
  Purple,
  Dismiss,
} from './styles'
import { Modal } from 'src/components/modal'
import { ModalTitleIcon } from 'src/components/modal/styles'
import unlockImage from 'assets/images/modals/unlock.png'
import unlockImageRetina from 'assets/images/modals/unlock@2x.png'
import closeIcon from 'assets/icons/close_new.svg'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { AuthType } from 'src/constants/authentication'

export interface IModalLoginRequiredProps {
  delayedAction?: () => void
  source?: string
  type?: AuthType
}

type ModalLoginRequiredProps = IModalLoginRequiredProps &
  ReturnType<typeof storesToProps>

class ModalLoginRequired extends React.Component<ModalLoginRequiredProps, {}> {
  public render() {
    const { authentication, modal, delayedAction, source, type } = this.props

    return (
      <Modal width={420} onClose={modal.close}>
        <ModalTitleIcon
          onClick={authentication.stopFlow}
          position={'top: 40px; right: 40px;'}
          icon={closeIcon}
        />
        <LoginRequiredBody>
          <picture>
            <source srcSet={`${unlockImageRetina} 2x, ${unlockImage} 1x`} />
            <LoginRequiredImage src={unlockImage} width={100} height={100} />
          </picture>
          <Title>Login required</Title>
          <LoginRequiredBodySubscriptionTitle>
            Please login to upload photos, import photos from Unsplash, and use
            locked filters.
          </LoginRequiredBodySubscriptionTitle>
          <Login
            onClick={() =>
              authentication.startFlow({
                type,
                delayedAction,
                source,
              })
            }
          >
            <Purple>Login</Purple>
          </Login>
          <Dismiss onClick={modal.close}>Dismiss</Dismiss>
        </LoginRequiredBody>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  modal: store.modal,
})

export default connector(storesToProps)(ModalLoginRequired)
