import { Environment } from 'src/constants/environments'
import logs from 'src/utils/logs'
import ConsoleLogger, {
  ConsoleLoggerModes,
} from 'src/utils/logs/loggers/console-logger'

const configureLogger = () =>
  logs.setLoggers([
    new ConsoleLogger(
      ENV === Environment.Prod
        ? ConsoleLoggerModes.Production
        : ConsoleLoggerModes.Default
    ),
  ])

export default configureLogger
