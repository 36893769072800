import styled from 'src/styles'
import { BaseInput } from 'src/components/input/styles'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
`

export const SearchInput = styled(BaseInput)``
