import React, { Component } from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import connector from 'src/decorators/connector'
import Editor from 'src/containers/editor'
import Header from 'src/containers/header'
import Library from 'src/containers/library'
import SaveOpenBar from 'src/containers/save-open-bar'
import FiltersHistory from 'src/containers/filters-history'
import Authentication from 'src/containers/authentication'

import { LeftColumn, Wrapper, MiddleColumn } from './styles'

import { Footer } from 'src/components/footer'
import { Dropzone } from 'src/components/dropzone'
import { IStoresMap } from 'src/types'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'
import { AEventSource } from '../../constants/analytics/event-sources'
import LandingPage from '../landing'

import { AppIcon, Title, Text } from '../landing/styles'
import logoSvg from 'assets/images/app-identity/prisma_icon.svg'
import qrImg from 'assets/images/stores/qr.png'

type EditorMainPageProps = RouteComponentProps &
  ReturnType<typeof storesToProps>

class EditorMainPage extends Component<
  EditorMainPageProps,
  { isOpen: boolean }
> {
  constructor(props: EditorMainPageProps | Readonly<EditorMainPageProps>) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  public closeRightColumn() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  public render() {
    const saveOpenBarProps = {
      onOpenImageUploader: () => {
        this.setState({ isOpen: true })
      },
    }

    const { account, editor, isAllLoaded } = this.props

    return (
      <>
        <Wrapper>
          {isAllLoaded && account.isSubLoaded && !account.subscription && (
            <FullScreenLanding />
          )}
          <Authentication />
          {isAllLoaded && (
            <Dropzone
              canUpload={account.canUploadImage}
              onUpload={editor.uploadImage}
              onDrop={(openHandler) =>
                editor.onDoesNotHaveUploadPermission(
                  openHandler,
                  AEventSource.editor
                )
              }
              afterUpload={() =>
                AnalyticsController.sendEventWrapper(
                  EVENT_NAMES.IMPORT.importSuccess,
                  {
                    photo_id: editor.image.id,
                    photo_source: 'local_file',
                    source: AEventSource.editor,
                  }
                )
              }
            />
          )}
          <LeftColumn>
            <Header />
            <Library />
          </LeftColumn>
          <MiddleColumn>
            <SaveOpenBar {...saveOpenBarProps} />
            {isAllLoaded && <Editor />}
            <FiltersHistory />
            <Footer />
          </MiddleColumn>
        </Wrapper>
      </>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  isAllLoaded: store.isAllLoaded,
  account: store.account,
  editor: store.editor,
  session: store.session,
})

export default withRouter(connector(storesToProps)(EditorMainPage))

class FullScreenLanding extends Component {
  public render() {
    const root = document.getElementsByClassName(
      'modal-root'
    )?.[0] as HTMLElement
    if (root.style) {
      root.style.display = 'none'
    }

    return (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          zIndex: 1000,
        }}
      >
        <Switch>
          <Route exact path={'*'} component={LandingPage} />
        </Switch>
      </div>
    )
  }
}
