import React, { Component } from 'react'

import MobileDetect from 'mobile-detect'
import { RouteComponentProps } from 'react-router-dom'

import { STORE_LINKS } from 'src/constants/links'
import { Platform } from 'src/constants/platforms'

import logoSvg from 'assets/images/app-identity/prisma_icon.svg'
import qrImg from 'assets/images/stores/qr.png'

import { Wrapper, AppIcon, Text, Title, CTAButton } from './styles'

class LandingPage extends Component<RouteComponentProps, {}> {
  public get md() {
    return new MobileDetect(window.navigator.userAgent)
  }

  public handleStoreClick = (platform: Platform) => {
    window.open(STORE_LINKS[platform], '_blank')
  }

  public render() {
    if (!this.md.mobile()) {
      return (
        <Wrapper>
          <AppIcon icon={logoSvg} width={152} square />

          <Title>Prisma App</Title>
          <Text>Use native mobile app for perfect experience</Text>
          <img src={qrImg} width={200} height={200} />
        </Wrapper>
      )
    }

    const isIOS = this.md.os() === 'iOS'
    return (
      <Wrapper>
        <AppIcon icon={logoSvg} width={152} square />

        <Title>Prisma App</Title>
        <Text>Use native mobile app for perfect experience</Text>

        <CTAButton
          onClick={() =>
            this.handleStoreClick(isIOS ? Platform.ios : Platform.android)
          }
        >
          Get the App
        </CTAButton>
      </Wrapper>
    )
  }
}

export default LandingPage
