import { applySnapshot, Instance, types } from 'mobx-state-tree'

import { AuthType } from 'src/constants/authentication'
import { ModalName } from 'src/containers/modal-root'
import {
  SUBSCRIPTION_PRICE_CONFIGURATIONS,
  SubscriptionPrice,
} from 'src/constants/subscription/prices'
import { ISubscriptionPrice } from 'src/models/subscription-price'
import StorageItem from 'src/utils/storage-item'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'

import { RootAccessing } from '../models/root-accessing'
import { Timer } from '../models/timer'

import modalSign from './actions/modal-sign'
import modalConfirm from './actions/modal-confirm'
import postAuthActions from './actions/post-auth-actions'

import { BaseAuthenticationModel } from './model'

export const authStorage = new StorageItem<IAuthStartFlowOptions>(
  localStorage,
  'authentication:state'
)

export interface IAuthStartFlowOptions {
  type?: AuthType
  price?: SubscriptionPrice
  delayedAction?: () => void // action that should be done after successful auth OR subscription purchase
  source?: string
  signInSource?: string
}

export interface IAuthSignOptions {
  source: string
}

export const BaseAuthentication = types
  .compose(BaseAuthenticationModel, RootAccessing, Timer)
  .volatile(() => ({
    delayedAction: null,
  }))
  .views((self) => ({
    get isCheckoutFlow(): boolean {
      return self.type === AuthType.checkoutFlow
    },
    get priceConfig(): ISubscriptionPrice {
      return SUBSCRIPTION_PRICE_CONFIGURATIONS[self.subPrice]
    },
  }))
  .actions((self) => ({
    setError(error: string) {
      self.error = error
    },
  }))
  .actions((self) => ({
    openLoginRequiredModal(options?: IAuthStartFlowOptions) {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.alertShow, {
        context: 'login_required',
        source: options.source,
      })
      self.rootStore.modal.open(ModalName.loginRequired, {
        type: options.type,
        delayedAction: options.delayedAction,
        source: options.signInSource,
      })
    },
    openSignModal() {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.SIGN_IN.signInShow, {
        source: self.source,
      })
      self.rootStore.modal.open(
        ModalName.authSign,
        self.isCheckoutFlow ? { showStepper: true, showLogo: true } : {}
      )
    },
    openConfirmModal() {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.SIGN_IN.signInShow, {
        source: self.source,
      })
      self.rootStore.modal.open(
        ModalName.authConfirm,
        self.isCheckoutFlow ? { showStepper: true, showLogo: true } : {}
      )
    },
  }))
  .actions((self) => ({
    initFlow({
      type,
      price,
      delayedAction,
      source,
      signInSource,
    }: IAuthStartFlowOptions = {}) {
      if (type) {
        self.type = type
      }
      if (price) {
        self.subPrice = price
      }
      if (delayedAction) {
        self.delayedAction = delayedAction
      }
      if (source) {
        self.source = source
      }
      if (signInSource) {
        self.signInSource = signInSource
      }

      authStorage.stringifyAndSet({
        type: self.type,
        price: self.subPrice,
        source: self.source,
        signInSource: self.signInSource,
      })
    },
  }))
  .actions((self) => ({
    initFlowFromStorage() {
      const state = authStorage.getParsed() || {}
      self.initFlow(state)
    },
    startFlow(options?: IAuthStartFlowOptions, modalAfter?: ModalName) {
      self.initFlow(options)
      if (options?.source === 'onboarding') {
        self.rootStore.onboarding.completeFirstEnter()
      }
      if (modalAfter) {
        self.rootStore.modal.open(modalAfter, options)
        return
      }
      self.openSignModal()
    },
    restartFlow() {
      self.resetTimer()

      self.userId = ''
      self.allowEmailSend = true
      self.error = ''
      self.loadingApple = false

      self.rootStore.authentication.authByEmail(self.email)
    },
    stopFlow({ leaveModals } = { leaveModals: false }) {
      if (self.isCheckoutFlow) {
        // temp solution, we can't stop checkout flow
        return
      }

      self.resetTimer()
      applySnapshot(self, {})
      authStorage.clear()

      if (!leaveModals) {
        self.rootStore.modal.close()
      }
    },
  }))

export const BaseAndAfterAuthModel = BaseAuthentication.actions(postAuthActions)

export const Authentication = BaseAndAfterAuthModel.actions(modalSign).actions(
  modalConfirm
)

export interface IAuthentication extends Instance<typeof Authentication> {}
