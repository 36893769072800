export enum PaymentStep {
  plans = 'plans',
  checkout = 'checkout',
  success = 'success',
}

export const defaultPaymentStep = PaymentStep.plans

export enum PaymentPeriod {
  monthly = 'monthly',
  yearly = 'yearly',
}

export const defaultPaymentPeriod = PaymentPeriod.yearly

export enum PlanAlias {
  free = 'free',
  creator = 'creator',
  pro = 'pro',
}

export const defaultPlan = PlanAlias.pro

export const PlanOptions: { [key: string]: any } = {
  [PlanAlias.free]: {
    uploads_per_day: '1',
    filters_available: '9',
    hd_processing: false,
    mobile_app: false,
    price: 0,
  },
  [PlanAlias.creator]: {
    uploads_per_day: '10',
    filters_available: '500+',
    hd_processing: true,
    mobile_app: true,
    price: 4.99,
  },
  [PlanAlias.pro]: {
    uploads_per_day: '100',
    filters_available: '500+',
    hd_processing: true,
    mobile_app: true,
    price: 9.99,
  },
}
