import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import ModalConfirm from 'src/containers/modal-confirm'
import connector from 'src/decorators/connector'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { GLOBAL_ERROR } from 'src/constants/errors'
import { capitalizeFirstLetter } from 'src/utils/string'

export interface IModalSignOutConfirmState {
  loading: boolean
}

export type ModalSignOutConfirmProps = ReturnType<typeof storesToProps>

class ModalSignOutConfirm extends Component<
  ModalSignOutConfirmProps,
  IModalSignOutConfirmState
> {
  constructor(props: ModalSignOutConfirmProps) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  public handleConfirm = async () => {
    const { session, modal, showGlobalError } = this.props

    try {
      this.setState({ loading: true })
      await session.logout()
      modal.resolve()
      session.clearSession()
    } catch (e) {
      const errorText = 'Sign out went wrong. \n Try to refresh page'
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: GLOBAL_ERROR,
        error_text: errorText,
        source: AEventSource.profile,
      })
      showGlobalError(errorText)
    }
  }

  render() {
    const { loading } = this.state
    const {
      account: { subscription },
    } = this.props

    return (
      <ModalConfirm
        title={`${capitalizeFirstLetter(
          subscription?.plan
        )} plan will be unavailable\u00A0after you sign out`}
        description={`You can always sign in and get back your ${capitalizeFirstLetter(
          subscription?.plan
        )} plan`}
        cancelText="Cancel"
        confirmText="Sign Out"
        onConfirm={this.handleConfirm}
        loading={loading}
      />
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  session: store.session,
  modal: store.modal,
  showGlobalError: store.showGlobalError,
})

export default connector(storesToProps)(ModalSignOutConfirm)
