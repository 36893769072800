import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import styled from 'src/styles'

export const QuickPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
`

export const StyledPaymentRequestButtonElement = styled(
  PaymentRequestButtonElement
)`
  margin-bottom: 20px;
`

export const Text = styled.span`
  ${({ theme }) => theme.typography.objToCss('reg13')};
  color: #999999;
  text-align: center;
`
