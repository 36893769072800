import { cast, Instance } from 'mobx-state-tree'

import { IStyle } from 'src/models/library/style'

import { FirstImageExpansion } from '../index'

export default (self: Instance<typeof FirstImageExpansion>) => ({
  setActiveStyle(style: IStyle) {
    self.activeStyle = { ...style }
  },
  saveActiveStyleToHistory() {
    const historyContainsCurrentActiveStyle = self.activeStyleHistory.some(
      (style) => style.id === self.activeStyle.id
    )
    if (!historyContainsCurrentActiveStyle) {
      self.activeStyleHistory = cast([
        ...self.activeStyleHistory,
        { ...self.activeStyle },
      ])
    }
  },
})
