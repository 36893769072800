import React, { useEffect, useState } from 'react'
import { PaginationProps } from './types'
import { PaginationItem, Wrapper } from './styles'

//  количество страниц от текущей страницы
let pageForward = 2
let pageBack = 2

export const Pagination: React.FC<PaginationProps> = ({
  query,
  lastPage,
  currentPage,
  changePage,
}: PaginationProps) => {
  const start = () => {
    if (currentPage === lastPage) {
      pageBack = 4
    } else if (currentPage === lastPage - 1) {
      pageBack = 3
    } else {
      pageBack = 2
    }
    const first = currentPage - pageBack
    return first >= 1 ? first : 1
  }

  const end = () => {
    if (currentPage === 1) {
      pageForward = 4
    } else if (currentPage === 2) {
      pageForward = 3
    } else {
      pageForward = 2
    }
    const last = currentPage + pageForward
    return last < lastPage ? last : lastPage
  }

  const numberOfPages: number[] = []
  for (let i = start(); i <= end(); i++) {
    numberOfPages.push(i)
  }

  const [currentButtons, setCurrentButtons] = useState([])

  useEffect(() => {
    const visiblePages: number[] | string[] = [...numberOfPages]
    setCurrentButtons(visiblePages)
  }, [currentPage, query, lastPage])

  return (
    <Wrapper>
      {lastPage > 1 && (
        <>
          <PaginationItem
            arrow
            disabled={currentPage === 1}
            onClick={() => changePage(1)}
          >
            &lt;&lt;
          </PaginationItem>
          <PaginationItem
            arrow
            disabled={currentPage === 1}
            onClick={() => changePage(currentPage - 1)}
          >
            &lt;
          </PaginationItem>
        </>
      )}

      {lastPage > 1 &&
        currentButtons.map((item, index) => (
          <PaginationItem
            key={index}
            onClick={() => changePage(item)}
            active={item === currentPage}
          >
            {item}
          </PaginationItem>
        ))}

      {lastPage > 1 && (
        <>
          <PaginationItem
            arrow
            disabled={currentPage === lastPage}
            onClick={() => changePage(currentPage + 1)}
          >
            &gt;
          </PaginationItem>
          <PaginationItem
            arrow
            disabled={currentPage === lastPage}
            onClick={() => changePage(lastPage)}
          >
            &gt;&gt;
          </PaginationItem>
        </>
      )}
    </Wrapper>
  )
}
