import Compressor from 'compressorjs'

import { Resolution } from 'src/constants/editor/resolution'
import { COMPRESSOR_PIXELS } from 'src/constants/editor/compressor'

const getNewImageSizes = (width: number, height: number, maxPixels: number) => {
  const pixels = width * height

  if (pixels <= maxPixels) {
    return {
      width,
      height,
    }
  }

  const mult = Math.sqrt(maxPixels / pixels)
  return {
    width: width * mult,
    height: height * mult,
  }
}

export const getProcessedImageFromStorage = ({
  uploadId,
  styleId,
  resolution,
}: {
  uploadId: string
  styleId: string
  resolution: Resolution
}) => {
  return sessionStorage.getItem(`${uploadId}.${styleId}.${resolution}`)
}

export const saveProcessedImageToStorage = ({
  uploadId,
  styleId,
  resolution,
  objectURL,
}: {
  uploadId: string
  styleId: string
  resolution: Resolution
  objectURL: string
}) => {
  return sessionStorage.setItem(
    `${uploadId}.${styleId}.${resolution}`,
    objectURL
  )
}

export const compress = ({
  file,
  resolution,
}: {
  file: File
  resolution: Resolution
}) => {
  return new Promise(async (resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const image = new Image()
      image.onload = () => {
        // We need to know image sizes to set proper width/height of compressed image.
        const w = image.naturalWidth || image.width
        const h = image.naturalHeight || image.height

        const { width, height } = getNewImageSizes(
          w,
          h,
          COMPRESSOR_PIXELS[resolution]
        )
        // tslint:disable-next-line:no-unused-expression
        new Compressor(file, {
          strict: false,
          height,
          width,
          mimeType: 'image/jpeg',
          quality: 0.8,
          success: resolve,
          error: reject,
        })
      }
      image.src = fileReader.result as string
    }

    fileReader.readAsDataURL(file)
  })
}

export default {
  getProcessedImageFromStorage,
  saveProcessedImageToStorage,
  compress,
}
