import SectionBase from 'src/api/sections/base'
import storeInstance from 'src/store/instance'
import {
  PAYMENT_METHOD_TYPES,
  PLAN,
  STRIPE_ENTITY_CONFIGURATION,
} from 'src/constants/subscription'

import {
  ISubscriptionCreateSessionOptions,
  ISubscriptionResponse,
  planType,
} from './types'

export default class Subscriptions extends SectionBase {
  public getSubscription = (): Promise<ISubscriptionResponse> => {
    const userID: string = storeInstance.get().session.userID

    return this.service.request({
      url: `/subscription-web_get/${userID}`,
      method: 'GET',
    })
  }

  public createSession = ({
    successUrl,
    cancelUrl,
    customerEmail,
  }: ISubscriptionCreateSessionOptions) => {
    const url = window.location.origin
    const userID: string = storeInstance.get().session.userID

    const data: any = {
      payment_method_types: PAYMENT_METHOD_TYPES,
      cancel_url: `${url}${cancelUrl}#cancelled`,
      success_url: `${url}${successUrl}#success`,
      user_id: userID,
      appsflyer_id: 'AppsflyerID1',
      subscription_data: [
        {
          plan: PLAN,
          quantity: 1,
        },
      ],
      customer_email: customerEmail,
    }

    return this.service.request({
      url: '/create_web_session',
      method: 'POST',
      data,
    })
  }

  public cancelSubscription = () => {
    const userID: string = storeInstance.get().session.userID
    return this.service.request({
      url: `/subscription-web_cancel/${userID}`,
      method: 'GET',
    })
  }

  public createSubscription = (paymentMethodId: string, planId: string) => {
    const data: any = {
      payment_method_id: paymentMethodId,
      plan_id: planId,
    }

    return this.service
      .request({
        url: '/subscription/stripe/create',
        method: 'POST',
        data,
      })
      .then((response) => {
        return {
          clientSecret: response.client_secret,
          expirationDate: response.expiration_date,
          paymentIntentStatus: response.payment_intent_status,
        }
      })
  }

  public getSecret = () => {
    return this.service
      .request({
        url: '/subscription/stripe/create',
        method: 'POST',
        data: {
          paymentMethodType: 'card',
          currency: 'usd',
        },
      })
      .then((response) => {
        return {
          clientSecret: response.client_secret,
        }
      })
  }
}
