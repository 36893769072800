import { flow, Instance } from 'mobx-state-tree'

import { IStyle } from 'src/models/library/style'
import { StyleType } from 'src/constants/styles'
import { EXAMPLE_IMAGES_DEFAULT_SELECTED_INDEX } from 'src/containers/library/base-view'
import { AuthType } from 'src/constants/authentication'
import { AEventSource } from 'src/constants/analytics/event-sources'

import { FirstEditorExpansion } from '../index'
import { ModalName } from '../../../containers/modal-root'
import { AnalyticsController } from '../../../utils/analytics'
import EVENT_NAMES from '../../../constants/analytics/event-names'

export default (self: Instance<typeof FirstEditorExpansion>) => ({
  setActiveStyle: flow(function* (style: IStyle) {
    const { session, authentication, account } = self.rootStore

    const action = async () => {
      if (!self.image.src) {
        self.uploadExampleImage(EXAMPLE_IMAGES_DEFAULT_SELECTED_INDEX)
      }
      self.image.setActiveStyle(style)
      await self.image.process()
      self.image.saveActiveStyleToHistory()
    }

    if (style.type === StyleType.v1) {
      if (!session.isLoggedIn) {
        authentication.startFlow(
          {
            type:
              self.exampleImages.isSelected || !self.image.src
                ? AuthType.paywallApplyExample
                : AuthType.paywallApplyServerStyle,
            delayedAction: action,
            source: AEventSource.alertPaidFeature,
          },
          ModalName.loginRequired
        )
        AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.alertShow, {
          context: 'login_required',
          source: AEventSource.editor,
        })
        return
      }

      if (session.isLoggedIn && !account.subscription?.plan) {
        AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.alertShow, {
          context: 'paid_feature',
          source: AEventSource.editor,
        })
        authentication.startFlow(
          {
            type:
              self.exampleImages.isSelected || !self.image.src
                ? AuthType.paywallApplyExample
                : AuthType.paywallApplyServerStyle,
            delayedAction: action,
            source: AEventSource.alertPaidFeature,
          },
          ModalName.paymentFlow
        )
        return
      }
    }

    yield action()
  }),
})
