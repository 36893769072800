import styled from 'src/styles'
import { ModalTitleIcon } from 'src/components/modal/styles'
import { Typography, TypographyView } from 'src/components/typography'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`

export const ModalBody = styled.div`
  padding: 36px 40px 40px;
`

export const ModalBlock = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.palette.fill.primary};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`

export const ModalTitle = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  margin-bottom: 26px;
`

// переопределение стилей старого кита
export const ModalClose = styled(ModalTitleIcon)`
  right: 26px;
  top: 26px;

  > svg {
    display: block;
    margin: 12px;
    transition: fill ease-out 200ms;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:disabled {
    background-color: transparent;
  }

  &:hover:not(:disabled) {
    > svg {
      fill: ${({ theme }) => theme.palette.label.secondary};
    }
  }
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold14,
})`
  margin-bottom: 7px;
`

export const Button = styled.button<{ active?: boolean; isLoading?: boolean }>`
  // reset
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  display: block;
  flex-shrink: 0;
  margin: 0 0 0 8px;

  ${({ active, theme }) =>
    theme.typography.objToCss(active ? 'bold14' : 'reg14')}
  color: ${(props) =>
    props.active ? props.theme.accent.accent : props.theme.palette.label.dark};

  transition: opacity ease-out 0.2s;

  ${({ isLoading }) => {
    if (isLoading) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `
    }
  }}
`

export const ErrorText = styled.span`
  color: #cb0000;
`

export const PrimaryText = styled.span`
  color: ${(props) => props.theme.palette.label.primary};
`

export const Info = styled(Typography).attrs({
  view: TypographyView.Reg14,
})`
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
  color: ${(props) => props.theme.palette.label.dark};

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    flex-grow: 1;
    flex-shrink: 1;
  }
`
