import { Search } from 'history'
import amplitude from 'amplitude-js'

// User can be redirected from apple (if he used this auth method).
// In this case we try to get token, etc info from query params.
export const getURLParamsAfterAppleAuth = (search: Search) => {
  const params = new URLSearchParams(search)

  const token = params.get('token')
  const userId = params.get('user_id')
  const error = params.get('error')

  return (token && userId) || error
    ? {
        token,
        userId,
        error,
      }
    : null
}

// User can be redirected from email via `Magic Link`. This link
// contains info about authentication key.
export const getCodeAfterRedirectFromEmail = (search: Search) => {
  const searchParams = new URLSearchParams(search)
  const code = searchParams.get('key')
  return code ? code : null
}

export const getDeviceIdFromStorage = async () => {
  const opt = await amplitude.getInstance().options
  let storageID = localStorage.getItem('account:device_id')

  if (!storageID || storageID === 'undefined' || storageID === 'null') {
    storageID = opt.deviceId
    localStorage.setItem('account:device_id', storageID)
  }

  return storageID
}
