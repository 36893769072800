import React from 'react'

import { observer } from 'mobx-react'

import {
  InputPlaceholder,
  TagWrapper,
  TagPlaceholder,
  StyleWrapper,
  StylePlaceholder,
  Wrapper,
} from './styles'

export const PlaceholderView: React.FC = () => {
  return (
    <Wrapper>
      <InputPlaceholder />

      <TagWrapper>
        {[...new Array(8)].map((_, i) => (
          <TagPlaceholder key={i} />
        ))}
      </TagWrapper>
      <StyleWrapper>
        {[...new Array(30)].map((_, i) => (
          <StylePlaceholder key={i} />
        ))}
      </StyleWrapper>
    </Wrapper>
  )
}

export default observer(PlaceholderView)
