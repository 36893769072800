import { TooltipProps } from './types'
import React from 'react'
import { Wrapper, Header, Body, Text, Close } from './styles'
import { Button, ButtonSize, ButtonView } from '../button'
import closeIcon from 'assets/icons/close_new.svg'

const Tooltip: React.FC<TooltipProps> = ({
  body,
  isOpen,
  onClose,
  classname,
  heading,
  top,
  bottom,
  left,
  right,
  headingWidth,
  bodyWidth,
}: TooltipProps) => {
  return (
    <Wrapper
      isOpen={isOpen}
      className={classname}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
    >
      <Body>
        <Close icon={closeIcon} onClick={() => onClose()} />
        {heading && <Header width={headingWidth}>{heading}</Header>}
        <Text width={bodyWidth}>{body}</Text>
        <Button
          view={ButtonView.Secondary}
          size={ButtonSize.Small}
          onClick={() => onClose()}
        >
          Ok, got it
        </Button>
      </Body>
    </Wrapper>
  )
}

export default Tooltip
