import {
  FACEBOOK_PIXEL_ID,
  FACEBOOK_PIXEL_ID_CHECKOUT_FLOW,
  GOOGLE_ID,
} from 'src/constants/analytics/analytics-account-keys'
import { EventDestination } from 'src/constants/analytics/event-destinations'

import amplitude from 'amplitude-js'

import logs from '../logs'

export class AnalyticsController {
  static sendGoogleEvent(event: string, params?: any) {
    if (params) {
      params.event_category = 'general'
      params.event_label = event
      gtag('event', event, params)
    } else {
      gtag('event', event)
    }
  }

  static sendFirebaseEvent(event: string, params?: any) {
    try {
      if (params) {
        params.event_category = 'general'
        params.event_label = event
        firebase.analytics().logEvent(event, params)
      } else {
        firebase.analytics().logEvent(event)
      }
    } catch (e) {
      logs.error('Error firebase analytics')
    }
  }

  static sendAmplitudeEvent(event: string, params?: any) {
    if (params) {
      amplitude.getInstance().logEvent(event, params)
    } else {
      amplitude.getInstance().logEvent(event)
    }
  }

  static updateUserPropertiesWrapper(userProperties: Record<string, any>) {
    AnalyticsController.updateUserProperties(
      [EventDestination.Firebase, EventDestination.Amplitude],
      userProperties
    )
  }

  static updateUserProperties(
    destinations: EventDestination[],
    userProperties: Record<string, any>
  ) {
    destinations.forEach((destination) => {
      switch (destination) {
        case EventDestination.Firebase:
          AnalyticsController.updateFirebaseUserProperties(userProperties)
          break
        case EventDestination.Amplitude:
          AnalyticsController.updateAmplitudeUserProperties(userProperties)
          break
      }
    })
  }

  static updateAmplitudeUserProperties(userProperties: Record<string, any>) {
    Object.entries(userProperties).forEach(([key, value]) => {
      amplitude.getInstance().identify(new amplitude.Identify().set(key, value))
    })
  }

  static updateFirebaseUserProperties(userProperties: Record<string, any>) {
    firebase.analytics().setUserProperties(userProperties)
  }

  static sendFacebookEvent(event: string, params?: any) {
    if (params) {
      fbq('trackCustom', event, params)
    } else {
      fbq('trackCustom', event)
    }
  }

  static sendAppsflyerEvent(event: string, params?: any) {
    if (params) {
      AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: event,
        eventValue: params,
      })
    } else {
      AF('pba', 'event', { eventType: 'EVENT', eventName: event })
    }
  }

  static sendEvent(event: string, destination: string[], params?: any) {
    destination.forEach((item) => {
      switch (item) {
        case EventDestination.Google:
          AnalyticsController.sendGoogleEvent(event, params)
          break
        case EventDestination.Firebase:
          AnalyticsController.sendFirebaseEvent(event, params)
          break
        case EventDestination.Amplitude:
          AnalyticsController.sendAmplitudeEvent(event, params)
          break
        case EventDestination.Facebook:
          AnalyticsController.sendFacebookEvent(event, params)
          break
        case EventDestination.Appsflyer:
          AnalyticsController.sendAppsflyerEvent(event, params)
          break
      }
    })
  }

  static sendEventWrapper(event: string, params?: any) {
    AnalyticsController.sendEvent(
      event,
      [
        EventDestination.Amplitude,
        EventDestination.Firebase,
        EventDestination.Appsflyer,
        EventDestination.Facebook,
      ],
      params
    )
  }

  static sendEventCheckoutFlow(event: string, params?: any) {
    if (params) {
      fbq('trackSingle', FACEBOOK_PIXEL_ID_CHECKOUT_FLOW, event, params)
    } else {
      fbq('trackSingle', FACEBOOK_PIXEL_ID_CHECKOUT_FLOW, event)
    }
  }

  static setUserId(userId: string) {
    // Google
    gtag('config', GOOGLE_ID, { user_id: userId })

    // Firebase
    try {
      firebase.analytics().setUserId(userId)
    } catch (e) {
      logs.error('Error firebase analytics')
    }

    // Amplitude
    amplitude.getInstance().setUserId(userId)

    // Facebook
    fbq('init', FACEBOOK_PIXEL_ID, { uid: userId })

    // Appsflyer
    AF('pba', 'setCustomerUserId', userId)
  }
}
