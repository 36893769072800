import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import ModalConfirm from 'src/containers/modal-confirm'
import { ModalName } from 'src/containers/modal-root'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { GLOBAL_ERROR } from 'src/constants/errors'

import sadFace from 'assets/images/modals/sad_face.svg'

export interface IModalCancelConfirmState {
  loading: boolean
}

export type ModalCancelConfirmProps = ReturnType<typeof storesToProps>

class ModalCancelConfirm extends Component<
  ModalCancelConfirmProps,
  IModalCancelConfirmState
> {
  constructor(props: ModalCancelConfirmProps) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  public handleConfirm = async () => {
    const { account, session, modal, showGlobalError } = this.props

    try {
      this.setState({ loading: true })
      await account.cancelSubscription()
      modal.resolve()
      AnalyticsController.sendEventWrapper(
        EVENT_NAMES.SETTINGS.purchaseUnsubscribe,
        {
          account_id: session.userID,
          subscription_type: account.subscription?.plan,
          source: 'settings',
        }
      )
    } catch (e) {
      const errorText = 'Cancel went wrong. \n Try to refresh page'
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: GLOBAL_ERROR,
        error_text: errorText,
        source: AEventSource.profile,
      })
      showGlobalError(errorText)
    }
  }

  render() {
    const { loading } = this.state

    return (
      <ModalConfirm
        title={
          <>
            Are you sure <br /> to cancel subscription?
          </>
        }
        // media={sadFace}
        description="You will lose unlimited image uploads, HD processing and premium styles."
        cancelText="Keep"
        confirmText="Cancel Subscription"
        onConfirm={this.handleConfirm}
        loading={loading}
      />
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  modal: store.modal,
  showGlobalError: store.showGlobalError,
  session: store.session,
})

export default connector(storesToProps)(ModalCancelConfirm)
