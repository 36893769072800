import { IStyle } from 'src/models/library/style'

export enum StyleCellType {
  Default = 'default',
  Daily = 'daily',
}

export enum StyleCellState {
  Default = 'default',
  Locked = 'locked',
  Selected = 'selected',
  FavoriteAdd = 'favorite_add',
  FavoriteChecked = 'favorite_checked',
}

export interface StyleCellProps {
  classNames?: string

  style: IStyle

  type?: StyleCellType
  state?: StyleCellState
  smallLabel?: boolean

  inactive?: boolean

  onClick: (style: IStyle) => void
}
