import styled from 'src/styles'
import { Icon } from '../../components/icon'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background: ${(props) => props.theme.palette.background.cellar};
`

export const LeftColumn = styled.div`
  display: flex;
  flex-flow: column;
  width: 336px;
  min-width: 336px;
  height: 100%;
  padding: 28px 0 0;
  background-color: ${(props) => props.theme.palette.background.base};
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05), 1px 0 0 #e6e6e6;
  z-index: 1;
`

export const MiddleColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 500px;
  padding: 20px 0;
  transition: width ease-out 200ms;
`

export const RightColumn = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 336px;
  min-width: 336px;
  height: 100%;
  right: 0;
  background-color: ${(props) => props.theme.palette.background.base};
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.05), -1px 0 0 #e6e6e6;
  ${({ isOpen }) => {
    return (
      !isOpen &&
      `
      right: -340px;
    `
    )
  }}
  z-index: 999;
  transition: right linear 200ms;
`

export const RightColumnClose = styled(Icon)<{ position?: string }>`
  position: relative;
  width: 12px;
  height: 12px;
  margin: 20px;
  z-index: 1;
`
