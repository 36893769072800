import styled from 'src/styles'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 24px; /* not sure that it's good idea */
`

export const StylesTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 20px 0;
  color: #000;
  border-top: 1px solid rgb(230, 230, 230);
`

export const Styles = styled.div`
  display: grid;
  padding: 16px 20px 0;

  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row dense;

  gap: 6px 6px;
`
