import React from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { Modal } from 'src/components/modal'
import Stepper from 'src/components/stepper'
import Svg from 'src/components/svg'
import { AnalyticsController } from 'src/utils/analytics'
import { AEventSource } from 'src/constants/analytics/event-sources'
import EVENT_NAMES from 'src/constants/analytics/event-names'

import closeIcon from 'assets/icons/close_new.svg'
import logo from 'assets/images/app-identity/prisma_logo.svg'

import {
  ModalBody,
  ModalClose,
  ModalTitle,
  LogoWrapper,
  FormWrapper,
  Text,
} from '../styles'

import Form from './form'
import { ResendButtonComponent } from './form/resend-button'

export interface IModalAuthConfirmProps {
  showStepper?: boolean
  showLogo?: boolean
}

type ModalAuthConfirmProps = IModalAuthConfirmProps &
  ReturnType<typeof storesToProps>

class ModalAuthConfirm extends React.Component<ModalAuthConfirmProps> {
  public handleTryAgain = () => {
    this.props.authentication.restartFlow()
  }

  public render() {
    const { authentication, showStepper, showLogo } = this.props

    return (
      <Modal width={420} onClose={authentication.stopFlow}>
        <ModalClose icon={closeIcon} onClick={authentication.stopFlow} />
        <ModalBody>
          <ModalTitle>
            {showStepper ? <Stepper currentStep={1} totalSteps={3} /> : 'Login'}
          </ModalTitle>
          {showLogo && (
            <LogoWrapper>
              <Svg icon={logo} />
            </LogoWrapper>
          )}
          <Text>
            <strong>Please check your email</strong>
            Login code has been sent to <br /> {authentication.email}
          </Text>
          <FormWrapper>
            <Form />
          </FormWrapper>

          <ResendButtonComponent
            id={
              authentication.isCheckoutFlow
                ? 'prisma_checkout-flow_confirm_btn-try-again'
                : undefined
            }
            handle={this.handleTryAgain}
          />
        </ModalBody>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  modal: store.modal,
})

export default connector(storesToProps)(ModalAuthConfirm)
