import styled from 'src/styles'

import Svg from 'src/components/svg'

export const StoresWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
`

export const StoreButton = styled.a`
  display: block;
`

export const StoreIcon = styled(Svg)`
  height: 52px;
  display: block;
`
