import React from 'react'

import Svg from 'src/components/svg'

import lock from 'assets/icons/lock.svg'

import checkmark from 'assets/icons/checkmark.svg'

import { Cover, Image, ImagePlaceholder, Name, Wrapper } from './styles'
import { StyleCellProps, StyleCellState } from './types'

export const StyleCell: React.FC<StyleCellProps> = ({
  type,
  state,
  style,
  onClick,
  inactive,
  smallLabel,
  ...rest
}) => {
  return (
    <Wrapper
      type={type}
      state={state}
      style={style}
      inactive={inactive}
      onClick={() => onClick(style)}
      {...rest}
    >
      {style.image_url ? <Image src={style.image_url} /> : <ImagePlaceholder />}

      <Name className="style-cell-name" state={state} smallLabel={smallLabel}>
        {style.artwork}
      </Name>

      {(state === StyleCellState.Locked ||
        state === StyleCellState.Selected) && (
        <Cover state={state}>
          <Svg
            icon={state === StyleCellState.Locked ? lock : checkmark}
            width={24}
            height={24}
          />
        </Cover>
      )}
    </Wrapper>
  )
}

export default StyleCell
