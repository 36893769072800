import { LoggerMethods } from '../types'

export enum ConsoleLoggerModes {
  Default,
  Production,
}

class ConsoleLogger {
  protected _mode: ConsoleLoggerModes = ConsoleLoggerModes.Default

  protected _middlewares = {
    [ConsoleLoggerModes.Default]: (args: any[], _method: LoggerMethods) => args,
    [ConsoleLoggerModes.Production]: (args: any[], method: LoggerMethods) => {
      switch (method) {
        case LoggerMethods.debug:
          return []
        default:
          return args
      }
    },
  }
  constructor(mode: ConsoleLoggerModes) {
    this._mode = mode
  }

  public info(...args: any[]) {
    this._log(args, LoggerMethods.info)
  }

  public debug(...args: any[]) {
    this._log(args, LoggerMethods.debug)
  }

  public warn(...args: any[]) {
    this._log(args, LoggerMethods.warn)
  }

  public error(...args: any[]) {
    this._log(args, LoggerMethods.error)
  }

  protected _log(args: any[], method: LoggerMethods) {
    const processedArgs = this._middlewares[this._mode](args, method)
    if (processedArgs && processedArgs.length) {
      console[method](...processedArgs)
    }
  }
}

export default ConsoleLogger
