import { flow, Instance } from 'mobx-state-tree'

import API from 'src/api'

import { EditorImageState } from 'src/constants/editor/image'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { GLOBAL_ERROR } from 'src/constants/errors'

import {
  getProcessedImageFromStorage,
  saveProcessedImageToStorage,
} from '../utils'

import { SecondImageExpansion } from '../index'
import { formatBytes } from 'src/utils/file/bytes'
import { Resolution } from 'src/constants/editor/resolution'
import { EventDestination } from '../../../../../constants/analytics/event-destinations'

export default (self: Instance<typeof SecondImageExpansion>) => ({
  process: flow(function* () {
    self.state = EditorImageState.processing

    const processedImage = getProcessedImageFromStorage({
      uploadId: self.id,
      styleId: self.activeStyle.id,
      resolution: self.resolution,
    })

    if (processedImage) {
      self.src = processedImage

      setTimeout(() => {
        self.setProcessedState()
      }, 100)

      // TODO: сделать нормальное решение без увеличения размеров оригинала
      // Для сохранения консистентности отображаемых размеров данных с учётом мержа
      // Убрать дублирование кода
      self.setFileSize(
        formatBytes(Math.round(((yield self.imageDataUrl).length * 3) / 4))
      )
      return
    }

    const photoProcessEventDestinations = [
      EventDestination.Firebase,
      EventDestination.Appsflyer,
      EventDestination.Facebook,
    ]

    try {
      const blob = yield API.processing.getProcessedImage(
        self.id,
        self.activeStyle.id,
        !self.rootStore.session.isLoggedIn,
        self.isFirstProcessing
      )

      const src = URL.createObjectURL(blob)
      self.src = src

      setTimeout(() => {
        self.setProcessedState()
      }, 100)

      saveProcessedImageToStorage({
        uploadId: self.id,
        styleId: self.activeStyle.id,
        resolution: self.resolution,
        objectURL: src,
      })

      if (self.isFirstProcessing) {
        photoProcessEventDestinations.push(EventDestination.Amplitude)
        self.rootStore.account.decreaseCounter()
        self.isFirstProcessing = false
      }

      // TODO: сделать нормальное решение без увеличения размеров оригинала
      // Для сохранения консистентности отображаемых размеров данных с учётом мержа
      self.setFileSize(
        formatBytes(Math.round(((yield self.imageDataUrl).length * 3) / 4))
      )

      self.state = EditorImageState.processed

      AnalyticsController.sendEvent(
        EVENT_NAMES.EDITOR.photoProcess,
        [
          EventDestination.Firebase,
          EventDestination.Appsflyer,
          EventDestination.Facebook,
        ],
        {
          photo_id: self.rootStore.editor.image.id,
          style_name: self.rootStore.editor.image.activeStyle.artwork,
          collection_name:
            self.rootStore.library.searchQuery.length > 0
              ? null
              : self.rootStore.editor.image.activeStyle.collection_name,
          style_type: self.rootStore.editor.image.activeStyle.paid
            ? 'premium'
            : 'free',
          style_source: self.rootStore.library.searchQuery.length
            ? 'search'
            : 'library',
          processing_success: true,
          hd_quality: self.rootStore.editor.image.resolution === Resolution.HD,
        }
      )
    } catch (e) {
      AnalyticsController.sendEvent(
        EVENT_NAMES.EDITOR.photoProcess,
        photoProcessEventDestinations,
        {
          photo_id: self.rootStore.editor.image.id,
          style_name: self.rootStore.editor.image.activeStyle.artwork,
          collection_name:
            self.rootStore.library.searchQuery.length > 0
              ? null
              : self.rootStore.editor.image.activeStyle.collection_name,
          style_type: self.rootStore.editor.image.activeStyle.paid
            ? 'premium'
            : 'free',
          style_source: self.rootStore.library.searchQuery.length
            ? 'search'
            : 'library',
          processing_success: false,
          hd_quality: self.rootStore.editor.image.resolution === Resolution.HD,
          error_show: e,
        }
      )

      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: 'processing error',
        error_text: GLOBAL_ERROR,
        source: AEventSource.editor,
      })
      self.setErrorState()
      self.errorStyle = self.activeStyle
      self.activeStyle = null
      throw e
    }
  }),
})
