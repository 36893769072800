import React, { Component } from 'react'

import logs from 'src/utils/logs'
import { FIREBASE_CONFIG } from 'src/constants/analytics/analytics-account-keys'

class FirebaseAnalytics extends Component {
  public componentDidMount() {
    try {
      firebase.initializeApp(FIREBASE_CONFIG)
      firebase.analytics()
    } catch (e) {
      logs.info('Error firebase analytics', e)
    }
  }

  public render() {
    return <></>
  }
}

export default FirebaseAnalytics
