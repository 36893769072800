import React, { RefObject, useEffect, useState } from 'react'
import { InputSearchProps } from '../input-search/types'
import { Wrapper, UnsplashStyledSearch, UnsplashButton } from './styles'
import unsplashLogo from 'assets/icons/unsplash-logo.svg'
import Svg from '../svg'

export const UnsplashSearch: React.FC<InputSearchProps> = ({
  placeholder,
  value,
  onChange,
  disabled,
}: InputSearchProps) => {
  const ref: RefObject<HTMLInputElement> = React.createRef()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleChange = (newValue: string) => {
    setInputValue(newValue)
  }

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // @ts-ignore
      onChange(e.target.value)
    }
  }

  return (
    <Wrapper>
      <UnsplashStyledSearch
        type="search"
        ref={ref}
        value={inputValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={onEnter}
      />
      <UnsplashButton onClick={() => onChange(inputValue)} disabled={disabled}>
        <Svg icon={unsplashLogo} />
      </UnsplashButton>
    </Wrapper>
  )
}

export default UnsplashSearch
