import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { InputWrapper, ImageInfo, SaveImageInput, Wrapper } from './styles'
import { InputFilenameProps } from './types'
import { useDebouncedCallback } from 'use-debounce'
import pen from 'assets/icons/pen.svg'
import Svg from '../svg'

export const InputFilename: React.FC<InputFilenameProps> = ({
  value,
  className,
  onChange,
  placeholder,
  sizes,
  fileSize,
}: InputFilenameProps) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    handleChange(value)
  }, [value])

  const debounced = useDebouncedCallback(
    (newValue: string) => onChange(newValue),
    200
  )

  const handleChange = (newValue: string) => {
    setInputValue(newValue)
    debounced(newValue)
  }

  return (
    <Wrapper>
      {/*<InputWrapper>
        <SaveImageInput
          contentEditable={true}
          suppressContentEditableWarning={true}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.innerHTML)
          }
          onKeyPress={(
            e: KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
          ) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.target.blur()
              handleChange(e.target.innerHTML)
            }
          }}
          placeholder={placeholder}
          className={className}
        >
          {inputValue}
        </SaveImageInput>
        <Svg icon={pen} />
      </InputWrapper>*/}
      {sizes && fileSize && (
        <ImageInfo>
          {sizes}, {fileSize}
        </ImageInfo>
      )}
    </Wrapper>
  )
}

export default InputFilename
