import styled from 'src/styles'

import { BaseButton } from '../button/styles'

import { IconProps } from './types'

export const IconWrapper = styled(BaseButton)<IconProps>`
  & > svg {
    fill: ${({ theme }) => theme.palette.label.primary};
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.palette.fill.quaternary};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.palette.fill.secondary};
  }

  &:disabled {
    & > svg {
      fill: ${({ theme }) => theme.palette.label.tertiary};
    }

    background-color: ${({ theme }) => theme.palette.fill.quaternary};

    pointer-events: none;
  }
`
