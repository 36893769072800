import React from 'react'
import { CSSTransition } from 'react-transition-group'
import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'
import { Content, Modal } from './styles'
import UnsplashWrapper from '../unsplash'

export type ModalUnsplashProps = ReturnType<typeof storesToProps>

class ModalUnsplash extends React.Component<ModalUnsplashProps> {
  render() {
    const { unsplash } = this.props;
    const { unsplash: { isOpen, noAnimation } } = this.props

    const onFinishAnimation = () => {
      unsplash.resetAnimation()
    }

    return (
      <CSSTransition
        in={isOpen}
        timeout={400}
        classNames="is"
        enter={!noAnimation}
        exit={!noAnimation}
        onEntered={onFinishAnimation}
        onExited={onFinishAnimation}
      >
        <Modal>
          <Content className="modal-content">
            <UnsplashWrapper />
          </Content>
        </Modal>
      </CSSTransition>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  unsplash: store.unsplash,
})

export default connector(storesToProps)(ModalUnsplash)
