import React, { useEffect, useState } from 'react'
import { ISubscription } from 'src/models/user/subscription'
import { capitalizeFirstLetter } from 'src/utils/string'
import { PlanAlias } from 'src/constants/payment-flow'
import { Button, ErrorText, Info, PrimaryText, Title } from '../styles'

export interface ISubscriptionBlockProps {
  counter: number
  subscription: ISubscription
  onCancel: () => void
  onUpgrade: () => Promise<void>
}

export const SubscriptionBlock: React.FC<ISubscriptionBlockProps> = ({
  counter,
  subscription,
  onCancel,
  onUpgrade,
}: ISubscriptionBlockProps) => {
  const [loading, setLoading] = useState(false)
  const [uploads, setUploads] = useState(1)

  const handleUpgrade = async () => {
    try {
      setLoading(true)
      await onUpgrade()
    } catch (e) {
      throw e
    }
  }

  useEffect(() => {
    switch (subscription?.plan) {
      case PlanAlias.pro:
        setUploads(100)
        break
      case PlanAlias.creator:
        setUploads(10)
        break
    }
  }, [subscription?.plan])

  return (
    <>
      <Title>Subscription plan</Title>
      {subscription?.active ? (
        <>
          <Info>
            <div>
              <PrimaryText>
                {capitalizeFirstLetter(subscription?.plan)}:{' '}
              </PrimaryText>
              {uploads} uploads per day
            </div>
            {/* {subscription?.canUpgrade && (
              <Button onClick={handleUpgrade} isLoading={loading} active>
                Upgrade
              </Button>
            )} */}
          </Info>
          <Info>Next billing date: {subscription.formattedDate}</Info>
          {subscription?.canCancel ? (
            <Info>
              <div>Auto-renewal: Active</div>
              <Button onClick={onCancel} isLoading={loading}>
                Unsubscribe
              </Button>
            </Info>
          ) : (
            <Info>
              <div>
                Auto-renewal: <ErrorText>Disabled</ErrorText>
              </div>
              {/*<Button onClick={onCancel} isLoading={loading} active>*/}
              {/*  Enable auto-renewal*/}
              {/*</Button>*/}
            </Info>
          )}
        </>
      ) : (
        <Info>
          <div>
            <PrimaryText>{capitalizeFirstLetter(PlanAlias.free)}: </PrimaryText>
            {uploads} uploads per day
          </div>
          {/* <Button onClick={handleUpgrade} isLoading={loading} active>
            Upgrade
          </Button> */}
        </Info>
      )}
    </>
  )
}

export default SubscriptionBlock
