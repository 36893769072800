import { ICreateSubscriptionOptions } from 'src/api/sections/subscriptions/types'
import { PAYMENT_METHOD_TYPES } from 'src/constants/subscription'

export const preFillSubscriptionOptions = (
  options: ICreateSubscriptionOptions
) => {
  const localOptions = { ...options }

  if (!localOptions.type) {
    localOptions.type = PAYMENT_METHOD_TYPES
  }

  return localOptions
}
