import React from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { Modal } from 'src/components/modal'
import Stepper from 'src/components/stepper'
import Svg from 'src/components/svg'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { AuthMethod } from 'src/constants/authentication'
import closeIcon from 'assets/icons/close_new.svg'
import logo from 'assets/images/app-identity/prisma_logo.svg'

import EmailForm from './email-form'
import GoogleForm from './google-form'

import {
  ModalBody,
  ModalClose,
  ModalTitle,
  Divider,
  LogoWrapper,
  FormWrapper,
} from '../styles'

import { ButtonAppleLogin, Link, TermsAndPrivacyMessage } from './styles'

export interface IModalAuthSignProps {
  showStepper?: boolean
  showLogo?: boolean
}

type ModalAuthSignProps = IModalAuthSignProps & ReturnType<typeof storesToProps>

class ModalAuthSign extends React.Component<ModalAuthSignProps, {}> {
  public render() {
    const { authentication, showStepper, showLogo, session } = this.props
    const timeZoneOffset = new Date().getTimezoneOffset() * 60 * -1

    return (
      <Modal width={420} onClose={authentication.stopFlow}>
        <ModalClose icon={closeIcon} onClick={authentication.stopFlow} />
        <ModalBody>
          <ModalTitle>
            {showStepper ? <Stepper currentStep={1} totalSteps={3} /> : 'Login'}
          </ModalTitle>
          {showLogo && (
            <LogoWrapper>
              <Svg icon={logo} />
            </LogoWrapper>
          )}
          <FormWrapper>
            <EmailForm />
          </FormWrapper>
          <Divider>...or use alternative options</Divider>
          <ButtonAppleLogin
            id={
              authentication.isCheckoutFlow
                ? 'prisma_checkout-flow_sign_btn-apple-login'
                : undefined
            }
            timeZoneOffset={timeZoneOffset}
            loading={authentication.loadingApple}
            redirectUrl={'/auth/apple'}
            deviceId={session.deviceID}
            onClick={() =>
              AnalyticsController.sendEventWrapper(
                EVENT_NAMES.SIGN_IN.signInTap,
                { method: AuthMethod.apple, source: authentication.source }
              )
            }
          />
          <GoogleForm />
          <TermsAndPrivacyMessage>
            By signing up you are agreeing to our&nbsp;
            <br />
            <Link
              target="blank"
              href="/terms_of_service.html"
              onClick={() =>
                AnalyticsController.sendEventWrapper(
                  EVENT_NAMES.GENERAL.legalTermsTap,
                  {
                    source: AEventSource.signInPopup,
                  }
                )
              }
            >
              Terms of Use
            </Link>
            &nbsp;&&nbsp;
            <Link
              target="blank"
              href="/privacy_policy.html"
              onClick={() =>
                AnalyticsController.sendEventWrapper(
                  EVENT_NAMES.GENERAL.legalPrivacyTap,
                  {
                    source: AEventSource.signInPopup,
                  }
                )
              }
            >
              Privacy Policy
            </Link>
            .
          </TermsAndPrivacyMessage>
        </ModalBody>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  session: store.session,
})

export default connector(storesToProps)(ModalAuthSign)
