import React, { useState, useEffect } from 'react'
import { Wrapper } from './styles'
import {
  getIndexedDBFile,
  setIndexedDBFile,
} from 'src/utils/file/indexedDBTransactions'
import { detectSafari } from 'src/utils/browser/safari'

interface DragEvent<T = Element> extends React.MouseEvent<T> {
  dataTransfer: DataTransfer
}

export interface IDropzoneProps {
  canUpload: boolean
  onUpload: (file: File) => void
  onDrop: (openHandler: () => void) => void
  afterUpload: () => void
}

export const Dropzone: React.FC<IDropzoneProps> = ({
  canUpload,
  onUpload,
  onDrop,
  afterUpload,
}: IDropzoneProps) => {
  const [visible, setVisible] = useState(false)

  // Saving memory pointer for removeEventListener
  function setVisibleTrueFn(event: DragEvent & MouseEvent) {
    if (detectSafari) {
      setVisible(true)
    } else if (event.dataTransfer.items[0]?.type.includes('image')) {
      setVisible(true)
    }
  }

  useEffect(() => {
    window.addEventListener('dragenter', setVisibleTrueFn)

    return () => {
      window.removeEventListener('dragenter', setVisibleTrueFn)
    }
  }, [])

  const allowDrag = (event: DragEvent<HTMLElement>) => {
    if (!canUpload) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (detectSafari) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    } else if (event.dataTransfer.items[0]?.type.includes('image')) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  const onDropFn = async (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    const fileToSave: File = await new Promise((resolve, reject) => {
      return event.dataTransfer.items[0]
        .webkitGetAsEntry()
        .file(resolve, reject)
    })

    if (canUpload) {
      await onUpload(fileToSave)
      afterUpload()
    } else {
      await setIndexedDBFile(fileToSave)
      onDrop(async () => onUpload(await getIndexedDBFile()))
      afterUpload()
    }
    setVisible(false)
  }

  return (
    <div>
      <Wrapper
        onDragEnter={(event) => allowDrag(event)}
        onDragOver={(event) => allowDrag(event)}
        onDragLeave={() => setVisible(false)}
        onDrop={(event) => {
          onDropFn(event)
        }}
        visible={visible}
      >
        Drop image here
        <br />
        to upload
      </Wrapper>
    </div>
  )
}

export default Dropzone
