import styled from 'src/styles'
import { BaseInput } from '../input/styles'

export const Wrapper = styled.label`
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 40px;
`

export const UnsplashStyledSearch = styled(BaseInput)<{ disabled: boolean }>`
  &[type='search']::-webkit-search-cancel-button {
    appearance: none;
  }

  &[type='search']::-webkit-search-decoration {
    appearance: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
      :hover,:focus {
        border-color: rgba(0,0,0,0.16);
        &::placeholder {
          color: #777777;
        }
  }
  `}
`
export const UnsplashButton = styled.button<{ disabled: boolean }>`
  position: absolute;
  top: 12px;
  right: 18px;
  border: none;
  background-color: transparent;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`
