import { flow, Instance } from 'mobx-state-tree'

import API from 'src/api'
import { getErrorMessage } from 'src/utils/errors'
import { AuthMethod } from 'src/constants/authentication'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'

import { BaseAndAfterAuthModel } from '../index'

export default (self: Instance<typeof BaseAndAfterAuthModel>) => ({
  confirmByEmail: flow(function* ({ code, userId }) {
    try {
      self.setError('')

      const id = userId ? userId : self.userId
      const token = yield API.auth.confirmByEmail({ key: code, user_id: id })

      self.rootStore.session.setSession(id, token, AuthMethod.email)

      yield self.postAuthActions(
        userId ? 'email_magic_link' : 'email_entered_code'
      )
    } catch (e) {
      const error = getErrorMessage(e)

      // TODO: think about solution with error code, not text
      let errorText = ''
      if (error === 'Request failed with status code 422') {
        errorText = 'Invalid Code'
      } else if (error === 'external api error') {
        errorText = ''
      }

      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: error,
        error_text: errorText || error,
        source: AEventSource.signInPopup,
      })
      self.setError(error)
      throw e
    }
  }),
})
