import styled from 'src/styles'

export const Wrapper = styled.div<{ visible: boolean }>`
  ${({ theme }) => theme.typography.objToCss('reg40')}
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.palette.white[100]};
  text-align: center;
  background-color: ${({ theme }) => theme.palette.black[64]};
  z-index: 10001;
  box-sizing: border-box;
  transition: opacity ease-out 200ms;
`
