import styled from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'

export const Wrapper = styled.div.attrs(
  // @ts-ignore
  ({ imageWidth: w, imageHeight: h, theme }) => ({
    style: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' id='visual' viewBox='0 0 ${w} ${h}' width='${w}' height='${h}' version='1.1'%3E%3Crect fill='${theme.palette.black[64]}' width='${w}' height='${h}' /%3E%3C/svg%3E")`,
    },
  })
)<{ imageHeight: number; imageWidth: number }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  margin-bottom: 20px;
  color: ${(props) => props.theme.palette.white[100]};
`

export const Description = styled(Typography).attrs({
  view: TypographyView.Reg13,
})`
  margin-bottom: 40px;
  color: ${(props) => props.theme.palette.white[100]};
  text-align: center;
`

export const Image = styled.img`
  margin-bottom: 40px;
`
