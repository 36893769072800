import React from 'react'
import ReactDOM from 'react-dom'

import App from 'src/containers/app'
import { createStore } from 'src/store/instance'
import { cacheEditorImage } from 'src/store/cache-image'

import configureLogger from './configure-logger'
import indexedDBCleaner from './indexedDBCleaner'

export default () => {
  indexedDBCleaner()
  configureLogger()
  const store = createStore()
  cacheEditorImage(store)

  if (module.hot) {
    // @ts-ignore
    window.__store = store
  }

  ReactDOM.render(<App />, document.getElementById('root'))
}
