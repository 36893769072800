import { css } from 'src/styles'

/**
 * Кнопки маленького размера
 * Используются: кнопка логина
 */
export default css`
  ${({ theme }) => {
    return `
      height: 30px;
      padding: 6px 12px;
      ${theme.typography.objToCss('bold13')}
      border-radius: 4px;
    `
  }}
`
