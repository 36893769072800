import SectionBase from 'src/api/sections/base'
import storeInstance from 'src/store/instance'

import { IUserResponse } from './types'
import { getDeviceIdFromStorage } from 'src/utils/authentication'

const deviceID = async () => await getDeviceIdFromStorage()

export default class User extends SectionBase {
  public getUser = (): Promise<IUserResponse> => {
    return this.service.request({
      url: `/auth/user`,
      method: 'GET',
    })
  }

  public getUserCounter = async () => {
    const userID: string = storeInstance.get().session.userID

    return this.service.request({
      url: `/auth/user/counter/${userID}`,
      method: 'GET',
      headers: {
        'prisma-device-id': await deviceID(),
      },
    })
  }

  public sendEmailAllowance = (allowed: boolean) => {
    return this.service.request({
      url: '/auth/user/sent_email_allowance',
      method: 'POST',
      data: {
        app: 'prisma',
        value: allowed,
      },
    })
  }
}
