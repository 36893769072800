import { getRoot, types } from 'mobx-state-tree'

import StorageItem from 'src/utils/storage-item'

import { IStore } from '../index'

const onBoardingFirstEnter = new StorageItem<boolean>(
  localStorage,
  'onboarding:first_enter'
)

const onboardingFloatBlockStorage = new StorageItem<boolean>(
  localStorage,
  'onboarding:float_block_state:closed'
)

const onBoardingIntensityTip = new StorageItem<boolean>(
  localStorage,
  'onboarding:intensity_tip'
)

const onBoardingHDTip = new StorageItem<boolean>(
  localStorage,
  'onboarding:HD_tip'
)

export const OnboardingStore = types
  .model('OnboardingStore', {
    isFloatBlockVisible: false,
    intensityTip: types.optional(types.boolean, false),
    firstEnter: types.optional(types.boolean, false),
    HDTip: types.optional(types.boolean, false),
    firstUpload: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    initFloatBlock() {
      if (!onboardingFloatBlockStorage.getParsed()) {
        self.isFloatBlockVisible = true
      }
    },
    initFirstEnter() {
      if (!onBoardingFirstEnter.getParsed()) {
        self.firstEnter = true
      }
    },
    initIntensityTip() {
      if (!onBoardingIntensityTip.getParsed()) {
        self.intensityTip = true
      }
    },
    initHDTip() {
      if (!onBoardingHDTip.getParsed()) {
        self.HDTip = true
      }
    },
    initEditor() {
      const hd = onBoardingHDTip.getParsed()
      const intensity = onBoardingIntensityTip.getParsed()
      const firstEnter = onBoardingFirstEnter.getParsed()
      if (intensity && firstEnter && !hd) {
        this.initHDTip()
        return
      }
      if (firstEnter && !intensity) this.initIntensityTip()
    },
    closeIntensityTip() {
      self.intensityTip = false
    },
    closeHDTip() {
      self.HDTip = false
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.initFloatBlock()
      self.initFirstEnter()
    },
    onFloatBlockClose() {
      self.isFloatBlockVisible = false
      onboardingFloatBlockStorage.stringifyAndSet(true)
    },
    completeFirstEnter() {
      self.firstEnter = false
      onBoardingFirstEnter.stringifyAndSet(true)
    },
    completeIntensityTip() {
      self.closeIntensityTip()
      onBoardingIntensityTip.stringifyAndSet(true)
      self.initHDTip()
    },
    completeHDTip() {
      self.closeHDTip()
      onBoardingHDTip.stringifyAndSet(true)
    },
  }))

export default OnboardingStore
