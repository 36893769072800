import React, { Component } from 'react'

import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'

import { Loader, LoaderBar, Title, Wrapper, Content } from './styles'

class LoaderUploading extends Component<{}, {}> {
  render() {
    return (
      <Wrapper>
        <Content>
          <Title>Uploading Image</Title>
          <Loader>
            <LoaderBar />
          </Loader>
        </Content>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  account: store.account,
})

export default connector(storesToProps)(LoaderUploading)
