import example1 from 'assets/images/editor-examples/example_1.jpg'
import example2 from 'assets/images/editor-examples/example_2.jpg'
import example3 from 'assets/images/editor-examples/example_3.jpg'

import example1hd from 'assets/images/editor-examples/hd-examples/example_1.jpeg'
import example2hd from 'assets/images/editor-examples/hd-examples/example_2.jpeg'
import example3hd from 'assets/images/editor-examples/hd-examples/example_3.jpeg'

export enum EXAMPLES_ID_HD {
  id_1 = 'web_new_1_hd',
  id_2 = 'web_new_2_hd',
  id_3 = 'web_new_3_hd',
}

export enum EXAMPLES_ID {
  id_1 = 'web_new_1',
  id_2 = 'web_new_2',
  id_3 = 'web_new_3',
}

export const EXAMPLE_IMAGES = [example1, example2, example3]

export const EXAMPLE_IMAGES_HD = [example1hd, example2hd, example3hd]

export const EXAMPLE_IMAGE_IDS = [
  {
    uploadId: 'web_new_1',
    uploadIdHD: 'web_new_1_hd',
    fileSize: '256Kb',
  },
  {
    uploadId: 'web_new_2',
    uploadIdHD: 'web_new_2_hd',
    fileSize: '295Kb',
  },
  {
    uploadId: 'web_new_3',
    uploadIdHD: 'web_new_3_hd',
    fileSize: '487Kb',
  },
]
