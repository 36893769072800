import styled from 'src/styles'

import Svg from 'src/components/svg'
import { flicker } from 'src/containers/library/style-block/style-cell/styles'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 23px;
  background-color: ${(props) => props.theme.palette.background.base};
`

export const LogoWrapper = styled.div`
  position: relative;

  ::after {
    content: 'beta';
    display: block;
    position: absolute;
    right: -20px;
    top: -8px;
    
    font-weight: bold;
    font-size: 9px;
    line-height: 18px;
    letter-spacing: 0.15px;
    color: ${({theme}) => theme.palette.label.dark};
  }
`

export const Logo = styled(Svg)`
  fill: ${(props) => props.theme.palette.label.primary};

`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`

export const ButtonPlaceholder = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.base};
  animation: ${flicker} 1s linear infinite;
`
