import styled from 'src/styles'

import ReactSwitch from 'react-switch'

export const Switch = styled(ReactSwitch)`
  // TODO: maybe make some custom animation
  display: flex;
  box-sizing: border-box !important;
  border-radius: 40px !important;
  border: 1px solid ${({ theme }) => theme.palette.black[100]};

  .react-switch-bg {
    transition: none !important;

    & div {
      transition: none !important;
    }
  }

  .react-switch-handle {
    box-shadow: none !important;
    transition: none !important;

    & div {
      transition: none !important;
    }
  }
`

export const ActiveButton = styled.span<{ right?: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ right }) => (right ? '-1px' : null)};
  left: ${({ right }) => (right ? null : '-1px')};
  padding: 5px 19px 6px;
  border-radius: 40px;
  z-index: 1;
  background-color: ${({ theme }) => theme.accent.accent};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.accent.accent};
  color: ${({ theme }) => theme.palette.white[100]};
  ${({ theme }) => theme.typography.objToCss('bold13')};
`

export const MonthlyDeactivate = styled.span<{ right?: boolean }>`
  position: absolute;
  right: ${({ right }) => (right ? null : '-19px')};
  left: ${({ right }) => (right ? '-19px' : null)};
  transform: translateY(-50%);
  padding: 5px 19px 6px;
  border-radius: ${({ right }) => (right ? '40px 0 0 40px' : '0 40px 40px 0')};
  color: ${({ theme }) => theme.palette.black[100]};
  ${({ theme }) => theme.typography.objToCss('reg13')};
`
