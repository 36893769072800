import { Instance, types } from 'mobx-state-tree'

const TIMER_MAX_VALUE = 60

export interface ITimer extends Instance<typeof Timer> {}

export const Timer = types
  .model('Timer', {
    timer: types.optional(types.maybeNull(types.number), null),
    timerId: types.optional(types.maybeNull(types.frozen()), null),
  })
  .actions((self) => ({
    setTimer(newTimer: number) {
      self.timer = newTimer
    },
    resetTimer() {
      const timerId = self.timerId
      if (timerId !== null) {
        clearInterval(timerId)
      }
      self.timer = null
      self.timerId = null
    },
  }))
  .actions((self) => ({
    startTimer() {
      self.timer = TIMER_MAX_VALUE
      self.timerId = setInterval(() => {
        const timer = self.timer - 1
        self.setTimer(timer)
        if (timer <= 0) {
          self.resetTimer()
        }
      }, 1000)
    },
  }))
