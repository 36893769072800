import styled from 'src/styles'
import { Typography, TypographyView } from 'src/components/typography'

export const LoginRequiredImage = styled.img`
  margin-bottom: 44px;
`

export const LoginRequiredBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 188px;
  margin: 0 auto;
  padding: 40px 0 40px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  text-align: center;
`

export const LoginRequiredBodySubscriptionTitle = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg13')};
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
`

export const Login = styled.button`
  margin-bottom: 40px;
  ${({ theme }) => theme.typography.objToCss('reg14')};
  background-color: transparent;
  border: none;
  cursor: pointer;
`

export const Purple = styled.span`
  ${({ theme }) => theme.typography.objToCss('bold14')};
  color: ${({ theme }) => theme.accent.accent};
`

export const Dismiss = styled.button`
  ${({ theme }) => theme.typography.objToCss('bold13')};
  color: #999999;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
