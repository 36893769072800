import React from 'react'

import GoogleLogin from 'react-google-login'

import { GOOGLE_WEB_CLIENT_ID } from 'src/constants/authentication'
import { ButtonSize, ButtonView } from 'src/components/button'
import googleIcon from 'assets/icons/google.svg'

import { Button } from './styles'

export interface IButtonGoogleLoginProps {
  id?: string
  className?: string
  loading?: boolean
  onSubmit: (data: any) => void
  onClick?: () => void
}

/**
 * Кнопка авторизации через Google
 * Находится в модальном окне авторизации
 * @param id string - html-атрибут ID
 * @param className string - css класс
 * @param onSubmit - родительский callback
 * @param loading - параметр, определяющий, идёт ли какой-либо процесс загузки
 * @param onClick - родительский callback
 * @constructor
 */
export const ButtonGoogleLogin: React.FC<IButtonGoogleLoginProps> = ({
  id,
  className,
  onSubmit,
  loading,
  onClick,
}: IButtonGoogleLoginProps) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_WEB_CLIENT_ID}
      render={({ onClick: click, disabled }) => (
        <Button
          id={id}
          className={className}
          view={ButtonView.Secondary}
          size={ButtonSize.Medium}
          disabled={disabled}
          startIcon={googleIcon}
          onClick={() => {
            if (onClick) {
              onClick()
            }
            click()
          }}
          isLoading={loading}
          fullWidth
        >
          Login with Google
        </Button>
      )}
      onSuccess={onSubmit}
      accessType="accessType"
      responseType="code"
    />
  )
}

export default ButtonGoogleLogin
