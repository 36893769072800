import React from 'react'

import { ExampleImage, ExamplesWrapper, Wrapper } from './styles'

export const PlaceholderScreen: React.FC = () => {
  return (
    <Wrapper>
      <ExamplesWrapper>
        <ExampleImage />
        <ExampleImage />
        <ExampleImage />
      </ExamplesWrapper>
    </Wrapper>
  )
}

export default PlaceholderScreen
