import { onSnapshot } from 'mobx-state-tree'

import StorageItem from 'src/utils/storage-item'
import { EditorImageState } from 'src/constants/editor/image'

import { IImage } from './editor/models/image'

import { IStore } from './index'

export const cachedImage = new StorageItem<IImage>(
  localStorage,
  'editor:image:state'
)

export const cacheEditorImage = (store: IStore) => {
  // @todo uncomment
  // onSnapshot(store, (newSnapshot: IStore) => {
  //   const {
  //     editor: { exampleImages, image },
  //   } = newSnapshot
  //   if (
  //     exampleImages.selectedIndex === null &&
  //     (image.state === EditorImageState.uploaded ||
  //       image.state === EditorImageState.processed)
  //   ) {
  //     cachedImage.stringifyAndSet(image)
  //   } else {
  //     cachedImage.clear()
  //   }
  // })
}
