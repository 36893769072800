export const PURCHASE = {
  // Показ пэйволла. В source записываем, где была нажата кнопка «Upgrade» или ее аналог.
  // source: settings, alert_paid_feature, alert_out_of_imports, ...;
  purchaseViewShow: 'purchase_view_show',

  // Показ экрана с оплатой.
  // account_id: айдишник аккаунта, полученный в sign_in_success;
  // subscription_type: тип подписки + частота биллинга;
  // source: откуда был открыт пэйволл, с которого началась оплата.
  purchaseCheckOutShow: 'purchase_check_out_show',

  // Отправляется на успешную покупку подписки, после валидации покупки сервером.
  // как user_property передаем subscription_type
  // как user_property передаем в subscription_date_till таймстемп этого ивента
  // как user_property передаем autorenew_status = true

  // account_id: айдишник аккаунта, полученный в sign_in_success;
  // subscription_type: тип подписки + частота биллинга;
  // subscription_price: стоимость подписки, отображаемая в интерфейсе цена и валюта;
  // source: откуда был открыт пэйволл, с которого оформилась подписка;
  // payment_method: способ оплаты, использованный при покупке.
  purchaseSuccess: 'purchase_success',

  // Нажатие на кнопку «Upgrade». В source записываем активный экран, где была нажата кнопка,
  // и в current_subscription_type текущий тип подписки (бесплатный юзер или creator).
  upgradePlanTap: 'upgrade_tap_plan',
}

export default PURCHASE
