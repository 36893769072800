import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import ROUTES from 'src/router/routes'
import history from 'src/history'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import {
  getCodeAfterRedirectFromEmail,
  getURLParamsAfterAppleAuth,
} from 'src/utils/authentication'

import RequestInterceptor from './request-interceptor'

type AuthenticationProps = ReturnType<typeof storesToProps> &
  RouteComponentProps<{ userId?: string }>

class Authentication extends React.Component<AuthenticationProps, {}> {
  public componentDidMount() {
    const {
      match: { path },
    } = this.props

    // Handle cases, related to authentication

    // Was user redirected from email and we already know confirmation code?
    if (path === ROUTES.CONFIRM_EMAIL) {
      this.handleConfirm()
      return
    }

    // Was user redirected from apple?
    if (path === ROUTES.AUTH_APPLE) {
      this.handleApple()
      return
    }

    // If it's regular page, init user account
    const { account } = this.props
    account.init()
  }

  public handleConfirm = async () => {
    const {
      match: { params },
      location: { search },
    } = this.props

    const userId = params?.userId
    const code = getCodeAfterRedirectFromEmail(search)

    if (code) {
      const { authentication } = this.props
      authentication.initFlowFromStorage()
      authentication.openConfirmModal()
      await authentication.confirmByEmail({ code, userId })
    }

    history.push(ROUTES.APP)
  }

  public handleApple = async () => {
    const params = getURLParamsAfterAppleAuth(this.props.location.search)
    if (params) {
      const { authentication, editor } = this.props
      authentication.initFlowFromStorage()
      authentication.openSignModal()
      await authentication.authByApple(params)
      // editor.uploadImage(await getIndexedDBFile())
    }

    history.push(ROUTES.APP)
  }

  public render() {
    return <RequestInterceptor />
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  onboarding: store.onboarding,
  account: store.account,
  editor: store.editor,
})

export default withRouter(connector(storesToProps)(Authentication))
