import { TrialType } from 'src/constants/subscription'
import { ISubscriptionPrice } from 'src/models/subscription-price'

export enum SubscriptionPrice {
  creatorYear = 'creator-year',
  creatorMonth = 'creator-month',
  proYear = 'pro-year',
  proMonth = 'pro-month',
  default = creatorYear,
}

type SubscriptionPriceConfigurationOptions = {
  [key in SubscriptionPrice]: ISubscriptionPrice
}

export const SUBSCRIPTION_PRICE_CONFIGURATIONS: SubscriptionPriceConfigurationOptions = {
  [SubscriptionPrice.creatorYear]: {
    trialType: TrialType.none,
    coupon: '',
  },
  [SubscriptionPrice.creatorMonth]: {
    trialType: TrialType.none,
    coupon: '',
  },
  [SubscriptionPrice.proYear]: {
    trialType: TrialType.none,
    coupon: '',
  },
  [SubscriptionPrice.proMonth]: {
    trialType: TrialType.none,
    coupon: '',
  },
}
