import React, { useEffect } from 'react'
import styled from '../../../styles'
import { Link as RouterLink } from 'react-router-dom'
import ROUTES from '../../routes'
import { AnalyticsController } from '../../../utils/analytics'
import EVENT_NAMES from '../../../constants/analytics/event-names'

const Wrapper = styled.div`
  width: 900px;
  margin-top: 81px;
`

const Title = styled.h1`
  text-align: center;
  ${({ theme }) => theme.typography.objToCss('bold30')};
`

const SubTitle = styled.h2`
  margin-top: 12px;
  text-align: center;
  ${({ theme }) => theme.typography.objToCss('bold20')};
  color: ${({ theme }) => theme.palette.label.dark};
`

const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
`

const UploadButton = styled.button`
  display: block;
  margin: 86px auto 51px;
  color: #666666;
  background-color: #f7f7f7;
  border: none;
  border-radius: 8px;
  padding: 13px 20px;
  cursor: pointer;
  transition: all ease-in-out 100ms;
  ${({ theme }) => theme.typography.objToCss('bold13')};

  &:hover,
  &:focus {
    background-color: #f2f2f2;
    color: #333333;
  }
`

const Link = styled(RouterLink)`
  text-decoration: none;
`

const ImageLink = styled(Link)`
  position: relative;
  transition: transform ease-in-out 200ms;
  width: 220px;
  height: 220px;
  margin: 0 20px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.17);
    transition: opacity ease-in-out 300ms;
  }

  &:hover {
    transform: translateY(-4px);
    transition: transform ease-in-out 300ms;

    &::after {
      opacity: 1;
    }
`

interface QuickStartProps {
  examples: any
  setExample: (index: number) => void
  openUnsplash: () => void
}

const QuickStart: React.FC<QuickStartProps> = ({
  examples,
  setExample,
  openUnsplash,
}) => {
  useEffect(() => {
    AnalyticsController.sendEventWrapper(
      EVENT_NAMES.ONBOARDING.onboardingQuickStartShow
    )
  }, [])

  return (
    <Wrapper>
      <Title>Quick Start</Title>
      <SubTitle>Select one of the example photos below</SubTitle>
      <ImagesWrapper>
        {examples.images.map((image: any, index: number) => (
          <ImageLink
            key={index}
            to={ROUTES.APP}
            onClick={() => setExample(index)}
          >
            <Image src={image.src} alt={`Example ${index}`} />
          </ImageLink>
        ))}
      </ImagesWrapper>
      <Link to={ROUTES.APP} onClick={openUnsplash}>
        <UploadButton>Upload photo</UploadButton>
      </Link>
    </Wrapper>
  )
}

export default QuickStart
