import styled from 'src/styles'
import { EditorImageState } from 'src/constants/editor/image'
import { Button } from 'src/components/button'
// @ts-ignore
import Slider from 'react-rangeslider/lib'

// TODO: пока боюсь удалять, чтоб откатиться быстро , если все ок - удалить комменты
export const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  text-align: center;
  user-select: none;
`

export const ControlsWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  height: fit-content;
  //top: 0;
  //left: 0;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  //align-items: center;
  margin: 0 20px 8px;
  opacity: 1;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  z-index: 9;
`

export const IntensitySliderWrapper = styled.div<{ disabled: boolean }>`
  ${({ theme }) => theme.typography.objToCss('bold10')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 44px;
  padding: 12px 20px 16px;
  background: ${({ theme }) => theme.palette.white[100]};
  border: 1px solid ${({ theme }) => theme.palette.black[16]};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  color: ${({ disabled }) => (disabled ? '#CCCCCC' : 'inherit')};
`

export const IntensitySlider = styled(Slider)<{ disabled: boolean }>`
  width: 4px;
  height: 100px;
  margin-top: 10px;
  background-color: ${({ theme, disabled }) =>
    disabled ? '#F5F5F5' : theme.palette.black[16]};
  outline: none;
  appearance: none;
  transform: rotate(180deg);

  .rangeslider__handle {
    width: 16px;
    height: 16px;
    background-color: ${({ theme, disabled }) =>
      disabled ? '#CCCCCC' : theme.palette.black[100]};
    border: none;
    border-radius: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    transform: translateX(-6px);
    transition: background-color 100ms linear;

    &:hover {
      background-color: ${({ theme }) => theme.buttonsColors.hover.color.text};
    }

    &:focus,
    :focus-within {
      cursor: pointer;
      outline: none;
    }
  }

  .rangeslider__labels {
    display: none;
  }
`

export const ControlButton = styled(Button)<{
  isActive: boolean
  disabled: boolean
}>`
  ${({ theme }) => theme.typography.objToCss('bold16')};
  width: 44px;
  height: 44px;
  margin-bottom: 20px;
  padding: 11px 12px 10px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.white[100] : theme.palette.black[100]};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.black[100] : theme.palette.white[100]};
  border: 1px solid ${({ theme }) => theme.palette.black[16]};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: color 100ms linear, background-color 100ms linear;

  ${({ disabled }) =>
    disabled &&
    `
    color: #CCCCCC;
  `}

  svg {
    margin: 0;
  }

  &:hover {
    color: ${({ theme, isActive }) =>
      isActive ? theme.palette.white[92] : theme.palette.label.secondary};
    background-color: ${({ theme, isActive }) =>
      isActive ? '#2C2C2E' : theme.palette.white[100]};
  }
`

export const ImagesWrapper = styled.div.attrs(
  (props: { editorState: EditorImageState }) => ({
    style: {
      opacity: `${Number(props.editorState !== EditorImageState.uploading)}`,
    },
  })
)<{ editorState: EditorImageState }>`
  width: 100%;
  position: relative;
`

export const Image = styled.img<{ first?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ first }) => (first ? 'unset' : '2')};

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
`

export const StyledImage = styled(Image).attrs(
  (props: { intensity: number }) => ({
    style: {
      opacity: props.intensity / 100,
    },
  })
)<{ intensity: number }>`
  z-index: 1;
`

export const ImageSlider = styled.div.attrs((props: { left: string }) => ({
  style: {
    left: props.left,
  },
}))<{ left: number | string }>`
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.accent.accent};
  opacity: 0.7;
  cursor: ew-resize;
  z-index: 1;
  transform: translateX(-50%);
`
