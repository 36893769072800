import styled from 'src/styles'
import { Typography, TypographyView } from 'src/components/typography'
import { Button as ButtonOrigin } from 'src/components/button'

export const OutOfImportsImage = styled.img`
  margin-bottom: 44px;
`

export const OutOfImportsBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  padding: 40px 0 40px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  text-align: center;
`

export const OutOfImportsSubscriptionTitle = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg13')};
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
`

export const Button = styled(ButtonOrigin)`
  margin-bottom: 40px;
`

export const Dismiss = styled.button`
  ${({ theme }) => theme.typography.objToCss('bold13')};
  color: #999999;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
