import React from 'react'

export enum TypographyView {
  Reg40 = 'reg40',
  Bold28 = 'bold28',
  Reg28 = 'reg28',
  Bold20 = 'bold20',
  Reg20 = 'reg20',
  Bold16 = 'bold16',
  Reg16 = 'reg16',
  Bold14 = 'bold14',
  Reg14 = 'reg14',
  Bold13 = 'bold13',
  Reg13 = 'reg13',
  Bold12 = 'bold12',
  Reg12 = 'reg12',
  Bold10 = 'bold10',
  Bold12Expanded = 'bold12Expanded',
  Reg10Expanded = 'reg10Expanded',
}

export interface TypographyProps {
  className?: string
  view?: TypographyView
  children?: React.ReactNode
}
