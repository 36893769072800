import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import { Button, ButtonView } from 'src/components/button'
import { Modal } from 'src/components/modal'
import connector from 'src/decorators/connector'

import { Body, Title, Description, ButtonsWrapper, Media } from './styles'
import { ISVG } from '*.svg'

export interface IModalConfirmProps {
  title?: React.ReactNode | string
  description?: React.ReactNode | string

  media?: ISVG

  cancelText?: string
  onCancel?: () => void

  confirmText?: string
  onConfirm?: () => void

  okText?: string
  onOk?: () => void

  loading?: boolean
}

export type ModalConfirmProps = ReturnType<typeof storesToProps> &
  IModalConfirmProps

class ModalConfirm extends Component<ModalConfirmProps> {
  render() {
    const {
      title,
      media,
      description,

      cancelText,
      onCancel,

      confirmText,
      onConfirm,

      okText,
      onOk,

      loading,
    } = this.props
    const { modal } = this.props

    return (
      <Modal width={420} closeOnOverlay={false}>
        <Body>
          <Title>{title}</Title>
          {media && <Media icon={media} />}

          <Description>{description}</Description>

          <ButtonsWrapper>
            {okText ? (
              <Button
                view={ButtonView.Primary}
                onClick={onOk ?? modal.close}
                isLoading={loading}
              >
                {okText}
              </Button>
            ) : (
              <>
                <Button
                  view={ButtonView.Secondary}
                  onClick={onCancel ?? modal.reject}
                >
                  {cancelText}
                </Button>

                <Button
                  view={ButtonView.CTA}
                  onClick={onConfirm ?? modal.resolve}
                  isLoading={loading}
                >
                  {confirmText}
                </Button>
              </>
            )}
          </ButtonsWrapper>
        </Body>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  modal: store.modal,
})

export default connector(storesToProps)(ModalConfirm)
