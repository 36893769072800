import styled from 'src/styles'

import { Modal } from 'src/components/modal'
import { Typography, TypographyView } from 'src/components/typography'

export const StyledModal = styled(Modal)`
  .modal {
    z-index: 99999999;
  }

  .overlay {
    background-color: transparent;
  }
`

export const ErrorBody = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`

export const ErrorTitle = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  margin-bottom: 6px;
`

export const ErrorDescription = styled(Typography).attrs({
  view: TypographyView.Reg13,
})`
  line-height: 21px;
  color: ${(props) => props.theme.palette.black[100]};
`

export const ErrorImage = styled.img`
  margin-right: 60px;
`
