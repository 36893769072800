import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import Loader2 from 'src/components/loader2/Loader'
import { EditorImageState } from 'src/constants/editor/image'

import StyleBlock, { SCROLL_TO_ID } from '../style-block'

import {
  EmptyViewWrapper,
  ResultWrapper,
  EmptyViewTitle,
  LoadingWrapper,
} from './styles'

export type SearchViewProps = ReturnType<typeof storesToProps>

class SearchView extends Component<SearchViewProps> {
  public handleClassicStylesClick = () => {
    const { library } = this.props
    library.search('')

    // @todo need to write solution without setTimeout!
    setTimeout(() => {
      document.getElementById(SCROLL_TO_ID)?.scrollIntoView({
        behavior: 'smooth',
      })
    }, 100)
  }

  render() {
    const {
      library,
      editor,
      session,
      editor: { image: editorImage },
      account: { subscription },
    } = this.props

    if (library.searching) {
      return (
        <LoadingWrapper>
          <Loader2 size={48} />
        </LoadingWrapper>
      )
    }

    const stylesLength = library.styles.length
    if (!stylesLength) {
      return (
        <EmptyViewWrapper>
          <EmptyViewTitle>Filters not found</EmptyViewTitle>
        </EmptyViewWrapper>
      )
    }

    return (
      <ResultWrapper>
        <StyleBlock
          name={`${stylesLength} RESULTS`}
          styles={library.styles}
          selected={editorImage.activeStyle}
          inactive={editorImage.state === EditorImageState.processing}
          onSelect={editor.setActiveStyle}
          loggedIn={session.isLoggedIn}
          paidPlan={subscription?.plan}
        />
      </ResultWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  library: store.library,
  editor: store.editor,
  session: store.session,
  account: store.account,
})

export default connector(storesToProps)(SearchView)
