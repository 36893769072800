import React from 'react'

import { ModalWrapper } from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  className,
  width,
  onClose,
  closeOnOverlay,
  children,
}) => {
  return (
    <ModalWrapper
      className={className}
      width={width}
      isOpen={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={closeOnOverlay ?? true}
      shouldFocusAfterRender={false}
      ariaHideApp={false}
    >
      {children}
    </ModalWrapper>
  )
}

export default Modal
