import styled from 'src/styles'

import Svg from 'src/components/svg'
import { Typography, TypographyView } from 'src/components/typography'

export const Body = styled.div`
  padding: 36px 40px 40px;
`

export const Media = styled(Svg)`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  margin-bottom: 20px;
`

export const Description = styled(Typography).attrs({
  view: TypographyView.Reg13,
})`
  color: ${(props) => props.theme.palette.label.primary};

  margin-bottom: 20px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:last-child {
    margin-left: 12px;
  }
`
