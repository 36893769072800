import React, { Component } from 'react'

import amplitude from 'amplitude-js'

import { AMPLITUDE_KEY } from 'src/constants/analytics/analytics-account-keys'

// @todo need to think about Analytics Store, where we can init analytics/send events/send other options.
// maybe store some values: such device_id, etc...
class AmplitudeAnalytics extends Component {
  public componentDidMount() {
    amplitude.getInstance().init(AMPLITUDE_KEY)
  }

  public render() {
    return <></>
  }
}

export default AmplitudeAnalytics
