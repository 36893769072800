import { css } from 'src/styles'

/**
 * Вторичный стиль кнопок
 * Используются:
 */
export default css`
  ${({ theme }) => {
    return `
      color: ${theme.buttonsColors.default.color.secondary};
      background-color: ${theme.buttonsColors.default.background.secondary};
      border: 1px solid ${theme.palette.black[16]};
      
      &:hover:not(:disabled) {
        color: ${theme.buttonsColors.hover.color.secondary};
        background-color: ${theme.buttonsColors.hover.background.secondary};
        
        svg {
          fill: ${theme.buttonsColors.hover.color.secondary};
        }
      }
      
      &:disabled {
        opacity: 0.36;
      }
    `
  }}
`
