import React, { RefObject, useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { InputSearchProps } from './types'
import { Wrapper } from './styles'
import { CancelBtn, StyledInput } from '../input/styles'

// @todo first solution, need to think about it
/**
 * Поиск по фильтрам Призма
 * @param {string} placeholder Описание поля
 * @param {string} value Введённое значение
 * @param {function} onChange Обработчик события изменения строки поиска
 * @param {function} onFocus Обработчик события установки фокуса на поле
 * @param {function} onBlur Обработчик события снятия фокуса с поля
 * @param {boolean} disabled Заблокировать инпут
 */
export const InputSearch: React.FC<InputSearchProps> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
}: InputSearchProps) => {
  const ref: RefObject<HTMLInputElement> = React.createRef()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const debounced = useDebouncedCallback(
    (newValue: string) => onChange(newValue),
    200
  )

  const handleChange = (newValue: string) => {
    setInputValue(newValue)
    debounced(newValue)
  }

  return (
    <Wrapper>
      <StyledInput
        type="search"
        ref={ref}
        value={inputValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        cancelBtn={true}
      />
      {inputValue && <CancelBtn onClick={() => handleChange('')} />}
    </Wrapper>
  )
}

export default InputSearch
