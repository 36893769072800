import React from 'react'

import { Button, ButtonSize, ButtonView } from 'src/components/button'

import useUploadImage from 'src/components/dropzone/hooks'

import { Wrapper } from './styles'
import {
  getIndexedDBFile,
  setIndexedDBFile,
} from 'src/utils/file/indexedDBTransactions'

export interface IButtonUploadProps {
  canUpload: boolean
  onUpload: (file: File) => void
  onClick: (openHandler: () => void) => void
  afterUpload?: () => void
}

export const ButtonUpload: React.FC<IButtonUploadProps> = ({
  canUpload,
  onUpload,
  onClick,
  afterUpload,
}: IButtonUploadProps) => {
  const handleUpload = async (fileToSave: File) => {
    await setIndexedDBFile(fileToSave)
    onUpload(await getIndexedDBFile())
    await setTimeout(() => afterUpload(), 1000)
  }

  const { openHandler, getInputProps } = useUploadImage(handleUpload)
  const inputProps = getInputProps()

  const handleOpen = async () => {
    if (canUpload) {
      openHandler()
      // onUpload(await getIndexedDBFile()) TODO: maybe we need it
      // afterUpload?.()
    } else {
      onClick(async () => {
        openHandler()
        onUpload(await getIndexedDBFile())
        // await afterUpload()
      })
    }
  }

  return (
    <Wrapper>
      <input {...inputProps} />
      <Button
        view={ButtonView.Primary}
        size={ButtonSize.Medium}
        onClick={() => handleOpen()}
        fullWidth
      >
        Select file
      </Button>
    </Wrapper>
  )
}

export default ButtonUpload
