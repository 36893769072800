import GENERAL from './general'
import EDITOR from './editor'
import ONBOARDING from './onboarding'
import IMPORT from './import'
import SIGN_IN from './sign-in'
import PURCHASE from './purchase'
import SETTINGS from './settings'

/**
 * For more info @see https://www.notion.so/prismalabs/Events-Prisma-Web-2-0-2850001fb64641abbfbd144fcba088c5
 */
const EVENT_NAMES = {
  GENERAL,
  ONBOARDING,
  IMPORT,
  EDITOR,
  SIGN_IN,
  SETTINGS,
  PURCHASE,
}

export default EVENT_NAMES
