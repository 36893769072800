import { getRoot, types } from 'mobx-state-tree'
import { IStore } from '../index'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'

const UNSPLASH_OPEN_CLASSNAME = 'unsplash-open'

export const UnsplashStore = types
  .model('UnsplashStore', {
    isOpen: types.optional(types.boolean, false),
    noAnimation: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    resetAnimation() {
      self.noAnimation = false
    },
    open(noAnimation?: boolean) {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.IMPORT.importPopupShow)
      self.rootStore.account.loadCounter()
      self.noAnimation = noAnimation
      self.isOpen = true
    },
    close(noAnimation?: boolean) {
      self.noAnimation = noAnimation
      self.isOpen = false
    },
  }))
