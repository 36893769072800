import { Instance, types } from 'mobx-state-tree'

export interface ITags extends Instance<typeof Tags> {}

export const Tags = types.model('TagsLibrary', {
  id: types.string,
  name: types.string,
})

export default Tags
