import { flow, Instance } from 'mobx-state-tree'

import amplitude from 'amplitude-js'

import { AuthType } from 'src/constants/authentication'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import history from '../../../history'

import { BaseAuthentication } from '../index'

// @todo definitely need to revisit this code + move this from auth store
export default (self: Instance<typeof BaseAuthentication>) => ({
  postAuthActions: flow(function* (authMethod: string) {
    const { account, subscription, session } = self.rootStore
    account.sendEmailAllowance(self.allowEmailSend)

    yield account.init()

    AnalyticsController.sendEventWrapper(EVENT_NAMES.SIGN_IN.signInSuccess, {
      method: authMethod,
      source: self.source,
      account_id: session.userID,
    })

    const paywall = async (show: boolean, source: string) => {
      if (show) {
        subscription.openOutOfImportsModal(source)
        // @todo need to restore action here
      } else if (self.delayedAction) {
        await self.delayedAction()
      }
      self.stopFlow({ leaveModals: show })
    }

    if (self.type === AuthType.paywallApplyServerStyle) {
      yield paywall(!account.subscription?.active, AEventSource.premiumStyle)
    } else if (self.type === AuthType.paywallUploads) {
      // if user still can upload/process images, we don't need to show paywall
      yield paywall(!account.canUploadImage, AEventSource.uploadPhoto)
    } else if (self.type === AuthType.paywallResolution) {
      // if user already can apply HD resolution, we don't need to show paywall
      yield paywall(!account.canApplyHDResolution, AEventSource.editorHd)
    } else if (self.type === AuthType.paywallApplyExample) {
      self.stopFlow()
      yield self.delayedAction()
    } else {
      self.stopFlow()
    }

    history.push('/')
  }),
})
