import styled, { keyframes } from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'

const load = keyframes`
  0% {
    width: 0;
  }
  
  25% {
    width: 10%;
  }
  
  50% {
    width: 30%;
  }
  
  75% {
    width: 65%;
  }
  
  100% {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.overlay.base};

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 320px;
  background-color: ${(props) => props.theme.palette.background.base};

  padding: 24px 32px 32px 32px;

  border-radius: 16px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold16,
})`
  margin-bottom: 18px;
  text-align: center;
`

export const Loader = styled.div`
  position: relative;

  width: 256px;
  height: 5px;

  border-radius: 5px;

  background: ${(props) => props.theme.palette.label.tertiary};

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const LoaderBar = styled.div`
  width: 0;
  height: 5px;
  background: ${(props) => props.theme.palette.label.primary};
  border-radius: 5px;

  animation: ${load} 4.5s normal forwards;
`
