import styled from 'src/styles'
import checkmark from 'assets/icons/checkmark.svg'
import { StyleCellState } from '../library/style-block/style-cell'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  height: 44px;
  min-height: 44px;
  padding: 20px 20px 5px;
  background-color: transparent;
  box-sizing: content-box;
`

export const HistoryImageWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  transition: all ease-in-out 300ms;
  margin-left: 8px;

  &:last-child {
    margin-left: 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 0;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
    transition: opacity ease-in-out 300ms;
  }

  &:hover {
    transform: translateY(-4px);
    transition: transform ease-in-out 300ms;

    &::after {
      opacity: 1;
    }
  }
`

export const HistoryImage = styled.img<{ onClick: () => void }>`
  position: relative;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 8px;
`

export const Cover = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.black[64]};
}

& > svg {
  fill: ${({ theme }) => theme.palette.white[100]};
}
`
