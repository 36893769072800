import { RouteComponentProps, withRouter } from 'react-router-dom'
import connector from 'src/decorators/connector'
import React, { Component } from 'react'
import { IStoresMap } from 'src/types'
import { Logo, LogoWrapper } from 'src/containers/header/styles'
import logoSvg from 'assets/images/app-identity/prisma_logo_name.svg'
import { ModalName } from 'src/containers/modal-root'
import styled from '../../../styles'
import { AEventSource } from '../../../constants/analytics/event-sources'
import { Button, ButtonSize, ButtonView } from '../../../components/button'

type HeaderProps = RouteComponentProps & ReturnType<typeof storesToProps>

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
`

const Login = styled.button`
  ${({ theme }) => theme.typography.objToCss('reg14')}
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const Purple = styled.span`
  ${({ theme }) => theme.typography.objToCss('bold14')}
  color: ${({ theme }) => theme.accent.accent};
`

class Header extends Component<HeaderProps> {
  public render() {
    const { modal, authentication, session } = this.props

    return (
      <Wrapper>
        <LogoWrapper>
          <Logo icon={logoSvg} />
        </LogoWrapper>
        {session.isLoggedIn ? (
          <Button
            view={ButtonView.Secondary}
            size={ButtonSize.Small}
            onClick={() => modal.open(ModalName.account)}
          >
            Settings
          </Button>
        ) : (
          <Login
            onClick={() =>
              authentication.startFlow({
                source: AEventSource.onboarding,
              })
            }
          >
            <Purple>Login</Purple>
          </Login>
        )}
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  modal: store.modal,
  authentication: store.authentication,
  session: store.session,
})

export default withRouter(connector(storesToProps)(Header))
