import styled from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'
import { ModalTitleIcon } from 'src/components/modal/styles'
import { Button as BaseButton } from 'src/components/button'

export const ModalBody = styled.div`
  padding: 36px 40px 40px;
`

export const ModalTitle = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  margin-bottom: 40px;
`

// переопределение стилей старого кита
export const ModalClose = styled(ModalTitleIcon)`
  right: 26px;
  top: 26px;

  > svg {
    display: block;
    margin: 12px;
    transition: fill ease-out 200ms;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:disabled {
    background-color: transparent;
  }

  &:hover:not(:disabled) {
    > svg {
      fill: ${({ theme }) => theme.palette.label.secondary};
    }
  }
`

export const LogoWrapper = styled.div`
  width: 100%;
  margin: 10px 0 24px 0;
  display: flex;
  justify-content: center;
`

export const InputWrapper = styled.div`
  margin-bottom: 16px;
`

export const FormWrapper = styled.div`
  margin-bottom: 40px;
`

export const Divider = styled.div`
  margin: 20px 0;

  ${(props) => props.theme.typography.objToCss('reg13')}
  color: ${(props) => props.theme.palette.label.dark};
  text-align: center;
  line-height: 24px;
`

export const Button = styled(BaseButton)`
  span {
    transition: color 300ms ease-out;
  }

  &:disabled {
    opacity: 1;

    span {
      color: #808080;
    }
  }
`

export const Text = styled.div`
  margin-bottom: 40px;
  text-align: center;

  ${({ theme }) => theme.typography.objToCss(TypographyView.Reg13)};

  strong {
    display: block;
    font-weight: 700;
  }
`
