export const GENERAL = {
  // Приходит при первом входе юзера на сайт
  newUser: 'new_user',

  // Нажатие на ссылку Terms of Use
  // source: footer, sign_in_popup
  legalTermsTap: 'legal_terms_tap',

  // Нажатие на ссылку Privacy Policy
  // source: footer, sign_in_popup, cookies_popup
  legalPrivacyTap: 'legal_privacy_tap',

  // Интеракция с баннером кук
  // performance_cookie: true / false
  // functional_cookie: true / false
  // targeting_cookie: true / false

  cookiesPermissionStatus: 'cookies_permission_status',

  // Отправляем событие при появлении ошибки (фронтовой / бэковской), с типом или айдишником и мессаджем, который показываем юзеру
  // error_type: если есть, тут http код или id ошибки можно
  // error_text: string
  // source: onboarding, sign_in, paywall, upload, editor, style_library, style_apply, profile, subscription и др
  // ;onboarding, import, editor, settings, sign_in, purchase, processing
  errorShow: 'error_show',

  // Появление поп-апа с алертом
  // context: login_required, paid_feature, out_of_imports
  // source: onboarding, sign_in, paywall, upload, editor, style_library, style_apply, profile, subscription и др
  alertShow: 'alert_show',
}

export default GENERAL
