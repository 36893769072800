import { Logger, LoggerMethods } from './types'

let loggers: Logger[] = []

const log = (args: any[], method: LoggerMethods) => {
  loggers.forEach((logger) => {
    try {
      logger[method](...args)
    } catch (e) {
      // TODO: Handle Error
    }
  })
}

export default {
  setLoggers(value: Logger[]) {
    loggers = value
  },
  info(...args: any[]) {
    log(args, LoggerMethods.info)
  },
  debug(...args: any[]) {
    log(args, LoggerMethods.debug)
  },
  warn(...args: any[]) {
    log(args, LoggerMethods.warn)
  },
  error(...args: any[]) {
    log(args, LoggerMethods.error)
  },
}
