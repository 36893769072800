import styled, { keyframes } from 'src/styles'
import Svg from 'src/components/svg'

import { StyleCellProps, StyleCellState } from './types'

export const flicker = ({ theme }: any) => keyframes`
  0% {
    background-color: ${theme.palette.fill.tertiary};
  }
  50% {
    background-color: ${theme.palette.fill.quaternary};
  }
  100% {
    background-color: ${theme.palette.fill.tertiary};
  }
`

export const Wrapper = styled.div<StyleCellProps>`
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 88px;
  height: 88px;
  background-color: ${({ theme }) => theme.palette.background.cellar};
  border-radius: 10px;
  transition: transform ease-in-out 250ms;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.17);
    transition: opacity ease-in-out 300ms;
  }

  ${({ inactive, theme }) => {
    if (inactive) {
      return `
        pointer-events: none;
      `
    }

    return `
    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
      transition: transform ease-in-out 300ms;
      
      &::after {
        opacity: 1;
      }
      
      .style-cell-name {
        color: ${theme.palette.label.primary};
      }
    }
  `
  }}
`

export const Image = styled.img`
  width: 100%;
  height: 70px;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  animation: ${flicker} 1s linear infinite;
`

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 70px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: ${(props) => props.theme.palette.fill.tertiary};
`

export const Name = styled.div<{ state: StyleCellState; smallLabel: boolean }>`
  height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  color: ${(props) =>
    props.state === StyleCellState.Selected ||
    props.state === StyleCellState.FavoriteAdd
      ? props.theme.palette.label.primary
      : props.theme.palette.label.secondary};

  text-align: center;

  ${(props) =>
    props.smallLabel
      ? props.theme.typography.objToCss('bold8')
      : props.theme.typography.objToCss('bold10')}
`

export const Cover = styled.div<{ state: StyleCellState }>`
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100%;
  height: calc(100% - 18px);

  ${({ state, theme }) => {
    switch (state) {
      case StyleCellState.Selected:
        return `background-color: ${theme.palette.black[64]};`
      case StyleCellState.Locked:
        return `background-color: ${theme.palette.black[36]};`
      default:
        return ``
    }
  }}
  & > svg {
    fill: ${({ theme }) => theme.palette.white[100]};
  }
`

export const TopRightIcon = styled(Svg)`
  position: absolute;
  top: 4px;
  right: 4px;

  width: 22px;
  height: 22px;
`
