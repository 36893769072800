import { applySnapshot, Instance } from 'mobx-state-tree'

import { EditorImageState } from 'src/constants/editor/image'

import { IExampleImage } from '../../example-images'
import { BaseImage } from '../index'

export default (self: Instance<typeof BaseImage>) => ({
  setExampleImage(image: IExampleImage) {
    self.src = image.src
    self.originalSrc = image.originalSrc
    self.originalSrcHD = image.originalSrcHD
    self.file = image.file

    self.uploadId = image.uploadId
    self.uploadIdHD = image.uploadIdHD

    // we won't need to count processing if it's example image
    self.isFirstProcessing = false

    self.state = EditorImageState.uploaded
    self.sizes = image.sizes
    self.fileSize = image.fileSize
  },
  changeExampleOriginResolution(image: IExampleImage) {
    self.originalSrc = image.originalSrc
    self.file = image.file

    self.sizes = image.sizes
    self.fileSize = image.fileSize
  },
  resetBeforeUpload() {
    applySnapshot(self, {
      src: self.src,
      originalSrc: self.originalSrc,
      state: self.state,
    })
    self.rootStore.editor.exampleImages.setSelectedIndex(null)
  },
})
