import React, { useState, useEffect, useRef } from 'react'
import { getSearchedUnsplashPhotos } from 'src/api/sections/unsplash'
import { observer } from 'mobx-react'
import {
  Wrapper,
  UnsplashThumbWrapper,
  UnsplashThumbContent,
  UnsplashThumb,
  UnsplashCover,
  PaginationWrapper,
  StyledMasonry,
} from './styles'
import { EditorImageState } from 'src/constants/editor/image'
import Svg from 'src/components/svg'
import checkmark from 'assets/icons/checkmark.svg'
import { Pagination } from '../pagination'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'
import {
  getIndexedDBFile,
  setIndexedDBFileFromUrl,
} from 'src/utils/file/indexedDBTransactions'
const IMAGES_PER_PAGE = 30

export interface IUnsplashProps {
  query: string
  editorImage: any
  canUpload: boolean
  onUpload: (file: File) => void
  onClick: (openHandler: () => void) => void
  modalClose: () => void
  afterUpload?: () => void
}

export const Unsplash: React.FC<IUnsplashProps> = ({
  query,
  editorImage,
  canUpload,
  onUpload,
  onClick,
  afterUpload,
  modalClose,
}: IUnsplashProps) => {
  const [unsplashCollection, setUnsplashCollection] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState()
  const firstManualSearch = useRef(false)

  const actualQuery = query || 'random'

  const preUpload = async (url: string, filename: string) => {
    editorImage.setUploadingState()
    editorImage.rootStore.unsplash.close(true)
    await setIndexedDBFileFromUrl(url, filename)
  }

  const handleOpen = async (url: string, filename: string) => {
    if (canUpload) {
      await preUpload(url, filename)
      await onUpload(await getIndexedDBFile())
      afterUpload()
      modalClose()
    } else {
      modalClose()
      await onClick(async () => {
        await preUpload(url, filename)
        onUpload(await getIndexedDBFile())
        afterUpload()
      })
    }

    setSelectedPhoto(url)
  }

  const changePage = async (page: number) => {
    setUnsplashCollection(
      await getSearchedUnsplashPhotos(
        actualQuery,
        page,
        IMAGES_PER_PAGE,
        (R) => R.response.results
      )
    )
    setCurrentPage(page)
    await window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (editorImage.state === EditorImageState.uploading) {
      setSelectedPhoto(null)
    }
  }, [editorImage.state])

  useEffect(() => {
    ;(async () => {
      setUnsplashCollection(
        await getSearchedUnsplashPhotos(
          actualQuery,
          1,
          IMAGES_PER_PAGE,
          (R) => {
            if (actualQuery.length >= 3 && firstManualSearch.current) {
              AnalyticsController.sendFirebaseEvent(
                EVENT_NAMES.IMPORT.unsplashSearchResult,
                { search_text: actualQuery, photos_count: R.response.total }
              )
            }
            firstManualSearch.current = false
            setLastPage(R.response.total_pages)
            return R.response.results
          }
        )
      )
      await window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })()
    setCurrentPage(1)
  }, [actualQuery])

  return (
    <Wrapper>
      <StyledMasonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {unsplashCollection.map((result: any, index) => {
          return (
            <UnsplashThumbWrapper key={result.urls.full}>
              <UnsplashThumbContent>
                <picture>
                  <source
                    srcSet={`${result.urls?.thumb}&dpr=2 2x, ${result.urls?.thumb} 1x`}
                  />
                  <UnsplashThumb
                    src={result.urls?.thumb}
                    alt={result.alt_description}
                    onClick={() =>
                      handleOpen(result.urls.full, result.alt_description)
                    }
                  />
                </picture>

                {selectedPhoto === result.urls.full &&
                  editorImage.state === EditorImageState.uploaded && (
                    <UnsplashCover>
                      <Svg icon={checkmark} width={24} height={24} />
                    </UnsplashCover>
                  )}
              </UnsplashThumbContent>
            </UnsplashThumbWrapper>
          )
        })}
      </StyledMasonry>

      <PaginationWrapper>
        <Pagination
          query={query}
          lastPage={lastPage}
          currentPage={currentPage}
          changePage={changePage}
        />
      </PaginationWrapper>
    </Wrapper>
  )
}

export default observer(Unsplash)
