import React from 'react'

import { ISVG } from '*.svg'

export enum InputView {
  Square = 'square',
}

export interface InputProps {
  type?: string

  className?: string

  placeholder?: string

  value?: string

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void

  maxLength?: number
  min?: number
  max?: number

  startIcon?: ISVG
  endIcon?: ISVG

  cancelBtn?: boolean

  fullWidth?: boolean

  error?: string
  showError?: boolean

  disabled?: boolean

  view?: string

  label?: string
  autoFocus?: boolean
}
