import React, { Component } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'
import { EditorImageState } from 'src/constants/editor/image'

import InitialScreen from './initial-screen'
import ImageScreen from './image-screen'
import LoaderUploading from './loader-uploading'
import PlaceholderScreen from './placeholder-screen'

import { Wrapper } from './styles'
import { getIndexedDBFile } from '../../utils/file/indexedDBTransactions'
import { exampleImageSelectedIndexStorage } from '../../store/editor/models/example-images'

type EditorProps = RouteComponentProps & ReturnType<typeof storesToProps>

interface IEditorState {
  file: File | null | undefined
}
class Editor extends Component<EditorProps, IEditorState> {
  constructor(props: EditorProps) {
    super(props)

    this.state = {
      file: undefined,
    }
  }

  async componentDidMount() {
    const { onboarding, unsplash, editor, account } = this.props

    onboarding.initEditor()
    const exampleImageSelectedIndex = exampleImageSelectedIndexStorage.getParsed()

    this.setState({
      file: await getIndexedDBFile(),
    })

    if (this.state.file && account.canUploadImage) {
      editor.uploadImage(this.state.file)
    } else if (exampleImageSelectedIndex !== null) {
      await editor.uploadExampleImage(exampleImageSelectedIndex)
    } else if (!this.state.file) {
      unsplash.open()
    } else if (!onboarding.firstEnter && !onboarding.intensityTip) {
      unsplash.open()
    }
  }

  public render() {
    const {
      editor: { image: editorImage },
      isAllLoaded,
    } = this.props
    const { file } = this.state

    if (isAllLoaded && file === undefined) {
      return <Wrapper />
    }

    if (!isAllLoaded || file === undefined) {
      return (
        <Wrapper>
          <PlaceholderScreen />
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        {editorImage.state === EditorImageState.initial ? (
          <InitialScreen />
        ) : (
          <ImageScreen />
        )}
        {editorImage.state === EditorImageState.uploading && (
          <LoaderUploading />
        )}
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  account: store.account,
  session: store.session,
  editor: store.editor,
  isAllLoaded: store.isAllLoaded,
  onboarding: store.onboarding,
  unsplash: store.unsplash,
})

export default withRouter(connector(storesToProps)(Editor))
