import { applySnapshot, flow, getRoot, types } from 'mobx-state-tree'

import API from 'src/api'
import logs from 'src/utils/logs'
import StorageItem from 'src/utils/storage-item'
import { isResponseSuccess } from 'src/utils/server-response'
import {
  User,
  Subscription,
  getUserModel,
  getSubscriptionModel,
} from 'src/models/user'
import { DEFAULT_PROCESSING_COUNTER } from 'src/constants/processing-counter'

import { IStore } from '../index'
import { SubType } from '../../constants/subscription'
import { AnalyticsController } from '../../utils/analytics'
import subscription from '../../models/user/subscription'
// import { isBoolean } from 'util'
import { PlanAlias } from '../../constants/payment-flow'

export const guestAccountStorage = new StorageItem<{
  counter: number
}>(localStorage, 'account:guest_state')

export const Account = types
  .model('Account', {
    user: types.optional(types.maybeNull(User), null),
    isUserLoaded: false,

    subscription: types.optional(types.maybeNull(Subscription), null),
    isSubLoaded: false,

    counter: types.optional(types.maybeNull(types.number), null),
    isCounterLoaded: false,
  })
  .volatile((self) => ({
    localCounter:
      typeof guestAccountStorage.getParsed()?.counter === 'number'
        ? guestAccountStorage.getParsed()?.counter
        : 0,
  }))
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    get realCounter(): number {
      const localCounter =
        self.localCounter && self.localCounter > 0 ? self.localCounter : 0
      const serverCounter = self.counter && self.counter > 0 ? self.counter : 0

      return self.rootStore.session.isLoggedIn ? serverCounter : localCounter
    },
  }))
  .views((self) => ({
    get canUploadImage(): boolean {
      return self.realCounter > 0
    },
    get canApplyHDResolution(): boolean {
      return (
        self.subscription?.status === SubType.Active ||
        self.subscription?.status === SubType.Canceled ||
        self.subscription?.plan === PlanAlias.creator ||
        self.subscription?.plan === PlanAlias.pro
      )
    },
  }))
  .actions((self) => ({
    decreaseCounter: flow(function* () {
      const { session } = self.rootStore

      if (session.isLoggedIn) {
        self.counter = (yield API.user.getUserCounter()).processing_count
      } else {
        const localCounter =
          typeof guestAccountStorage.getParsed()?.counter === 'number'
            ? guestAccountStorage.getParsed().counter - 1
            : 0
        guestAccountStorage.stringifyAndSet({
          counter: localCounter,
        })
        self.localCounter = localCounter
      }
    }),
  }))
  .actions((self) => ({
    loadUser: flow(function* () {
      try {
        const userResponse = yield API.user.getUser()
        self.user = getUserModel(userResponse)
      } catch (e) {
        logs.error('User load error', e)
        self.rootStore.session.reset()
      } finally {
        self.isUserLoaded = true
      }
    }),
    loadSubscription: flow(function* () {
      try {
        const subscriptionResponse = yield API.subscription.getSubscription()
        self.subscription = getSubscriptionModel(subscriptionResponse)
      } catch (e) {
        logs.error('Subscription load error', e)
      } finally {
        self.isSubLoaded = true
        AnalyticsController.updateUserPropertiesWrapper({
          subscription_type: self.subscription?.plan || 'free',
          subscription_date_till: self.subscription
            ? +new Date(self.subscription.expirationDate)
            : null,
          autorenew_state:
            typeof self.subscription?.canCancel === 'boolean'
              ? self.subscription.canCancel
              : null,
        })
      }
    }),
    loadCounter: flow(function* () {
      const { session } = self.rootStore

      try {
        if (session.isLoggedIn) {
          // In case if user is authorized, we should use server to get
          // info about counter
          const { processing_count } = yield API.user.getUserCounter()
          self.counter = processing_count
        } else {
          // In case if user is guest, we should use local storage to save
          // info about counter
          const accountState = guestAccountStorage.getParsed()
          if (!accountState) {
            guestAccountStorage.stringifyAndSet({
              counter: DEFAULT_PROCESSING_COUNTER,
            })
            self.localCounter = DEFAULT_PROCESSING_COUNTER
          }
        }
      } catch (e) {
        logs.error('Get counter error', e)
      } finally {
        self.isCounterLoaded = true
      }
    }),
  }))
  .actions((self) => ({
    init: flow(function* () {
      applySnapshot(self, {})
      const { session } = self.rootStore
      if (session.isLoggedIn) {
        yield self.loadUser()
        yield Promise.all([self.loadSubscription(), self.loadCounter()])
        AnalyticsController.updateUserPropertiesWrapper({
          sign_in_status: true,
          account_id: session.userID,
        })
      } else {
        yield self.loadCounter()
        AnalyticsController.updateUserPropertiesWrapper({
          sign_in_status: false,
          account_id: null,
        })
      }
    }),
  }))
  .actions((self) => ({
    sendEmailAllowance: flow(function* (allowed: boolean) {
      const { status } = yield API.user.sendEmailAllowance(allowed)
      if (isResponseSuccess(status) && self.user) {
        self.user.allowEmailSend = allowed
      }
    }),
    cancelSubscription: flow(function* () {
      try {
        const { status } = yield API.subscription.cancelSubscription()
        if (isResponseSuccess(status)) {
          yield self.loadSubscription()
        }
      } catch (e) {
        logs.error('Cancel subscription error', e)
        throw e
      }
    }),
  }))
