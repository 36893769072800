import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { Unsplash } from '../../components/unsplash'
import {
  Wrapper,
  UnsplashHeader,
  SubscribeWrapper,
  CloseIcon,
  Login,
  UploadWrapper,
} from './styles'
import UnsplashPlaceholder from './unsplash-placeholder'
import closeIcon from 'assets/icons/close_new.svg'
import UploadsCounter from 'src/containers/uploads-counter'
import { AEventSource } from '../../constants/analytics/event-sources'
import ButtonUpload from '../editor/button-upload'
import UnsplashSearch from 'src/components/unsplash-search/unsplash-search'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'

export type UnsplashWrapperProps = ReturnType<typeof storesToProps>

class UnsplashWrapper extends Component<
  UnsplashWrapperProps,
  { query: string }
> {
  constructor(props: UnsplashWrapperProps | Readonly<UnsplashWrapperProps>) {
    super(props)

    this.state = {
      query: '',
    }
  }

  public render() {
    const {
      account,
      editor,
      isAllLoaded,
      authentication,
      session,
      unsplash,
    } = this.props

    const searchUnsplash = (value: string) => {
      this.setState({ query: value })
    }

    const afterUpload = () => {
      return AnalyticsController.sendEventWrapper(
        EVENT_NAMES.IMPORT.importSuccess,
        {
          photo_id: editor.image.id,
          photo_source: 'local_file',
          source: AEventSource.editor,
        }
      )
    }

    return isAllLoaded ? (
      <>
        <SubscribeWrapper>
          {session.isLoggedIn ? (
            <UploadsCounter />
          ) : (
            <Login
              onClick={() =>
                authentication.startFlow({
                  source: AEventSource.unsplash,
                })
              }
            >
              <span>Login</span>
            </Login>
          )}

          <CloseIcon icon={closeIcon} onClick={() => unsplash.close()} />
        </SubscribeWrapper>
        <Wrapper>
          <UnsplashHeader>
            <UnsplashSearch
              placeholder={'Search photos'}
              onChange={searchUnsplash}
              value={this.state.query}
            />

            <UploadWrapper>
              <p>Upload image from your computer</p>
              <ButtonUpload
                canUpload={account.canUploadImage}
                onUpload={editor.uploadImage}
                onClick={(openHandler) =>
                  editor.onDoesNotHaveUploadPermission(
                    openHandler,
                    AEventSource.unsplash
                  )
                }
                afterUpload={afterUpload}
              />
            </UploadWrapper>
          </UnsplashHeader>
          <Unsplash
            query={this.state.query}
            editorImage={editor.image}
            canUpload={account.canUploadImage}
            onUpload={editor.uploadImage}
            onClick={(openHandler) =>
              editor.onDoesNotHaveUploadPermission(
                openHandler,
                AEventSource.unsplash
              )
            }
            afterUpload={() =>
              AnalyticsController.sendEventWrapper(
                EVENT_NAMES.IMPORT.importSuccess,
                {
                  photo_id: editor.image.id,
                  photo_source: 'unsplash',
                  source: AEventSource.editor,
                }
              )
            }
            modalClose={() => {
              unsplash.close(true)
            }}
          />
        </Wrapper>
      </>
    ) : (
      <UnsplashPlaceholder />
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  editor: store.editor,
  isAllLoaded: store.isAllLoaded,
  authentication: store.authentication,
  session: store.session,
  unsplash: store.unsplash,
})

export default connector(storesToProps)(UnsplashWrapper)
