import { Instance, types } from 'mobx-state-tree'

import { ModalName } from 'src/containers/modal-root'

import { Session } from './session'
import { LibraryStore } from './library'
import { Account } from './account'
import { Authentication } from './authentication'
import { Subscription } from './subscription'
import { OnboardingStore } from './onboarding'
import { ModalStore, ModalEternalStore } from './modal'
import { Editor } from './editor'
import { UnsplashStore } from './unsplash'

const Store = types
  .model({
    onboarding: types.optional(OnboardingStore, {}),

    session: types.optional(Session, {}),
    authentication: types.optional(Authentication, {}),

    account: types.optional(Account, {}),
    subscription: types.optional(Subscription, {}),

    editor: types.optional(Editor, {}),
    library: types.optional(LibraryStore, {}),

    modal: types.optional(ModalStore, {}),
    modalEternal: types.optional(ModalEternalStore, {}),

    unsplash: types.optional(UnsplashStore, {}),
  })
  .views((self) => ({
    get isAllLoaded(): boolean {
      const { isRestored } = self.session
      const { isUserLoaded, isSubLoaded, isCounterLoaded } = self.account
      const { isLoaded } = self.library
      const { isInitialized } = self.editor.exampleImages

      const preConditions = isRestored
        ? isUserLoaded && isSubLoaded && isCounterLoaded // user potentially authorized
        : isCounterLoaded // user is guest
      return preConditions && isLoaded && isInitialized
    },
  }))
  // @todo uncomment
  // .actions((self) => ({
  //   afterCreate() {
  //     const image = cachedImage.getParsed()
  //     if (image) {
  //       self.editor.image.setImage(image)
  //     }
  //   },
  // }))
  .actions((self) => ({
    showGlobalError(message: string) {
      if (self.unsplash.isOpen) return
      self.modalEternal.open(ModalName.error, { message })
    },
  }))

export default Store

export interface IStore extends Instance<typeof Store> {}
