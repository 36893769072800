import { cast, types } from 'mobx-state-tree'

import { ModalName } from 'src/containers/modal-root'
import logs from 'src/utils/logs'

// @todo need to revisit modal structure
// pls see https://github.com/prisma-ai/prisma-web/tree/web-0-modal-refactored with example
// how we can improve it

export const Modal = types
  .model('Modal', {
    name: types.optional(
      types.maybeNull(
        types.enumeration('ModalName', [...Object.values(ModalName)])
      ),
      null
    ),
    props: types.optional(types.maybeNull(types.frozen()), null),
  })
  .volatile(() => ({
    promise: null,
    _resolve: null,
    _reject: null,
  }))
  .actions((self) => ({
    afterCreate() {
      self.promise = new Promise((resolve, reject) => {
        self._resolve = resolve
        self._reject = reject
      })
    },
  }))
  .actions((self) => ({
    resolve() {
      self._resolve()
    },
    reject() {
      self._reject()
    },
  }))

export const ModalStore = types
  .model('ModalStore', {
    current: types.optional(types.maybeNull(Modal), null),
  })
  .views((self) => ({
    get isOpened(): boolean {
      return Boolean(self.current)
    },
  }))
  .actions((self) => ({
    remove() {
      self.current = null
    },
  }))
  .actions((self) => ({
    resolve() {
      self.current?._resolve()
      self.remove()
    },
    reject() {
      self.current?._reject()
      self.remove()
    },
  }))
  .actions((self) => ({
    close() {
      self.reject()
    },
  }))
  .actions((self) => ({
    open(name: ModalName, props: object = {}) {
      if (self.isOpened) {
        self.close()
      }

      self.current = Modal.create({ name, props })
      self.current.promise.catch((e: Error) => {
        // prevent creating an uncaught promise
        logs.info('[MODALS]: caught unhandled error', e)
      })
      return self.current.promise
    },
  }))

// `Eternal` means, that only user can close modal (via actions).
// For now, we are using such type for cookie/error modals.
export const ModalEternalStore = types
  .model('ModalEternalStore', {
    content: types.array(Modal),
  })
  .actions((self) => ({
    getModel(name: ModalName) {
      return self.content.find((m) => m.name === name)
    },
    isOpened(name: ModalName): boolean {
      return self.content.findIndex((m) => m.name === name) !== -1
    },
    remove(name: ModalName) {
      self.content = cast(self.content.filter((m) => m.name !== name))
    },
  }))
  .actions((self) => ({
    open(name: ModalName, props: object = {}) {
      const modal = Modal.create({ name, props })

      if (self.isOpened(name)) {
        modal.reject()
      } else {
        self.content = cast([...self.content, modal])
      }

      modal.promise.catch((e: Error) => {
        // prevent creating an uncaught promise.
        logs.info('[MODALS]: caught unhandled error', e)
      })

      return modal.promise
    },
  }))
  .actions((self) => ({
    resolve(name: ModalName) {
      self.getModel(name)?.resolve()
      self.remove(name)
    },
    reject(name: ModalName) {
      self.getModel(name)?.reject()
      self.remove(name)
    },
  }))
  .actions((self) => ({
    close(name: ModalName) {
      self.reject(name)
    },
  }))

export default ModalStore
