import React from 'react'

import { TagProps } from './types'
import { TagWrapper } from './styles'

export const Tag: React.FC<TagProps> = ({
  className,
  onClick,
  caption,
  active,
}) => {
  return (
    <TagWrapper
      className={className}
      active={active}
      onClick={() => {
        onClick(caption)
      }}
    >
      {' #'}
      {caption}{' '}
    </TagWrapper>
  )
}

export default Tag
