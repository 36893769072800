import React, { Component } from 'react'

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import MobileDetect from 'mobile-detect'

import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'

import ROUTES from './routes'
import LandingPage from './landing'
import EditorMainPage from './editor'
import OnBoardingPage from './on-boarding'

import ModalUnsplash from 'src/containers/modal-unsplash'

import { Content, Wrapper } from './styles'

class Router extends Component<
  RouteComponentProps & ReturnType<typeof storesToProps>
> {
  public componentDidMount() {
    sessionStorage.clear()
  }

  public get md() {
    return new MobileDetect(window.navigator.userAgent)
  }

  public render() {
    const { onboarding, session } = this.props

    if (this.md.mobile()) {
      return (
        <Switch>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />

          <Redirect to={ROUTES.LANDING} />
        </Switch>
      )
    }

    return (
      <Wrapper onboarding={onboarding.firstEnter}>
        {!session.isLoggedIn ? (
          <Content>
            <Switch>
              <Route
                exact
                path={ROUTES.AUTH_APPLE}
                component={OnBoardingPage}
              />
              <Route path={ROUTES.ONBOARDING} component={OnBoardingPage} />

              <Redirect to={ROUTES.ONBOARDING} />
            </Switch>
          </Content>
        ) : (
          <Content>
            <Switch>
              <Route
                exact
                path={ROUTES.CONFIRM_EMAIL}
                component={EditorMainPage}
              />
              <Route
                exact
                path={ROUTES.AUTH_APPLE}
                component={EditorMainPage}
              />
              <Route exact path={ROUTES.APP} component={EditorMainPage} />

              <Route
                exact
                path={ROUTES.ONBOARDING}
                component={OnBoardingPage}
              />
              <Redirect to={ROUTES.APP} />
            </Switch>
          </Content>
        )}
        <div className="modal-root">
          <ModalUnsplash />
        </div>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  session: store.session,
  account: store.account,
  onboarding: store.onboarding,
})

export default withRouter(connector(storesToProps)(Router))
