import styled from 'src/styles'

import { flicker } from 'src/containers/library/style-block/style-cell/styles'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  top: -44px;
`

export const ExamplesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 12px;
`

export const ExampleImage = styled.div`
  width: 160px;
  height: 115px;
  border-radius: 6px;

  background-color: ${(props) => props.theme.palette.background.base};
  animation: ${flicker} 1s linear infinite;
`
