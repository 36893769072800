import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'

import StyleBlock from '../style-block'
import { LoadingWrapper } from '../search-view/styles'
import Loader2 from 'src/components/loader2/Loader'
import PageableStyleBlock from '../pageable-style-block'

export type BaseViewProps = ReturnType<typeof storesToProps>

export const EXAMPLE_IMAGES_DEFAULT_SELECTED_INDEX = 0

/**
 * @type {paidPlan} - backend don't return freeplan in subscription
 */
class BaseView extends Component<BaseViewProps> {
  constructor(props: BaseViewProps | Readonly<BaseViewProps>) {
    super(props)
  }

  render() {
    const {
      session,
      library,
      editor,
      editor: { image: editorImage },
      account: { subscription },
    } = this.props

    if (library.searching) {
      return (
        <LoadingWrapper>
          <Loader2 size={48} />
        </LoadingWrapper>
      )
    }

    return (
      <>
        <StyleBlock
          name={library.localStyleBlock.name}
          styles={library.localStyleBlock.styles}
          selected={editorImage.activeStyle}
          inactive={false}
          loggedIn={session.isLoggedIn}
          onSelect={editor.setActiveStyle}
          key={library.localStyleBlock.name}
        />

        {library.serverStyleBlocks.map((block, index) => {
          return (
            <React.Fragment key={`${block.id}_${index}`}>
              <PageableStyleBlock
                name={block.name}
                styles={block.styles}
                activeStyle={editorImage.activeStyle}
                editorImageState={editorImage.state}
                isLoggedIn={session.isLoggedIn}
                onSelect={editor.setActiveStyle}
                plan={subscription?.plan}
                itemsPerPage={50}
              />
            </React.Fragment>
          )
        })}
      </>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  library: store.library,
  session: store.session,
  editor: store.editor,
  account: store.account,
})

export default connector(storesToProps)(BaseView)
