import styled from 'src/styles'

export const Wrapper = styled.div`
  width: 100%;

  color: ${(props) => props.theme.palette.label.quaternary};

  ${(props) => props.theme.typography.objToCss('bold10')}

  transition: width linear 200ms;
`

export const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin: 0 auto;
  font-weight: 700;
`

// maybe we need to create separate component for links?
export const Link = styled.a`
  font-weight: 400;
  color: ${(props) => props.theme.palette.label.quaternary};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.palette.label.primary};
    text-decoration: underline;
  }
`

export const BoldLink = styled(Link)`
  font-weight: 700;
  color: #000000;
`
