const font = {
  family: 'Arial, sans-serif',
  weight: {
    bold: 700,
    medium: 500,
    regular: 400,
  },
}

const typography: { [index: string]: any } = {
  fontFamily: font.family,
  fontWeight: font.weight,
  reg40: {
    // title1
    fontSize: '40px',
    fontWeight: font.weight.regular,
    lineHeight: '44px',
    letterSpacing: '0.25px',
  },
  bold30: {
    // love designers
    fontSize: '30px',
    fontWeight: font.weight.bold,
    lineHeight: '34.5px',
    letterSpacing: '-0.32px',
  },
  bold28: {
    // title2
    fontSize: '28px',
    fontWeight: font.weight.bold,
    lineHeight: '34px',
    letterSpacing: '0.25px',
  },
  reg28: {
    fontSize: '28px',
    fontWeight: font.weight.bold,
    lineHeight: '34px',
    letterSpacing: '0.25px',
  },
  bold20: {
    // title3
    fontSize: '20px',
    fontWeight: font.weight.bold,
    lineHeight: '24px',
    letterSpacing: '0.25px',
  },
  reg20: {
    fontSize: '16px',
    fontWeight: font.weight.regular,
    lineHeight: '24px',
    letterSpacing: '0.25px',
  },
  bold16: {
    // headline bodyMedium
    fontSize: '16px',
    fontWeight: font.weight.bold,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  reg16: {
    //  bodyRegular
    fontSize: '16px',
    fontWeight: font.weight.regular,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  reg15: {
    fontSize: '15px',
    fontWeight: font.weight.regular,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  bold13: {
    // subheadlineMedium
    fontSize: '13px',
    fontWeight: font.weight.bold,
    lineHeight: '18px',
    letterSpacing: '0.15px',
  },
  reg14: {
    fontSize: '14px',
    lineHeight: '16.1px',
    fontWeight: font.weight.regular,
    // letterSpacing: '-0.32px',
  },
  bold14: {
    fontSize: '14px',
    fontWeight: font.weight.bold,
    lineHeight: '16.1px',
    // letterSpacing: '-0.32px',
  },
  reg13: {
    // subheadlineRegular
    fontSize: '13px',
    fontWeight: font.weight.regular,
    lineHeight: '18px',
    letterSpacing: '0.15px',
  },
  bold12: {
    // captionMedium
    fontSize: '12px',
    fontWeight: font.weight.bold,
    lineHeight: '16px',
    letterSpacing: '0.15px',
  },
  reg12: {
    // captionRegular
    fontSize: '12px',
    fontWeight: font.weight.regular,
    lineHeight: '16px',
    letterSpacing: '0.15px',
  },
  bold10: {
    fontSize: '10px',
    fontWeight: font.weight.bold,
    lineHeight: '12px',
    letterSpacing: '0.05px',
  },
  bold8: {
    fontSize: '8px',
    fontWeight: font.weight.bold,
    lineHeight: '10px',
    letterSpacing: '0',
  },
  bold12Expanded: {
    fontSize: '12px',
    fontWeight: font.weight.bold,
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  reg10Expanded: {
    fontSize: '10px',
    fontWeight: font.weight.regular,
    lineHeight: '12px',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
  },
  objToCss: (unit: string) => {
    const textTransform = typography[unit].textTransform
      ? `text-transform: ${typography[unit].textTransform};`
      : ''

    return `
      font-size: ${typography[unit].fontSize};
      font-weight: ${typography[unit].fontWeight};
      line-height: ${typography[unit].lineHeight};
      letter-spacing: ${typography[unit].letterSpacing};
      ${textTransform}
    `
  },
}

const shadows = {
  surface: '0 1px 6px rgba(25, 31, 37, 0.06)',
  layer: '0 1px 5px rgba(25, 31, 37, 0.12)',
  float: '0 6px 24px rgba(22, 29, 36, 0.12)',
}

const accent = {
  accent: '#8529ff',
  accent36: 'rgba(133, 41, 255, 0.36)',
  accent16: 'rgba(133, 41, 255, 0.16)',
  accent8: 'rgba(133, 41, 255, 0.08)',
  accentHover: '#7022d6',
}

const staticColors = {
  black: {
    100: '#000000',
    92: 'rgba(0, 0, 0, 0.92)',
    64: 'rgba(0, 0, 0, 0.64)',
    36: 'rgba(0, 0, 0, 0.36)',
    16: 'rgba(0, 0, 0, 0.16)',
    8: 'rgba(0, 0, 0, 0.08)',
  },
  white: {
    100: '#ffffff',
    92: 'rgba(255, 255, 255, 0.92)',
    64: 'rgba(255, 255, 255, 0.64)',
    36: 'rgba(255, 255, 255, 0.36)',
    16: 'rgba(255, 255, 255, 0.16)',
    8: 'rgba(255, 255, 255, 0.08)',
  },
  blue: {
    100: '#0d69ff',
  },
}

const buttonsColors = {
  default: {
    background: {
      CTA: accent.accent,
      primary: staticColors.black[100],
      secondary: staticColors.white[100],
      text: 'transparent',
    },
    color: {
      CTA: staticColors.white[100],
      primary: staticColors.white[100],
      secondary: staticColors.black[100],
      text: '#777777',
    },
  },
  hover: {
    background: {
      CTA: accent.accentHover,
      primary: staticColors.black[92],
      secondary: staticColors.white[100],
      text: 'transparent',
    },
    color: {
      CTA: staticColors.white[100],
      primary: staticColors.white[92],
      secondary: '#4d4d4d',
      text: '#4d4d4d',
    },
  },
  disabled: {
    color: {
      text: '#777777',
    },
  },
}

const placeholder = {
  placeholder: '#777777',
  placeholderHover: '#a0a0a0',
}

const tag = {
  inactiveBG: '#f4f4f5',
  activeBG: '#56b16f',
  inactiveText: '#4d4d4d',
  activeText: staticColors.white[100],
}

export const lightTheme = {
  typography,
  shadows,
  accent,
  buttonsColors,
  placeholder,
  tag,

  palette: {
    ...staticColors,

    label: {
      primary: staticColors.black[100],
      secondary: '#4d4d4d',
      tertiary: '#777777',
      quaternary: '#b2b2b2',
      dark: '#999999',
    },

    background: {
      base: staticColors.white[100],
      cellar: '#f8f8f8',
    },

    fill: {
      primary: '#e4e4e6',
      secondary: '#eaeaeb',
      tertiary: '#efeff0',
      quaternary: '#f4f4f5',
    },

    overlay: {
      base: 'rgba(0, 0, 0, 0.72)',
    },
  },
}

export const darkTheme = {
  typography,
  buttonsColors,
  placeholder,
  tag,

  palette: {
    ...staticColors,
    ...accent,

    label: {
      primary: staticColors.white[100],
      secondary: '#999999',
      tertiary: '#4c4c4c',
      quaternary: '#2e2e2e',
    },

    background: {
      base: staticColors.black[100],
      cellar: '#0f0f0f',
    },

    fill: {
      primary: '#2c2c2e',
      secondary: '#272729',
      tertiary: '#1d1d1f',
      quaternary: '#151517',
    },

    overlay: {
      base: 'rgba(0, 0, 0, 0.72)',
    },
  },
}

export type Theme = typeof lightTheme

export default lightTheme
