import { API_URL } from 'src/api/env'
import { RequestMiddleware, ResponseMiddleware } from 'src/api/types'

class ServiceBase {
  public get baseURL() {
    return API_URL
  }

  protected requestMiddleware: RequestMiddleware[] = []
  protected responseMiddleware: ResponseMiddleware[] = []

  public addRequestMiddleware(...middleware: RequestMiddleware[]) {
    this.requestMiddleware.push(...middleware)
  }

  public addResponseMiddleware(...middleware: ResponseMiddleware[]) {
    this.responseMiddleware.push(...middleware)
  }
}

export default ServiceBase
