export const SIGN_IN = {
  // Показ попапа с регистрацией/логином. В source записываем, откуда был открыт попап с логином:
  // source:
  //   onboarding — нажатие на ссылки «Create account» / «Login» во время онбординга;
  //   import — нажатие на ссылки «Create account» / «Login» на попапе с импортом фото;
  //   editor — нажатие на ссылки «Create account» / «Login» на экране редактора (кнопка над библиотекой стилей);
  //   alert_import_login_required — нажатие на ссылки «Create account» / «Login» на попапе с предупреждением при попытке импорта фото, если юзер не залогинился;
  //   alert_paid_feature — нажатие на ссылки «Create account» / «Login» на попапе с предупреждением при нажатии на HD или платный стиль, если юзер не залогинился.
  signInShow: 'sign_in_show',

  // Отправляется при нажатии на одну из кнопок авторизации: «Sign in with Apple», «Sign in with Google», «Continue» с имейлом
  // method: email, apple, google
  // source: sign_in_popup
  signInTap: 'sign_in_tap',

  // Отправляется при успешном логине
  // method: email_entered_code, email_magic_link, apple, google
  // subscription_status: true, false
  // + как user_property передаем email_subscription_status = true / false
  // + как user_property передаем sign_in = true
  signInSuccess: 'sign_in_success',

  // Отправляется при успешном разлогине
  // source: profile
  // + как user_property передаем sign_in = false
  signOutSuccess: 'sign_out_success',
}

export default SIGN_IN
