import React, { Component } from 'react'

import { EditorImageState } from 'src/constants/editor/image'

import StyleBlock from '../style-block'
import { IStyle } from 'src/models/library/style'
import PageableWrapper from '../pageable-wrapper'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { AnimationWrapper } from './styles'
import { StylesTitle } from '../style-block/styles'

export interface PageableStyleBlockProps {
  name: string
  styles: IStyle[]
  activeStyle: IStyle | null
  editorImageState: EditorImageState
  isLoggedIn: boolean
  onSelect: (style: IStyle) => void
  plan: string
  itemsPerPage: number
}

/**
 * @type {paidPlan} - backend don't return freeplan in subscription
 */
export default class PageableStyleBlock extends Component<
  PageableStyleBlockProps,
  { pageItems: IStyle[]; page: number; forward: boolean }
> {
  private myRef = React.createRef() as React.RefObject<HTMLBaseElement>
  constructor(
    props: PageableStyleBlockProps | Readonly<PageableStyleBlockProps>
  ) {
    super(props)
    this.state = { pageItems: [], page: 1, forward: true }
  }

  scroll = () => {
    this.myRef.current.scrollIntoView()
  }

  render() {
    const {
      isLoggedIn,
      name,
      activeStyle,
      editorImageState,
      onSelect,
      plan,
      styles,
    } = this.props

    return (
      <PageableWrapper
        items={styles}
        itemsPerPage={51}
        onPageUpdate={({ items, page }) => {
          this.setState({
            pageItems: items,
            page,
            forward: this.state.page - page > 0,
          })
          this.scroll()
        }}
      >
        <StylesTitle ref={this.myRef}>{name}</StylesTitle>
        <AnimationWrapper
          className={this.state.forward ? 'slide-up' : 'slide-down'}
        >
          <TransitionGroup>
            <CSSTransition
              in={true}
              timeout={500}
              classNames="slide"
              key={this.state.page}
            >
              <StyleBlock
                name={null}
                styles={this.state.pageItems}
                selected={activeStyle}
                inactive={editorImageState === EditorImageState.processing}
                loggedIn={isLoggedIn}
                onSelect={onSelect}
                paidPlan={plan}
              />
            </CSSTransition>
          </TransitionGroup>
        </AnimationWrapper>
      </PageableWrapper>
    )
  }
}
