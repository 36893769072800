import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { ModalName } from 'src/containers/modal-root'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { GLOBAL_ERROR } from 'src/constants/errors'

import { Email } from './styles'
import { Button, Info, Title } from '../styles'
import { isEmailHiddenByApple } from './utils'

export interface IUserBlockState {
  loading: boolean
}

export type UserBlockProps = ReturnType<typeof storesToProps>

class UserBlock extends Component<UserBlockProps, IUserBlockState> {
  constructor(props: UserBlockProps) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  public handleLogout = async () => {
    const { modal, session, account, showGlobalError } = this.props

    if (account.subscription?.active) {
      modal
        .open(ModalName.signOutConfirm)
        .catch(() => modal.open(ModalName.account))
    } else {
      try {
        this.setState({ loading: true })
        await session.logout()
        modal.resolve()
        session.clearSession()
      } catch (e) {
        const errorText = 'Logout went wrong. \n Try to refresh page'
        AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
          error_type: GLOBAL_ERROR,
          error_text: errorText,
          source: AEventSource.profile,
        })
        showGlobalError(errorText)
      }
    }
  }

  render() {
    const { account, session } = this.props
    const { loading } = this.state

    return (
      <>
        <Title>Your account:</Title>
        <Info>
          <Email>
            {isEmailHiddenByApple(account.user?.email, session.tokenType)
              ? 'email is hidden by Apple'
              : account.user?.email}
          </Email>
          <Button onClick={this.handleLogout} isLoading={loading}>
            Sign out
          </Button>
        </Info>
      </>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  session: store.session,
  modal: store.modal,
  showGlobalError: store.showGlobalError,
})

export default connector(storesToProps)(UserBlock)
