import {
  applySnapshot,
  cast,
  flow,
  getRoot,
  getSnapshot,
  toGenerator,
  types,
} from 'mobx-state-tree'

import API from 'src/api'
import logs from 'src/utils/logs'
import { LOCAL_STYLES_BLOCK } from 'src/constants/styles'
import { Library, Style, Tags } from 'src/models/library'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { GLOBAL_ERROR } from 'src/constants/errors'

import { IStore } from '../index'

import { applyCollectionNameToStyles, librarySearch } from './utils'

// Необходимо отправлять евент поиска только один раз на изображение
let librarySearchTapImageId: string | null = null

/**
 * Модель хранилища всех стилистических групп (см модель models/library)
 */
export const LibraryStore = types
  .model('LibraryStore', {
    // Base properties
    localStyleBlock: types.optional(
      Library,
      applyCollectionNameToStyles(LOCAL_STYLES_BLOCK)
    ),
    serverStyleBlocks: types.array(Library),

    // Search properties
    searchQuery: types.optional(types.string, ''),
    visibleSearchQuery: types.optional(types.string, ''),
    styles: types.array(Style),
    searching: types.optional(types.boolean, false),
    tags: types.array(Tags),
    searchedTags: types.array(Tags),
    isLoaded: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    setSearching(isSearch: boolean) {
      self.searching = isSearch
    },
  }))
  .actions((self) => ({
    loadStyles: flow(function* () {
      try {
        const { collections } = yield* toGenerator(API.styles.getStyles())
        self.serverStyleBlocks = cast(
          collections.map((collection) =>
            applyCollectionNameToStyles(collection)
          )
        )
      } catch (e) {
        const errorText =
          'Style loading went wrong. \n Check your internet connection'
        AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
          error_type: GLOBAL_ERROR,
          error_text: errorText,
          source: AEventSource.styleLibrary,
        })
        self.rootStore.showGlobalError(errorText)
        logs.error('Style load error', e)
      } finally {
        self.isLoaded = true
      }
    }),
    search: flow(function* (query: string) {
      self.searching = true
      self.searchQuery = query
      self.visibleSearchQuery = query

      if (self.searchQuery.length >= 3) {
        const imageId = self.rootStore.editor.image.id
        // send event only when imageId changed (UPD: send only first time in session)

        if (imageId !== librarySearchTapImageId) {
          if (sessionStorage.getItem('searchUse') !== 'true') {
            AnalyticsController.sendEventWrapper(
              EVENT_NAMES.EDITOR.librarySearchTap,
              {
                photo_id: self.rootStore.editor.image.id,
              }
            )
          }
          librarySearchTapImageId = imageId
          sessionStorage.setItem('searchUse', 'true')
        }
      }

      const preparedQuery = self.searchedTags.length
        ? `tags_ids=${self.searchedTags.map((tag) => `${tag.id}`).join(',')}`
        : `query=${query.toLowerCase()}`

      try {
        const { styles_ids } = yield* toGenerator(
          API.styles.search(preparedQuery)
        )

        self.styles = cast(
          librarySearch(
            styles_ids,
            [LOCAL_STYLES_BLOCK as any, ...getSnapshot(self.serverStyleBlocks)],
            self.rootStore.session.isLoggedIn,
            self.rootStore.account.subscription?.plan
          )
        )

        AnalyticsController.sendFirebaseEvent(
          EVENT_NAMES.EDITOR.librarySearchResult,
          {
            photo_id: self.rootStore.editor.image.id,
            search_text: preparedQuery,
            styles_count: self.styles.length,
            styles_names: self.styles.slice(0, 5).map((style) => style.artwork),
            tags_used: false, // cannot know which tags were used
          }
        )
      } catch (e) {
        self.searchQuery = ''
        self.rootStore.showGlobalError(
          'Search went wrong. \n Try to refresh page'
        )
      }

      self.searching = false
    }),
    loadTags: flow(function* () {
      try {
        const { tags } = yield API.tags.getTags()
        self.tags = tags
      } finally {
        self.isLoaded = true
      }
    }),
  }))
  .actions((self) => ({
    setSearchedTags(tag: any) {
      const foundTag = self.searchedTags.find((x) => x.name === tag.name)
      const tagIdPlace = foundTag ? self.searchedTags.indexOf(foundTag) : -1

      if (tagIdPlace > -1) {
        self.searchedTags.splice(tagIdPlace, 1)
      } else {
        self.searchedTags.push(tag)
      }

      self.search(
        getSnapshot(self.searchedTags)
          .map((x) => `#${x.name}`)
          .join(', ')
      )
    },
    setSearchedQuery(query: string) {
      self.searchedTags = cast([])
      self.search(query)
    },
    clearVisibleQuery() {
      if (self.searchedTags.length) {
        self.visibleSearchQuery = ''
      }
    },
    restoreVisibleQuery() {
      if (self.searchedTags.length) {
        self.visibleSearchQuery = self.searchQuery
      }
    },
    reset() {
      applySnapshot(self, {})
    },
  }))
