import { observe } from 'mobx'
import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree'

import API from 'src/api'
import { AuthMethod } from 'src/constants/authentication'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'

import { IStore } from '../index'

import restoreToken from './actions/restore-token'
import sessionUpdate from './actions/session-update'

export interface ISession extends Instance<typeof Session> {}

export const BaseSession = types
  .model('Session', {
    userID: types.maybe(types.string),
    deviceID: types.maybe(types.string),
    token: types.maybe(types.string),
    tokenType: types.maybe(
      types.enumeration('TokenType', [...Object.values(AuthMethod)])
    ),
    isRestored: false,
  })
  .views((self) => ({
    get rootStore(): IStore {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    get isLoggedIn() {
      return (
        Boolean(self.userID) && Boolean(self.token) && Boolean(self.tokenType)
      )
    },
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, {})
    },
  }))
  .actions((self) => ({
    refreshToken: flow(function* () {
      self.token = yield API.auth.refreshToken({
        user_id: self.userID,
        token: self.token,
        type: self.tokenType,
      })
    }),
    logout: flow(function* () {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.SETTINGS.signOutTap, {
        account_id: self.userID,
        source: 'settings',
      })
      yield API.auth.logout()
    }),
    clearSession() {
      sessionStorage.clear()
      self.reset()
      self.rootStore.editor.reset()
      self.rootStore.account.init()
    },
  }))

export const Session = BaseSession.actions(sessionUpdate)
  .actions(restoreToken)
  .actions((self) => ({
    afterCreate() {
      if (self.restoreFromCache()) {
        self.isRestored = true
      }
      self.initCache()
      self.setDeviceID()
      observe(self, 'isLoggedIn', (props: any) => {
        if (!props.newValue) {
          self.clearCache()
        }
      })
    },
  }))
