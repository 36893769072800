import SectionBase from 'src/api/sections/base'

export default class Uploads extends SectionBase {
  public uploadImage = (dataBlob: Blob) => {
    const data = new FormData()

    data.append(
      'image',
      // @ts-ignore
      new File([dataBlob], dataBlob?.name, { type: dataBlob.type })
    )

    return this.service.request({
      url: '/v2/upload/image',
      method: 'POST',
      data,
    })
  }
}
