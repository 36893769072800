import styled from 'src/styles'

import { Loader as BaseLoader } from 'src/components/loader2'
import { TypographyView } from 'src/components/typography'
import {
  ButtonCTA,
  ButtonPrimary,
  ButtonSecondary,
  ButtonText,
  ButtonSmall,
  ButtonMedium,
  ButtonLarge,
} from './sub-styles'
import { ButtonProps, ButtonSize, ButtonView } from './types'

export const BaseButton = styled.button`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: transparent;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: background-color ease-out 100ms;

  &:disabled {
    cursor: auto;
  }
`

export const StyledButton = styled(BaseButton)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.typography.objToCss(TypographyView.Bold16)};
  /* VIEW */
  ${({ view }) => {
    switch (view) {
      case ButtonView.CTA:
        return ButtonCTA
      case ButtonView.Primary:
        return ButtonPrimary
      case ButtonView.Secondary:
        return ButtonSecondary
      case ButtonView.Text:
        return ButtonText
      default:
        return ``
    }
  }}

  /* SIZES */
  ${({ size }) => {
    switch (size) {
      case ButtonSize.Small:
        return ButtonSmall
      case ButtonSize.Medium:
        return ButtonMedium
      case ButtonSize.Large:
        return ButtonLarge
      default:
        return ``
    }
  }}
  
  /* FULL WIDTH */
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  /* LOADER */
  ${({ isLoading }) => isLoading && `pointer-events: none;`}
  
  & > .loader {
    border-bottom-color: transparent;
  }

  /* ICONS */
  & svg {
    margin: 0 6px;

    &.icon-start {
      margin-left: 0;
    }

    &.icon-end {
      margin-right: 0;
    }
  }
`

export const ButtonCaption = styled.span<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
`

export const Loader = styled(BaseLoader)`
  position: absolute;
`
