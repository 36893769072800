import { useCallback } from 'react'

import { useDropzone } from 'react-dropzone'

import heic2any from 'heic2any'

const useUploadImage = (getSrc: (file: File) => void) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(async (file: File) => {
      getSrc(
        file.type === 'image/heic'
          ? ((await heic2any({
              blob: file,
              toType: 'image/jpeg',
            })) as File)
          : file
      )
    })
  }, [])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragReject,
  } = useDropzone({
    // disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png, image/heic',
    multiple: false,
    onDrop,
  })

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    openHandler: open,
  }
}

export default useUploadImage
