import styled from 'src/styles'

export const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`
export const SearchWrapper = styled.div`
  padding: 0 20px 20px;
  border-bottom: 1px solid #e6e6e6;
`

export const LibraryWrapper = styled.div`
  flex-grow: 1;
  overflow-y: overlay;
  //TODO: it's safari fix
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: #d6d6d6 transparent;

  // this trick allows to animate the scroll thumb transparency
  color: transparent;
  transition: color 300ms ease-out;

  &:hover,
  &:focus,
  &:focus-within {
    scrollbar-color: #d6d6d6;
    color: #d6d6d6;
  }

  &::-webkit-scrollbar {
    width: 6px;
    z-index: 1;
  }

  &::-webkit-scrollbar-track {
    height: 10px;
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    background: inherit;
    border-radius: 10px;
  }

  @-moz-document url-prefix() {
    & {
      overflow-y: scroll;
      scrollbar-color: transparent transparent;

      &:hover,
      &:focus,
      &:focus-within {
        scrollbar-color: #d6d6d6 transparent;
      }
    }
  }

  .Collapsible {
    color: ${({ theme }) => theme.palette.label.primary};
    border-bottom: 1px solid #e6e6e6;
  }

  .Collapsible__trigger {
    ${(props) => props.theme.typography.objToCss('bold12Expanded')}
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 20px;
    transition: color 200ms ease-out;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      top: 50%;
      right: 26px;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.50001 1.55025L6.44976 6.5L1.50001 11.4497' stroke='black' stroke-width='1.64645' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      transform: translateY(-50%);
      transition: transform linear 200ms;
    }

    &.is-open {
      padding-bottom: 0;

      &:after {
        transform: translateY(-5px) rotateZ(-90deg);
        top: 70%;
      }

      ~ .Collapsible__contentOuter {
        padding: 16px 20px 0;
        max-height: max-content;
        transition: all 200ms ease 0s !important;
      }
    }

    &.is-closed {
      ~ .Collapsible__contentOuter {
        max-height: 0;
        transition: all 200ms ease 0s !important;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.label.secondary};
    }
  }

  .Collapsible__contentOuter {
    padding: 0 20px;
  }
`
