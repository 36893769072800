import { flow, Instance } from 'mobx-state-tree'

import API from 'src/api'
import { Resolution } from 'src/constants/editor/resolution'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { GLOBAL_ERROR } from 'src/constants/errors'

import { compress } from '../utils'
import { FirstImageExpansion } from '../index'

export default (self: Instance<typeof FirstImageExpansion>) => ({
  compressAndUpload: flow(function* ({ doesResolutionChange = false } = {}) {
    try {
      const blob = yield compress({
        file: self.file,
        resolution: self.resolution,
      })

      if (!doesResolutionChange) {
        self.src = URL.createObjectURL(blob)
      }

      const { image_id } = yield API.uploads.uploadImage(blob)

      self.originalSrc = URL.createObjectURL(blob)

      if (self.resolution === Resolution.SD) {
        self.uploadId = image_id
      } else {
        self.uploadIdHD = image_id
      }
    } catch (e) {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: 'compression error',
        error_text: GLOBAL_ERROR,
        source: doesResolutionChange
          ? AEventSource.editorHd
          : AEventSource.uploadPhoto,
      })
      self.setErrorState()
      throw e
    }
  }),
})
