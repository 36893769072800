import React, { Component } from 'react'
import { AnalyticsController } from '../../../utils/analytics'
import EVENT_NAMES from '../../../constants/analytics/event-names'

class CookieManager extends Component {
  eventListenerName = 'OneTrustGroupsUpdated'
  // should ignore first update, because event OneTrustGroupsUpdated automatically fires first time
  ignoreUpdate = true

  eventListener: EventListener = ({ detail }: CustomEvent) => {
    const cookies = detail as string[]

    if (!this.ignoreUpdate) {
      const performanceCookie = cookies.includes('C0002')
      const functionalCookie = cookies.includes('C0003')
      const targetingCookie = cookies.includes('C0004')

      AnalyticsController.sendEventWrapper(
        EVENT_NAMES.GENERAL.cookiesPermissionStatus,
        {
          performance_cookie: performanceCookie,
          functional_cookie: functionalCookie,
          targeting_cookie: targetingCookie,
        }
      )
    }
    this.ignoreUpdate = false
  }

  public componentDidMount() {
    window.addEventListener(this.eventListenerName, this.eventListener)
  }

  componentWillUnmount() {
    window.removeEventListener(this.eventListenerName, this.eventListener)
  }

  public render() {
    return <></>
  }
}

export default CookieManager
