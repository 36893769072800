// @ts-nocheck
// tslint:disable only-arrow-functions no-unused-expression curly
import React, { Component } from 'react'
import {
  FACEBOOK_PIXEL_ID,
  FACEBOOK_PIXEL_ID_CHECKOUT_FLOW,
} from 'src/constants/analytics/analytics-account-keys'

class FacebookAnalytics extends Component {
  public componentDidMount() {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )

    fbq('init', FACEBOOK_PIXEL_ID)
    fbq('trackSingle', FACEBOOK_PIXEL_ID, 'PageView')

    fbq('init', FACEBOOK_PIXEL_ID_CHECKOUT_FLOW)
    fbq('trackSingle', FACEBOOK_PIXEL_ID_CHECKOUT_FLOW, 'PageView')
  }

  public render() {
    return (
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src={`https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
        />
        <img
          height="1"
          width="1"
          style="display:none"
          src={`https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID_CHECKOUT_FLOW}&ev=PageView&noscript=1`}
        />
      </noscript>
    )
  }
}

export default FacebookAnalytics
