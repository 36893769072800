import styled from 'src/styles'
import { Typography, TypographyView } from '../../components/typography'
import { darkTheme } from '../../styles/theme'
import { BaseButton } from 'src/components/button/styles'

export const Body = styled.div`
  padding: 32px 0 0;
`

export const SuccessImage = styled.img`
  margin-bottom: 40px;
`

export const SuccessBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin: 0 auto;
  padding: 48px 0 223px;
`

export const Header = styled.div`
  padding: 0 70px;

  &:last-child {
    padding: 0 62px 64px 40px; // todo: записать иначе
    min-height: 648px; // todo: подумать, как обеспечить одинаковую высоту на всех шагах
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 32px 70px;

  background-color: #f7f7f7;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold28,
})<{ withMargin?: boolean }>`
  letter-spacing: -1px;
  text-align: center;

  ${(props) => props.withMargin && 'margin-bottom: 62px;'}

  svg {
    margin-right: 9px;
  }
`

export const SubTitle = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  letter-spacing: 0;
  text-align: center;
  margin: 10px 0 44px;
  color: ${({ theme }) => darkTheme.palette.label.secondary};
`

export const TextBold = styled(Typography).attrs({
  view: TypographyView.Bold16,
})``

export const TextRegular = styled(Typography).attrs({
  view: TypographyView.Reg16,
})`
  margin-right: 8px;
`

export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;

  //gap: 8px;
  margin: 10px 0 8px;
`

export const OptionsRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 133px 133px 133px;
  justify-items: center;
  gap: 0 4px;

  ${({ theme }) => theme.typography.objToCss('reg16')};

  &:not(:first-child) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }
  }

  &:last-child {
    border-top: none;
  }
`

export const RadioRow = styled.div`
  display: grid;
  grid-template-columns: 133px 133px;
  justify-content: end;
`

export const OptionsCell = styled.div`
  padding: 14px 0;
  width: 100%;
  text-align: center;

  &:first-child {
    ${({ theme }) => theme.typography.objToCss('bold16')};
    text-align: start;
  }

  & svg {
    & path {
      fill: ${({ theme }) => theme.accent.accent};
    }
  }

  &.checkmark {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
`

export const PurpleCell = styled(OptionsCell)`
  background-color: ${({ theme }) => theme.accent.accent};
  color: ${({ theme }) => theme.palette.white[100]};
`

export const LightPurpleCell = styled(OptionsCell)`
  background-color: #f6f0ff;
  color: ${({ theme }) => theme.accent.accent};
`

export const RadioCell = styled(OptionsCell)`
  padding: 16px 0 20px;
`

export const WhiteTop = styled(OptionsCell)`
  text-align: center !important;
  grid-column-start: 2;
  padding-top: 17px;
  ${({ theme }) => theme.typography.objToCss('bold16')};
`

export const PurpleTop = styled(PurpleCell)`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  ${({ theme }) => theme.typography.objToCss('bold16')};
  padding-top: 17px;
`

export const LightPurpleTop = styled(PurpleTop)`
  background-color: #f6f0ff;
  color: ${({ theme }) => theme.accent.accent};
`

export const PurpleBottom = styled(PurpleCell)`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const LightPurpleBottom = styled(LightPurpleCell)`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const Back = styled(BaseButton)`
  position: absolute;
  display: flex;
  align-items: center;
  left: 40px;
  top: 30px;
  color: #a0a0a0;

  ${({ theme }) => theme.typography.objToCss('reg16')};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid #d8d8d8;
    margin-right: 10px;
  }

  svg {
    fill: ${({ theme }) => darkTheme.palette.label.secondary};
    width: 7px;
    height: 12px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;

  > div {
    width: calc(50% - 12px);
  }
`

export const SubscriptionTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
`

export const SuccessSubscriptionTitle = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg15')};
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
`

export const Billing = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg16')};
  color: #a0a0a0;
`

export const Total = styled.div`
  margin-top: 30px;
  ${({ theme }) => theme.typography.objToCss('reg16')};

  span {
    font-weight: 700;
  }
`

export const List = styled.div`
  margin-top: 30px;
  ${({ theme }) => theme.typography.objToCss('reg16')};
`

export const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  > svg {
    display: block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const StripeFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  ${({ theme }) => theme.typography.objToCss('reg12')};
`

export const StripeLogo = styled.div`
  display: flex;
  align-items: center;
  height: 10px;
  width: auto;
  color: #a0a0a0;

  svg {
    display: block;
    height: 14px;
    margin-left: 6px;
    width: auto;
    fill: #a0a0a0;
  }
`

export const StripeLinks = styled.div`
  display: flex;
  //gap: 12px;
  align-items: center;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #a0a0a0;

  > a {
    display: block;
    text-decoration: none;
    padding: 2px 0;
    color: #a0a0a0;

    &:last-child {
      margin-left: 12px;
    }
  }
`
