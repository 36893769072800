import axios, { CancelTokenSource } from 'axios'
import SectionBase from 'src/api/sections/base'
import { getDeviceIdFromStorage } from 'src/utils/authentication'

let ajaxRequest: CancelTokenSource | null

const deviceID = async () => await getDeviceIdFromStorage()

export default class Processing extends SectionBase {
  public getProcessedImage = async (
    imageId: string,
    model: string,
    isFree: boolean,
    isFirstProcessing: boolean
  ): Promise<void> => {
    if (ajaxRequest) {
      ajaxRequest.cancel()
    }
    ajaxRequest = axios.CancelToken.source()

    return this.service.request({
      // url: `/process/${imageId}/${model}/freeaspect${isFree ? '/free' : ''}`,
      url: `/v2/process/${model}/${imageId}`,
      method: 'GET',
      responseType: 'blob',
      cancelToken: ajaxRequest.token,
      headers: {
        'prisma-is-first-processing': isFirstProcessing,
        'prisma-device-id': await deviceID(),
      },
    })
  }
}
