import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { ButtonSize, ButtonView } from 'src/components/button'

import { Button, Content, SaveImageWrapper, Wrapper } from './styles'
import { getFileNameWithoutExtension } from '../../utils/file/extension'
import { downloadImage } from '../../utils/file/download'
import { EditorImageState } from '../../constants/editor/image'
import InputFilename from '../../components/input-filename'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'
import { Resolution } from '../../constants/editor/resolution'

export type SaveOpenBarProps = ReturnType<typeof storesToProps> & {
  onOpenImageUploader(): void
}

class SaveOpenBar extends Component<SaveOpenBarProps, { filename: string }> {
  constructor(props: SaveOpenBarProps | Readonly<SaveOpenBarProps>) {
    super(props)
    this.state = {
      filename: props.editor?.image.uploadId,
    }
  }

  public handleDownload = async () => {
    const {
      editor: { image: editorImage },
      library,
    } = this.props

    const { filename } = this.state

    const [originalName] = getFileNameWithoutExtension(
      editorImage.file?.name ?? 'image'
    ).split(' ')
    const styleName = (editorImage.activeStyle.artwork || '').toLowerCase()

    // const defaultName = `${originalName}_prisma_${styleName}.jpg`
    const defaultName = `Prisma_${styleName}.jpg`
    AnalyticsController.sendEventWrapper(EVENT_NAMES.EDITOR.editorSaveTap, {
      photo_id: editorImage.id,
      style_name: editorImage.activeStyle.artwork,
      collection_name: editorImage.activeStyle.collection_name,
      style_type: editorImage.activeStyle.paid ? 'premium' : 'free',
      style_source: library.searchQuery.length ? 'search' : 'library',
      styles_applied: editorImage.activeStyleHistory.length,
      hd_quality: editorImage.resolution === Resolution.HD,
      style_intensity: editorImage.intensity * 100,
    })

    downloadImage(
      // @ts-ignore
      await editorImage.imageDataUrl,
      defaultName || `${editorImage.uploadId}.jpg`
    )
  }

  public render() {
    const {
      editor: { image: editorImage },
      unsplash,
    } = this.props

    const changeFilename = (name: string) => {
      this.setState({ filename: name })
    }

    return (
      <Wrapper>
        <Content
          isSpaceBetween={editorImage.state !== EditorImageState.initial}
        >
          {editorImage.state !== EditorImageState.initial && (
            <SaveImageWrapper>
              <Button
                view={ButtonView.Primary}
                size={ButtonSize.Medium}
                onClick={this.handleDownload}
                disabled={editorImage.state !== EditorImageState.processed}
              >
                Save Image
              </Button>

              <InputFilename
                value={
                  getFileNameWithoutExtension(editorImage.file?.name) ||
                  editorImage.uploadId ||
                  'image'
                }
                className={'input-filename'}
                onChange={changeFilename}
                sizes={editorImage.sizes}
                fileSize={editorImage.fileSize}
              />
            </SaveImageWrapper>
          )}
          <Button
            view={ButtonView.Primary}
            size={ButtonSize.Medium}
            onClick={() => unsplash.open()}
          >
            Open new image
          </Button>
        </Content>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  editor: store.editor,
  unsplash: store.unsplash,
  library: store.library,
})

export default connector(storesToProps)(SaveOpenBar)
