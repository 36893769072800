import { css } from 'src/styles'

/**
 * Кнопки среднего размера
 * Используются:
 */
export default css`
  ${({ theme }) => {
    return `
      height: 44px;
      padding: 13px 24px;
      ${theme.typography.objToCss('bold13')}
      border-radius: 8px;
    `
  }}
`
