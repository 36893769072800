import { Instance, types } from 'mobx-state-tree'

import Style from './style'

export interface ILibrary extends Instance<typeof Library> {}

/**
 * Модель групп стилей (Prisma essentials, Special collection, Daily releases and etc...)
 */
export const Library = types.model('StyleLibrary', {
  id: types.string,
  name: types.optional(types.string, ''),
  styles: types.array(Style),
})

export default Library
