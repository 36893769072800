// IDs for auth
export const GOOGLE_WEB_CLIENT_ID =
  '521818952847-5je130icjqa3cb42jqf2mhvam0d5cek2.apps.googleusercontent.com'

export const APPLE_WEB_CLIENT_ID = 'com.prisma-ai.web-app'

export enum AuthType {
  noPaywall = 'no-paywall',
  paywallApplyExample = 'paywall-apply-example',
  paywallApplyServerStyle = 'paywall-apply-server-style',
  paywallUploads = 'paywall-uploads',
  paywallResolution = 'paywall-resolution',
  checkoutFlow = 'checkout-flow',
  default = noPaywall,
}

export enum AuthMethod {
  google = 'google',
  email = 'email',
  apple = 'apple',
}
