import { Instance, types } from 'mobx-state-tree'

import { IUserResponse } from 'src/api/sections/user/types'

export interface IUser extends Instance<typeof User> {}

export const User = types.model('User', {
  username: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  allowEmailSend: types.maybeNull(types.boolean),
})

export const getUserModel = ({
  username,
  email,
  email_allowed,
}: IUserResponse) => {
  return User.create({
    username,
    email,
    allowEmailSend: email_allowed,
  })
}

export default User
