import localforage from 'localforage'

export default () => {
  window.onbeforeunload = () => {
    window.localStorage.setItem('unloadTime', JSON.stringify(new Date()))
  }

  window.onload = async () => {
    const loadTime = new Date()
    const unloadTime = new Date(
      JSON.parse(window.localStorage.getItem('unloadTime'))
    )
    const refreshTime = loadTime.getTime() - unloadTime.getTime()

    if (refreshTime > 300000) {
      localforage.removeItem('imageFileToUpload')
    }

    window.localStorage.removeItem('unloadTime')
  }
}
