import { flow, Instance } from 'mobx-state-tree'

import { Resolution } from 'src/constants/editor/resolution'
import { AuthType } from 'src/constants/authentication'
import { AEventSource } from 'src/constants/analytics/event-sources'

import { FirstEditorExpansion } from '../index'

export default (self: Instance<typeof FirstEditorExpansion>) => ({
  setResolution: flow(function* (resolution: Resolution) {
    const { session, account, authentication, subscription } = self.rootStore

    const action = () => {
      self.image.setResolution(resolution)
    }

    if (account.canApplyHDResolution) {
      action()
      return resolution
    }

    if (session.isLoggedIn) {
      subscription.openUpgradeHdModal()
      // @TODO continue set resolution
    } else {
      authentication.startFlow({
        type: AuthType.paywallResolution,
        delayedAction: action,
        source: AEventSource.alertImportLoginRequired,
      })
    }
  }),
})
