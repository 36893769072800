import React, { Component } from 'react'
import { Pagination } from 'src/components/pagination'
import { PageableStyleBlockWrapper } from './styles'

export interface PageableWrapperProps {
  items: any[]
  itemsPerPage: number
  onPageUpdate: (pageUpdate: { page: number; items: any[] }) => void
}

export default class PageableWrapper extends Component<
  PageableWrapperProps,
  { currentPage: number; lastPage: null | number }
> {
  constructor(props: PageableWrapperProps | Readonly<PageableWrapperProps>) {
    super(props)
    this.state = {
      currentPage: 1,
      lastPage: null,
    }
  }

  componentDidMount() {
    this.changePage(1)
  }

  calculatePageMeta = (
    page: number
  ): { startIndex: number; endIndex: number; lastPage: number } => {
    const startIndex = (page - 1) * this.props.itemsPerPage
    const endIndex = startIndex + this.props.itemsPerPage
    const lastPage = Math.ceil(
      this.props.items.length / this.props.itemsPerPage
    )
    return { startIndex, endIndex, lastPage }
  }

  changePage = (currentPage: number) => {
    const { startIndex, endIndex, lastPage } = this.calculatePageMeta(
      currentPage
    )
    const pageItems = this.props.items.slice(startIndex, endIndex)
    this.props.onPageUpdate({ page: currentPage, items: pageItems })
    this.setState({ currentPage, lastPage })
  }

  render() {
    const { lastPage, currentPage } = this.state

    return (
      <>
        {this.props.children}
        <PageableStyleBlockWrapper>
          <Pagination
            lastPage={lastPage}
            currentPage={currentPage}
            changePage={this.changePage}
          />
        </PageableStyleBlockWrapper>
      </>
    )
  }
}
