import React from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { ButtonSize, ButtonView } from 'src/components/button'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'

import { InputWrapper, Button } from '../../styles'

import { CODE_LENGTH } from './input-verification-code'
import { InputView } from 'src/components/input/types'
import { Input } from 'src/components/input'

export const CODE_ERRORS = {
  IncorrectCode: 'Invalid Code',
}

export type FormProps = ReturnType<typeof storesToProps>

export interface IFormState {
  code: string
  loading: boolean
}

class Form extends React.Component<FormProps, IFormState> {
  public constructor(props: FormProps) {
    super(props)

    this.state = {
      code: '',
      loading: false,
    }
  }

  public isCodeValid(code: string): string {
    return code.length === CODE_LENGTH ? '' : CODE_ERRORS.IncorrectCode
  }

  public handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > CODE_LENGTH) return

    this.setState({ code: e.target.value })
  }

  public handleCodeFocus = () => {
    this.props.authentication.setError('')
  }

  public handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const { code } = this.state
    const { authentication } = this.props

    const error = this.isCodeValid(code)
    if (error) {
      authentication.setError(error)
      return
    }

    try {
      this.setState({ loading: true })
      await authentication.confirmByEmail({ code })
    } catch (e) {
      this.setState({ code: '', loading: false })
    }
  }

  public render() {
    const {
      authentication: { error, isCheckoutFlow },
    } = this.props
    const { code, loading } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <InputWrapper>
          <Input
            placeholder={'X'.repeat(CODE_LENGTH)}
            label="Login code:"
            value={code}
            onChange={this.handleCodeChange}
            onFocus={this.handleCodeFocus}
            error={error}
            view={InputView.Square}
            fullWidth
            showError
            autoFocus
          />
        </InputWrapper>

        <Button
          id={
            isCheckoutFlow
              ? 'prisma_checkout-flow_confirm_btn-submit'
              : undefined
          }
          view={ButtonView.Primary}
          size={ButtonSize.Medium}
          isLoading={loading}
          disabled={!code}
          fullWidth
        >
          Submit code
        </Button>
      </form>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
})

export default connector(storesToProps)(Form)
