import styled from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'
import { darkTheme } from '../../../styles/theme'

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  // TODO: скорее всего не нужно и убрать паддинг
  padding-top: 10px;
`

export const ResultWrapper = styled.div`
  height: 100%;
  overflow-y: overlay;

  scrollbar-width: thin;
  scrollbar-color: #d6d6d6 transparent;

  // this trick allows to animate the scroll thumb transparency
  color: transparent;
  transition: color 300ms ease-out;

  &:hover,
  &:focus,
  &:focus-within {
    scrollbar-color: #d6d6d6;
    color: #d6d6d6;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    height: 10px;
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    background: inherit;
    border-radius: 10px;
  }

  @-moz-document url-prefix() {
    & {
      overflow-y: scroll;
      scrollbar-color: transparent transparent;

      &:hover,
      &:focus,
      &:focus-within {
        scrollbar-color: #d6d6d6 transparent;
      }
    }
  }
`

export const EmptyViewWrapper = styled(LoadingWrapper)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
`

export const EmptyViewTitle = styled(Typography).attrs({
  view: TypographyView.Bold13,
})`
  margin-top: 40px;
  color: ${(props) => darkTheme.palette.label.secondary};
`
