import styled from 'src/styles'

import Svg from 'src/components/svg'

import { InputProps, InputView } from './types'

export const InputContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'inherit')};
`

export const InputWrapper = styled.div`
  position: relative;
`

export const BaseInput = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  padding: 11px 16px;
  ${({ theme, view }) =>
    theme.typography.objToCss(view === InputView.Square ? 'reg13' : 'bold13')}
  color: ${({ theme }) => theme.palette.black[100]};
  background-color: ${({ theme }) => theme.palette.white[100]};
  border: 1px solid ${({ theme }) => theme.palette.black[16]};
  border-radius: 20.5px;
  box-shadow: none;
  outline: 0;
  outline-offset: 0;
  transition: all ease-out 100ms;

  & ~ svg {
    fill: ${({ theme }) => theme.palette.black[100]};
  }

  &:placeholder-shown {
    transition: all ease-out 150ms;

    & ~ svg {
      fill: ${({ theme }) => theme.palette.black[100]};
    }
  }

  &::placeholder {
    ${({ theme }) => theme.typography.objToCss('reg13')}
    color: ${({ theme }) => theme.placeholder.placeholder};
    transition: color ease-out 100ms;
  }

  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.accent.accent};
  }

  &:hover {
    &::placeholder {
      color: ${({ theme }) => theme.placeholder.placeholderHover};
    }
  }

  &:focus:hover {
    box-shadow: 0 0 8px ${({ theme }) => theme.accent.accent16};
  }
`

export const StyledInput = styled(BaseInput)<InputProps>`
  /* With Icons */
  ${({ startIcon }) => startIcon && 'padding-left: 44px;'}
  ${({ endIcon }) => endIcon && 'padding-right: 44px;'}
  ${({ cancelBtn }) => cancelBtn && 'padding-right: 30px;'}
  
  &[type='search']::-webkit-search-cancel-button {
    appearance: none;
  }

  /* With Error */
  ${({ error, theme }) =>
    error &&
    `
    background-color: transparent;
    color: #CB0000;
    
    &:hover, &:focus {
      color: #CB0000;
    }
    
    &:focus {
      & ~ svg {
        fill: #CB0000;
      }
    }
  `}

  // square input
  ${({ view, theme }) =>
    view === InputView.Square &&
    `
      height: auto;
      padding: 5px 11px;
      border-radius: 4px;
      border: 1px solid #ccc;
      line-height: 24px;
      
      &:hover::placeholder,
      &::placeholder {
        color: ${theme.palette.label.dark};
      }
  `}

  // disabled
  ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: #f3f3f3;
      pointer-events: none;
      color: ${theme.palette.label.dark};
  `}
`

export const CancelBtn = styled.div`
  appearance: none;
  height: 13px;
  width: 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L7 5.58997L2.11 0.699971C1.72 0.309971 1.09 0.309971 0.700001 0.699971C0.310001 1.08997 0.310001 1.71997 0.700001 2.10997L5.59 6.99997L0.700001 11.89C0.310001 12.28 0.310001 12.91 0.700001 13.3C1.09 13.69 1.72 13.69 2.11 13.3L7 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.41 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 18px;
`

export const ErrorMessage = styled.div<{ visible: boolean }>`
  color: #cb0000;

  min-height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;

  display: ${({ visible }) => (visible ? 'block' : 'none')};

  ${({ theme }) => theme.typography.objToCss('bold12')}
  text-align: center;
`

export const Icon = styled(Svg)`
  position: absolute;
  top: 13px;
`

export const StartIcon = styled(Icon)`
  left: 13px;
`

export const EndIcon = styled(Icon)`
  right: 13px;
`

export const Label = styled.div`
  ${({ theme }) => theme.typography.objToCss('bold13')};
  line-height: 24px;
  color: #000000;
  margin-top: 7px;
`
