import styled from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  top: -10px;
`

export const QuickStartWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
  padding: 80px;
  background-color: ${({ theme }) => theme.palette.white[100]};
  border-radius: 16px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  color: ${({ theme }) => theme.palette.black[100]};
  margin-bottom: 35px;
`

export const ExamplesWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 16px;
`

export const ExampleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const SubTitle = styled(Typography).attrs({
  view: TypographyView.Reg13,
})`
  display: flex;
  flex-direction: row;
  letter-spacing: -0.325px;
  color: ${({ theme }) => theme.palette.black[100]};
  text-align: center;
  margin-bottom: 37px;
  flex-shrink: 0;
`

export const CloseWrapper = styled.div`
  position: absolute;
  top: 33px;
  right: 30px;
`

export const ExampleImageWrapper = styled.div`
  position: relative;
  width: 88px;
  height: 88px;
  transition: transform ease-in-out 200ms;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.17);
    transition: opacity ease-in-out 300ms;
  }

  &:hover {
    transform: translateY(-4px);
    transition: transform ease-in-out 300ms;

    &::after {
      opacity: 1;
    }
  }
`
