import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import styled, { css } from 'src/styles'
import { Button } from 'src/components/button'
import { Input } from 'src/components/input'
import { ButtonSize, ButtonView } from 'src/components/button/types'
import { InputView } from 'src/components/input/types'

export const FormWrapper = styled.div`
  position: relative;
`

export const PaymentWrapper = styled.div.attrs(
  (props: { quickPaymentLoaded: boolean }) => ({
    style: {
      opacity: props.quickPaymentLoaded ? 1 : 0,
    },
  })
)<{ quickPaymentLoaded: boolean }>`
  transition: opacity 500ms ease-in-out;
`

export const Title = styled.p`
  ${({ theme }) => theme.typography.objToCss('bold13')};
  line-height: 24px;
  color: #000000;
  margin-top: 7px;

  &:first-child {
    margin-top: 0;
  }
`

export const CardElementsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 0;
  grid-template-areas:
    'cardNumber cardNumber'
    'CardExpiry CardCvc';
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #cccccc;
  border-radius: 4px;
`

// todo разобраться, как прокинуть в страйп тему
export const cardStyles = {
  base: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color: '#000000',
    '::placeholder': {
      color: '#999999',
    },
  },
  invalid: {
    color: '#D02727',
  },
}

const CardElementBase = css`
  padding: 5px 8px;
`

export const CardInput = styled(Input).attrs({ view: InputView.Square })``

export const CardName = styled(Input)`
  margin-bottom: 7px;
`

export const CardNumber = styled(CardNumberElement)`
  ${CardElementBase};
  border-bottom: 1px solid #cccccc;
  grid-area: cardNumber;
`

export const CardExpiry = styled(CardExpiryElement)`
  ${CardElementBase};
  border-right: 1px solid #cccccc;
  grid-area: CardExpiry;
`

export const CardCvc = styled(CardCvcElement)`
  ${CardElementBase};
  grid-area: CardCvc;
`

export const PayButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`

export const PayButton = styled(Button).attrs({
  view: ButtonView.CTA,
  size: ButtonSize.Large,
})`
  width: auto;
  white-space: nowrap;
  margin-right: 21px;

  :disabled {
    opacity: 1;
    color: #c294ff;
  }
`

export const ErrorMessage = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg13')};
  display: block;
  width: 100%;
  margin: auto;
  letter-spacing: 0.15px;
  color: #cb0000;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  z-index: 1;
`
