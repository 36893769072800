import React from 'react'
import ReactModal from 'react-modal'

import styled from 'src/styles'
import { Icon } from 'src/components/icon'

// @todo need to avoid any
export const ModalWrapper = styled(
  ({ className, modalClassName, ...props }: any) => (
    <ReactModal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  )
).attrs({
  overlayClassName: 'overlay',
  modalClassName: 'modal',
})`
  .modal {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: ${({ theme }) => theme.palette.background.base};

    box-shadow: ${({ theme }) => theme.shadows.float};
    border-radius: 20px;

    overflow: hidden;

    &:focus {
      outline: none;
    }
  }

  .overlay {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: ${({ theme }) => theme.palette.overlay.base};

    z-index: 1000;
  }
`

// @todo need to think about styles below

export const ModalTitle = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.fill.secondary};
`

export const ModalTitleIcon = styled(Icon)<{ position?: string }>`
  position: absolute;
  top: 10px;
  ${({ position }) => position || 'right: '}10px;
  z-index: 1;
`

export const ModalBody = styled.div`
  padding: 24px 16px 16px 16px;
`
