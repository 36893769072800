/*
  Пояснение к коду:

  Сваппер временно отключен в виду отсутствия необходимости
  Возможно, в будущем Андрей Усольцев продумает, как расширить функционал
*/

import React, { Component } from 'react'
import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'
import { Resolution } from 'src/constants/editor/resolution'
// import { downloadImage } from 'src/utils/file/download'
// import { getFileNameWithoutExtension } from 'src/utils/file/extension'
import { EditorImageState } from 'src/constants/editor/image'
import LoaderProcessing from '../loader-processing'
import ProcessingError from '../processing-error'
// import imageSwapper from 'assets/icons/image-swapper.svg'
import Tooltip from 'src/components/tooltip'

import {
  ControlButton,
  ControlsWrapper,
  Image,
  ImagesWrapper,
  IntensitySlider,
  IntensitySliderWrapper,
  StyledImage,
  Wrapper,
} from './styles'
import { formatBytes } from 'src/utils/file/bytes'
import { AnalyticsController } from '../../../utils/analytics'
import EVENT_NAMES from '../../../constants/analytics/event-names'

const DEFAULT_INTENSITY = 100

export type IImageScreenProps = ReturnType<typeof storesToProps>

export interface IImageState {
  imageWidth: number
  imageHeight: number
  isDragging: boolean
  intensity: number
  currentSrc: string
  /*
  См. пояснение к коду выше
  sliderX: string
  isSwapperActive: boolean*/
}

class ImageScreen extends Component<IImageScreenProps, IImageState> {
  private imageRef = React.createRef() as React.RefObject<HTMLImageElement>
  private previousIntensity: null | number = null

  constructor(props: IImageScreenProps | Readonly<IImageScreenProps>) {
    super(props)
    this.state = {
      imageWidth: 0,
      imageHeight: 0,
      isDragging: false,
      intensity: DEFAULT_INTENSITY,
      currentSrc: this.props.editor.image.originalSrc,
      /*
      См. пояснение к коду выше
      sliderX: '50%',
      isSwapperActive: false,*/
    }
  }

  public setImageSize() {
    /**
     * Обработка через Object для обхода случая, когда this.imageRef.current === null
     */
    const {
      editor: { image: editorImage },
    } = this.props
    const { naturalWidth, naturalHeight } = Object(this.imageRef.current)
    if (!naturalWidth || !naturalHeight) return
    const { imageWidth, imageHeight } = this.state
    editorImage.setSizes(`${naturalWidth}x${naturalHeight} px`)

    if (imageWidth !== naturalWidth || imageHeight !== naturalHeight) {
      this.setState({
        imageWidth: naturalWidth,
        imageHeight: naturalHeight,
      })
    }
  }

  /*
  См. пояснение к коду выше
  public getCursorPosition(event: React.MouseEvent<HTMLDivElement>) {
    const imageRect = this.imageRef.current.getBoundingClientRect()
    let x = event.pageX - imageRect.left
    x = x - window.pageXOffset
    return x
  }

  public slideMove(event: React.MouseEvent<HTMLDivElement>) {
    if (this.state.isDragging) {
      event.stopPropagation()
      const cursorPosition = this.getCursorPosition(event)
      const { imageWidth } = this.state
      const sliderPosition =
        cursorPosition < 0
          ? 0
          : cursorPosition > imageWidth
          ? imageWidth
          : cursorPosition

      this.setState({
        sliderX: `${sliderPosition}px`,
      })
    }
  }*/

  public async toggleHD() {
    const { onboarding, editor } = this.props
    const toHD =
      editor.image.resolution === Resolution.SD ? Resolution.HD : Resolution.SD

    const isHD = await editor.setResolution(toHD)
    onboarding.completeHDTip()

    if (isHD) {
      AnalyticsController.sendEventWrapper(EVENT_NAMES.EDITOR.editorHdTap, {
        photo_id: editor.image.uploadId,
      })
    }
  }

  public getParams() {
    const {
      editor: { image: editorImage },
    } = this.props

    /*
    См. пояснение к коду выше
    const { sliderX, isSwapperActive } = this.state*/

    return {
      /*clipX: isSwapperActive ? sliderX : '0',*/
      disabled: editorImage.state !== EditorImageState.processed,
    }
  }

  public completeFirstTip = () => {
    this.props.onboarding.completeIntensityTip()
  }

  public completeSecondTip = () => {
    this.props.onboarding.completeHDTip()
  }

  public handleIntensity = async () => {
    const { intensity } = this.state
    const {
      onboarding,
      editor: { image: editorImage },
    } = this.props

    if (onboarding.intensityTip) this.completeFirstTip()

    editorImage.setIntensity(intensity / 100)

    editorImage.setFileSize(
      formatBytes(Math.round(((await editorImage.imageDataUrl).length * 3) / 4))
    )

    AnalyticsController.sendEventWrapper(
      EVENT_NAMES.EDITOR.editorIntensitySlider,
      {
        photo_id: editorImage.id,
        intensity_value: intensity,
        default_intensity: intensity === DEFAULT_INTENSITY,
      }
    )
  }

  public reset = () => {
    const {
      editor: { image: editorImage },
    } = this.props
    this.setState({
      intensity: DEFAULT_INTENSITY,
      // isHD: Resolution.SD,  TODO: может вынести в другой стейт
      currentSrc: this.props.editor.image.originalSrc,
    })
    editorImage.setIntensity(1)
  }

  componentDidMount() {
    this.setImageSize()
  }

  componentDidUpdate(
    prevProps: Readonly<IImageScreenProps>,
    prevState: Readonly<IImageState>,
    snapshot?: any
  ) {
    this.setImageSize()
    if (this.props.editor.image.originalSrc !== this.state.currentSrc) {
      this.reset()
    }
  }

  render() {
    const {
      editor: { image: editorImage },
      onboarding,
    } = this.props

    const {
      imageWidth,
      imageHeight,
      intensity,
      /*
      См. пояснение к коду выше
      sliderX,
      isSwapperActive,
      */
    } = this.state

    return (
      <Wrapper>
        <ControlsWrapper disabled={this.getParams().disabled}>
          <ControlButton
            isActive={editorImage.resolution === Resolution.HD}
            onClick={() => this.toggleHD()}
            children={'HD'}
            disabled={this.getParams().disabled}
          />

          <Tooltip
            body="Activate HD to get more detailed higher resolution output images."
            heading="Try HD processing"
            isOpen={
              !onboarding.intensityTip &&
              onboarding.HDTip &&
              editorImage?.activeStyleHistory.length > 2 &&
              editorImage.state === EditorImageState.processed
            }
            onClose={this.completeSecondTip}
            headingWidth="120px"
            bodyWidth="200px"
            left="69px"
            top="-25%"
          />

          <IntensitySliderWrapper disabled={this.getParams().disabled}>
            {intensity}%
            <IntensitySlider
              min={0}
              max={100}
              step={1}
              value={intensity}
              orientation="vertical"
              tooltip={false}
              reverse={false}
              onChange={(value: number) => this.setState({ intensity: value })}
              onChangeComplete={() => this.handleIntensity()}
              disabled={this.getParams().disabled}
            />
          </IntensitySliderWrapper>

          <Tooltip
            body="Blend original and filtered photo to create a unique combination."
            heading="Adjust intensity"
            isOpen={
              onboarding.intensityTip &&
              editorImage.state === EditorImageState.processed
            }
            onClose={this.completeFirstTip}
            headingWidth="120px"
            bodyWidth="200px"
            left="69px"
            top="35%"
          />

          {/*
          См. пояснения к коду выше
          <ControlButton
            isActive={isSwapperActive}
            onClick={() => this.setState({ isSwapperActive: !isSwapperActive })}
            startIcon={imageSwapper}
          />
          */}
        </ControlsWrapper>
        <ImagesWrapper editorState={editorImage.state}>
          <Image
            first={!!editorImage?.activeStyleHistory.length}
            src={
              editorImage.originalSrcHD
                ? editorImage.originalSrcHD
                : editorImage.originalSrc
            }
          />

          {editorImage.src && (
            <>
              {/*{isSwapperActive && (
              <ImageSlider
                left={sliderX}
                onMouseDown={() => this.setState({ isDragging: true })}
                onMouseUp={() => this.setState({ isDragging: false })}
              />
            )}*/}
              <StyledImage
                ref={this.imageRef}
                src={editorImage.src}
                onLoad={() => this.setImageSize()}
                intensity={intensity}
                alt="Editor Image"
              />
            </>
          )}
          {editorImage.state === EditorImageState.processing && (
            <LoaderProcessing
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            />
          )}
          {editorImage.state === EditorImageState.error && (
            <ProcessingError
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            />
          )}
        </ImagesWrapper>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  account: store.account,
  editor: store.editor,
  onboarding: store.onboarding,
})

export default connector(storesToProps)(ImageScreen)
