import React from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AuthMethod } from 'src/constants/authentication'

import { ButtonGoogleLogin } from 'src/components/button-google-login'

export type GoogleFormProps = ReturnType<typeof storesToProps>

export interface IEmailFormState {
  loading: boolean
}

class GoogleForm extends React.Component<GoogleFormProps, IEmailFormState> {
  public constructor(props: GoogleFormProps) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  public handleSubmit = async ({ code }: any = {}) => {
    this.setState({ loading: true })
    await this.props.authentication.authByGoogle(code)
  }

  public render() {
    const { authentication } = this.props
    const { loading } = this.state

    return (
      <ButtonGoogleLogin
        id={
          authentication.isCheckoutFlow
            ? 'prisma_checkout-flow_sign_btn-google-login'
            : undefined
        }
        onSubmit={this.handleSubmit}
        onClick={() => {
          AnalyticsController.sendEventWrapper(EVENT_NAMES.SIGN_IN.signInTap, {
            method: AuthMethod.google,
            source: authentication.source,
          })
        }}
        loading={loading}
      />
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
})

export default connector(storesToProps)(GoogleForm)
