import SectionBase from 'src/api/sections/base'

import {
  IAuthEmailRequest,
  IAuthGoogleRequest,
  IAuthGoogleResult,
  IAuthRefreshTokenRequest,
  IConfirmRequest,
} from './types'

import { getDeviceIdFromStorage } from 'src/utils/authentication'

const timeZoneOffset = new Date().getTimezoneOffset() * 60 * -1
const deviceID = async () => await getDeviceIdFromStorage()

export default class Auth extends SectionBase {
  public authByEmail = (data: IAuthEmailRequest): Promise<string> =>
    this.service
      .request({
        url: '/auth/email',
        method: 'POST',
        data: {
          ...data,
        },
        headers: {
          'prisma-timezone-offset': timeZoneOffset,
          'prisma-app': 'prisma',
          'prisma-platform': 'web',
        },
      })
      .then(({ user_id }) => user_id)

  public confirmByEmail = async (data: IConfirmRequest) =>
    this.service
      .request({
        url: '/auth/confirm/email',
        method: 'POST',
        data: {
          ...data,
        },
        headers: {
          'prisma-device-id': await deviceID(),
        },
      })
      .then(({ token }) => token)
      .catch((error) => {
        throw error?.response?.status === 500
          ? new Error('Invalid code, please enter one from email')
          : error
      })

  public authByGoogle = async (
    data: IAuthGoogleRequest
  ): Promise<IAuthGoogleResult> =>
    this.service
      .request({
        url: '/auth/google',
        method: 'POST',
        data: {
          ...data,
          redirect_uri: window.location.origin,
        },
        headers: {
          'prisma-device-id': await deviceID(),
          'prisma-timezone-offset': timeZoneOffset,
          'prisma-app': 'prisma',
          'prisma-platform': 'web',
        },
      })
      .then(({ token, user_id, email }) => ({
        token,
        email,
        userId: user_id,
      }))

  public refreshToken = async (
    data: IAuthRefreshTokenRequest
  ): Promise<{ token: string }> =>
    this.service
      .request({
        url: '/auth/refresh_token',
        method: 'POST',
        data: {
          ...data,
          app: 'prisma',
          platform: 'web',
          device_id: await deviceID(),
        },
      })
      .then(({ token }) => token)

  public logout = async () => {
    return this.service.request({
      url: '/auth/user/logout',
      method: 'POST',
      headers: {
        'prisma-device-id': await deviceID(),
      },
    })
  }
}
