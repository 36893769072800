import styled, { keyframes } from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'

const spinner = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const Wrapper = styled.div.attrs(
  // @ts-ignore
  ({ imageWidth: w, imageHeight: h, theme }) => ({
    style: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' id='visual' viewBox='0 0 ${w} ${h}' width='${w}' height='${h}' version='1.1'%3E%3Crect fill='${theme.palette.black[64]}' width='${w}' height='${h}' /%3E%3C/svg%3E")`,
    },
  })
)<{ imageHeight: number; imageWidth: number }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Spinner = styled.div`
  position: relative;
  width: 207px;
  height: 207px;
`

export const SpinnerStick = styled.div<{ num: number }>`
  transform: translateZ(0) scale(0.7);
  backface-visibility: hidden;

  &:after {
    position: absolute;
    top: 80px;
    left: 101px;
    content: '';
    display: block;
    width: 4px;
    height: 12px;
    border-radius: 1px;
    background: ${({ theme }) => theme.palette.white[100]};
    transform-origin: 2px 22px;
    animation: ${spinner} 1.1s linear infinite;
    animation-delay: ${({ num }) => -1.1 + num / 7}s;
    transform: rotate(${({ num }) => num * 45}deg);
  }
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Reg13,
})`
  color: ${(props) => props.theme.palette.white[100]};
`
