import { Instance } from 'mobx-state-tree'
import { autorun } from 'mobx'

import StorageItem from 'src/utils/storage-item'

import { BaseSession } from '../index'

export const sessionStorage = new StorageItem<{
  userID: string
  token: string
  tokenType: string
}>(localStorage, 'session:state')

export default (self: Instance<typeof BaseSession>) => {
  let cacheReactionDisposer: any = null

  return {
    initCache: () => {
      if (!cacheReactionDisposer) {
        cacheReactionDisposer = autorun(() => {
          if (!self.userID || !self.token || !self.tokenType) {
            return
          }

          sessionStorage.stringifyAndSet({
            userID: self.userID,
            token: self.token,
            tokenType: self.tokenType,
          })
        })
      }
    },
    restoreFromCache: () => {
      const cachedState = sessionStorage.getParsed()
      if (!cachedState) {
        return false
      }

      const { token, userID, tokenType } = cachedState
      if (!token || !userID || !tokenType) {
        return false
      }

      self.token = token
      self.userID = userID
      self.tokenType = tokenType

      return true
    },
    clearCache: () => {
      sessionStorage.clear()
    },
  }
}
