import { cast, flow, Instance, types } from 'mobx-state-tree'

import {
  EXAMPLE_IMAGE_IDS,
  EXAMPLE_IMAGES,
  EXAMPLE_IMAGES_HD,
} from 'src/constants/example-images'
import { uploadFile } from 'src/utils/file/upload'
import StorageItem from 'src/utils/storage-item'

export interface IExampleImage extends Instance<typeof ExampleImage> {}

export const exampleImageSelectedIndexStorage = new StorageItem<number | null>(
  localStorage,
  'example_image:index'
)

export const ExampleImage = types
  .model('ExampleImage', {
    src: types.optional(types.maybeNull(types.string), null),
    originalSrc: types.optional(types.maybeNull(types.string), null),
    uploadId: types.optional(types.maybeNull(types.string), null),
    uploadIdHD: types.optional(types.maybeNull(types.string), null),
    sizes: types.optional(types.maybeNull(types.string), null),
    fileSize: types.optional(types.maybeNull(types.string), null),
    intensity: types.optional(types.maybeNull(types.number), 1),
    errorStyle: types.optional(types.maybeNull(types.string), null),
    originalSrcHD: types.optional(types.maybeNull(types.string), null),
  })
  .volatile(() => ({
    file: null,
  }))
  .actions((self) => ({
    setFile(file: File) {
      self.file = file
    },
    setOriginalSrcHD(src: string) {
      self.originalSrcHD = src
    },
  }))

export const ExampleImages = types
  .model('ExampleImages', {
    images: types.array(ExampleImage),
    imagesHd: types.array(ExampleImage),
    selectedIndex: types.optional(types.maybeNull(types.number), null),
    isInitialized: false,
  })
  .views((self) => ({
    get isSelected() {
      return self.selectedIndex !== -1
    },
  }))
  .actions((self) => ({
    createExample(images: 'images' | 'imagesHd', uploaded: any) {
      self[images] = cast(
        EXAMPLE_IMAGE_IDS.map((ids, index) => {
          const x = ExampleImage.create({
            ...uploaded[index],
            ...ids,
          })

          x.setFile(uploaded[index].file)

          return x
        })
      )
    },
    createSrcHD(uploaded: any) {
      EXAMPLE_IMAGE_IDS.map((item, index) => {
        self.images[index].setOriginalSrcHD(uploaded[index].originalSrc)
      })
    },
  }))
  .actions((self) => ({
    afterCreate: flow(function* () {
      yield Promise.all(EXAMPLE_IMAGES.map((image) => uploadFile(image))).then(
        (uploaded) => {
          self.createExample('images', uploaded)
        }
      )

      yield Promise.all(
        EXAMPLE_IMAGES_HD.map((image) => uploadFile(image))
      ).then((uploaded) => {
        self.createExample('imagesHd', uploaded)
        self.createSrcHD(uploaded)
      })
      self.isInitialized = true
    }),
    setSelectedIndex(index: number | null) {
      self.selectedIndex = index
      exampleImageSelectedIndexStorage.set(index)
    },
  }))

export default ExampleImages
