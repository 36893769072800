import React from 'react'

import Svg from 'src/components/svg'

import { ButtonProps, ButtonSize } from './types'
import { ButtonCaption, Loader, StyledButton } from './styles'

/**
 * Общий компонент кнопки
 * @param id string - html-атрибут ID
 * @param className string - css класс
 * @param startIcon string - иконка кнопки до текста
 * @param endIcon string - иконка кнопки после текста
 * @param children string - текст кнопки
 * @param onClick function - родительский callback
 * @param view ButtonView - внешний вид кнопки (см. components/button/sub-styles)
 * @param size ButtonSize - размер кнопки (см. components/button/sub-styles)
 * @param fullWidth boolean - параметр, определяющий, что кнопка должна занимать всю ширину родителя
 * @param disabled - boolean - html-атрибут дизейблед
 * @param isLoading boolean - параметр, определяющий, происходит ли процесс загрузки, связанный с кнопкой (например, процесс upload)
 * @constructor
 */
export const Button: React.FC<ButtonProps> = ({
  id,
  className,
  startIcon,
  endIcon,
  children,
  onClick,
  view,
  size,
  fullWidth,
  disabled,
  isLoading,
}: ButtonProps) => {
  return (
    <StyledButton
      id={id}
      className={className}
      view={view}
      size={size ?? ButtonSize.Medium}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {startIcon && !isLoading && (
        <Svg className="icon-start" icon={startIcon} />
      )}
      <ButtonCaption isLoading={isLoading}>{children}</ButtonCaption>
      {endIcon && !isLoading && <Svg className="icon-end" icon={endIcon} />}
      {isLoading && <Loader className="loader" />}
    </StyledButton>
  )
}

export default Button
