import styled from 'src/styles'

export const Wrapper = styled.div<{ onboarding?: boolean }>`
  font-family: ${(props) => props.theme.typography.fontFamily};

  ${(props) =>
    props.onboarding &&
    `
    background: ${props.theme.palette.background.base};
  `}// background: ${(props) => props.theme.palette.background.cellar};
`

export const Content = styled.div`
  margin: 0 auto;

  width: 100%;
  min-height: 312px;
  display: flex;
`
