import styled from 'src/styles'
import { BaseButton } from '../../../components/button/styles'
import { TagStyledSpan } from '../../../components/tag/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin: -4px;

  > * {
    margin: 4px;
  }
`

export const ShowTagsButton = styled(BaseButton)<{ isOpen: boolean }>`
  ${TagStyledSpan};
  display: flex;
  align-items: center;
  background-color: #777777;

  svg {
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  }
`
