import styled from 'src/styles'

export const Wrapper = styled.div`
  display: flex;
  width: min-content;
  margin-top: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`

export const PaginationItem = styled.button<{
  active?: boolean
  arrow?: boolean
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border: none;
  border-right: 1px solid #d9d9d9;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.fill.tertiary : 'transparent'};
  ${({ theme }) => theme.typography.objToCss('reg12')}
  color: ${({ theme }) => theme.palette.label.primary};

  font-size: ${({ arrow }) => (arrow ? '9px' : '12px')};
  cursor: pointer;

  &:last-child {
    border-right: none;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.cellar};
  }

  &:disabled {
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`
