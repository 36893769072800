import React, { useState, useEffect } from 'react'
import { Tag } from 'src/components/tag'
import Svg from 'src/components/svg'
import { Wrapper, ShowTagsButton } from './styles'
import forwardIcon from 'assets/icons/forward.svg'
import { getSnapshot } from 'mobx-state-tree'

export interface TagProps {
  query: string
  onChange: (value: string) => void
  searching: boolean
  // TODO: no any
  tags: any
}

export const Tags: React.FC<TagProps> = ({
  onChange,
  tags,
  query,
}: TagProps) => {
  const [tagsVisible, setTagsVisible] = useState(false)
  const [backendTags, setBackendTags] = useState([])

  useEffect(() => {
    tags?.length ? setBackendTags(getSnapshot(tags)) : setBackendTags([])
  }, [tags])

  const toggleTagsVisible = () => {
    setTagsVisible((value) => !value)
  }

  return (
    <Wrapper>
      {!!backendTags.length && (
        <>
          {backendTags.map((item, index) => (
            <Tag
              key={item.id}
              caption={item.name}
              active={query.includes(item.name)}
              className={index > 5 && !tagsVisible ? 'hide' : ''}
              onClick={() => onChange(item)}
            />
          ))}
          <ShowTagsButton isOpen={tagsVisible} onClick={toggleTagsVisible}>
            <Svg icon={forwardIcon} />
          </ShowTagsButton>
        </>
      )}
    </Wrapper>
  )
}

export default Tags
