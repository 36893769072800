import styled from 'src/styles'

import Svg from 'src/components/svg'
import { Typography, TypographyView } from 'src/components/typography'
import { Button, ButtonSize, ButtonView } from 'src/components/button'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.background.base};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AppIcon = styled(Svg)`
  margin-bottom: -23px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Reg40,
})`
  margin-bottom: 12px;
`

export const Text = styled(Typography).attrs({
  view: TypographyView.Bold16,
})`
  width: 200px;
  text-align: center;

  color: ${(props) => props.theme.palette.label.secondary};

  margin-bottom: 48px;
`

export const CTAButton = styled(Button).attrs({
  view: ButtonView.CTA,
  size: ButtonSize.Large,
})`
  width: 200px;
`
