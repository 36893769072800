import React from 'react'

import { observer } from 'mobx-react'

import { IStyle } from 'src/models/library/style'
import { StyleCell, StyleCellState } from './style-cell'
import { Styles, StylesTitle, Wrapper } from './styles'
import { StyleType } from 'src/constants/styles'

export const SCROLL_TO_ID = 'target_scroll_id'
export const PRISMA_CLASSIC_NAME = 'PRISMA CLASSIC'

export interface StyleBlockProps {
  name: string

  styles: IStyle[]
  selected: IStyle | null
  inactive: boolean
  loggedIn: boolean

  onSelect: (style: IStyle) => void

  paidPlan?: unknown | string
}

export const StyleBlock: React.FC<StyleBlockProps> = ({
  name,
  styles,
  selected,
  inactive,
  onSelect,
  loggedIn,
  paidPlan,
}) => {
  return (
    <Wrapper id={name === PRISMA_CLASSIC_NAME ? SCROLL_TO_ID : undefined}>
      {name && <StylesTitle>{name}</StylesTitle>}
      <Styles>
        {styles.map((style, index) => {
          return (
            <StyleCell
              key={index}
              state={
                selected?.id === style.id
                  ? StyleCellState.Selected
                  : (!loggedIn || !paidPlan) && style.type === StyleType.v1
                  ? StyleCellState.Locked
                  : StyleCellState.Default
              }
              style={style}
              inactive={inactive}
              onClick={onSelect}
              smallLabel={style.artwork.length > 13}
            />
          )
        })}
      </Styles>
    </Wrapper>
  )
}

export default observer(StyleBlock)
