import styled from 'src/styles'
import Masonry from 'react-masonry-css'

export const Wrapper = styled.div`
  overflow-y: overlay;
  padding: 0 202px;

  scrollbar-width: thin;
  scrollbar-color: #d6d6d6 transparent;

  // this trick allows to animate the scroll thumb transparency
  color: transparent;
  transition: color 300ms ease-out;

  &:hover,
  &:focus,
  &:focus-within {
    scrollbar-color: #d6d6d6;
    color: #d6d6d6;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    height: 10px;
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    background: inherit;
    border-radius: 10px;
  }

  @-moz-document url-prefix() {
    & {
      overflow-y: scroll;
      scrollbar-color: transparent transparent;

      &:hover,
      &:focus,
      &:focus-within {
        scrollbar-color: #d6d6d6 transparent;
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 0 12%;
  }
`

export const UnsplashThumbWrapper = styled.div`
  width: 100%;
  max-width: 279px;
  height: fit-content;
`

export const UnsplashThumbContent = styled.div`
  position: relative;
`

export const UnsplashThumb = styled.img`
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  display: block;
`

export const UnsplashCover = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.black[64]};
}

  & > svg {
    fill: ${({ theme }) => theme.palette.white[100]};
  }
`

export const PaginationWrapper = styled.div`
  grid-column: 1 / 4;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
`

export const ColWrap = styled.div`
  display: grid;
  gap: 40px;
  height: fit-content;
`
export const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -40px;
  padding-top: 26px;
  width: auto;

  .my-masonry-grid_column {
    padding-left: 40px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    &:not(:first-child) {
      padding-top: 40px;
    }
  }
`
