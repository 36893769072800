import React, { Component } from 'react'

import {
  Content,
  SpinnerWrapper,
  Spinner,
  SpinnerStick,
  Title,
  Wrapper,
} from './styles'

class LoaderProcessing extends Component<{
  imageWidth: number
  imageHeight: number
}> {
  render() {
    return (
      <Wrapper
        imageWidth={this.props.imageWidth}
        imageHeight={this.props.imageHeight}
      >
        <Content>
          <SpinnerWrapper>
            <Spinner>
              {[...Array(8)].map((x, i) => (
                <SpinnerStick key={i} num={i} />
              ))}
            </Spinner>
          </SpinnerWrapper>
          <Title>Processing…</Title>
        </Content>
      </Wrapper>
    )
  }
}

export default LoaderProcessing
