export const EDITOR = {
  // Отправляется после окончания процессинга стиля или HD (или ошибке в применении, если такая была).
  // В Амплитуду отправляется один раз за сессию первый успешный процессинг, в остальные системы — каждый процессинг, включая ошибки
  // + в user_properties параметр modified_photos увеличиваем на 1 (исключая обработку example photos).
  // photo_id: айдишник фотографии, меняется с новой загруженной фотографией;
  // face: число лиц на фото — без лиц, одно или больше одного; если будет vision;
  // style_name: название примененного стиля;
  // collection_name: коллекция примененного стиля;
  // style_type: стиль платный или бесплатный;
  // style_source: был применен напрямую из библиотеки, из списка последних стилей или из поиска;
  // processing_success: true — стиль применился к фотографии, false — стиль не применился к фотографии, произошла ошибка; при этом отправляется error_show с параметрами ошибки;
  // hd_quality: процессинг стиля в HD или нет.
  photoProcess: 'photo_process',

  // Отправляется после успешного сохранения фото из редактора.
  // photo_id: айдишник фотографии, меняется с новой загруженной фотографией;
  // face: число лиц на фото — без лиц, одно или больше одного; если будет vision;
  // style_name: название примененного стиля;
  // collection_name: коллекция примененного стиля;
  // style_type: стиль платный или бесплатный;
  // style_source: был применен напрямую из библиотеки, из списка последних стилей или из поиска;
  // styles_applied: число процессингов стилей до сохранения;
  // hd_quality: фото сохранено с HD-разрешением стиля или нет.
  editorSaveTap: 'editor_save_tap',

  // Нажатие на кнопку HD.
  // photo_id: id
  editorHdTap: 'editor_hd_tap',

  // Отправлять после применения настроек интенсивности к фото. Если ползунок не использовался (не было процессинга с новой интесивностью), то ивент не отправляется.

  // photo_id: айдишник фотографии, меняется с новой загруженной фотографией;
  // intensity_value: значение интенсивности при процессинге после изменения ползунка;
  // default_intensity:
  //   false, если intensity_value не равно изначальной интенсивности стиля;
  //   true, когда до этого подвигали ползунок, применилось другое значение интенсивности, а потом вернули изначальное значение интенсивности стиля.
  //     Например, исходное значения для стиля — 100: сначала сдвинули ползунок на 50, прошел процессинг, получили editor_intensity_slider(intensity_value=50, default_intensity=false);
  //     потом сдвинули ползунок обратно на 100, прошел процессинг, получили editor_intensity_slider(intensity_value=100, default_intensity=true).
  //     Если не было процессинга с новой интенсивностью, то ивент не отправляется.
  editorIntensitySlider: 'editor_intensity_slider',

  // Результаты выдачи поисковых запросов стилей после вбивания каждой новой буквы. Логировать запросы после 3х введенных букв или первого тега. Отправлять только в Фаербейз.
  // photo_id: айдишник фотографии, меняется с новой загруженной фотографией;
  // search_text: текст поискового запроса;
  // styles_count: количество отданных стилей;
  // styles_names: названия стилей, отдавать первые 5 стилей поисковой выдачи;
  // tags_used: true, в поисковом запросе введены теги; false, если поисковый запрос вводили вручную.
  librarySearchResult: 'library_search_result',

  // Нажатие на строку поиска или на тег.
  // Логировать запросы после 3х введенных букв или первого тега.
  // Один раз на photo_id.
  librarySearchTap: 'library_search_tap',
}

export default EDITOR
