import { Wrapper } from 'src/components/pagination/styles'
import styled from '../../../styles'

export const PageableStyleBlockWrapper = styled.div`
  grid-column: 1 / 4;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  ${Wrapper} {
    margin-top: 0;
  }
`
