import React from 'react'

import Svg from 'src/components/svg'

import { IconProps } from './types'
import { IconWrapper } from './styles'

/**
 * Компонент рендера SVG-иконок
 * @param className string - css-класс
 * @param icon string - название иконки
 * @param disabled - html-атрибут дизейбла
 * @param onClick - родительский callback
 * @constructor
 */
export const Icon: React.FC<IconProps> = ({
  className,
  icon,
  disabled,
  onClick,
}) => {
  return (
    <IconWrapper
      className={className}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
    >
      <Svg icon={icon} />
    </IconWrapper>
  )
}

export default Icon
