import styled from 'src/styles'
import { flicker } from 'src/containers/library/style-block/style-cell/styles'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 800px;
  padding: 40px 200px 52px;
`

export const InputPlaceholder = styled.div`
  height: 40px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.base};
  animation: ${flicker} 1s linear infinite;
`

export const StyleWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;

  gap: 6px 6px;
`

export const StylePlaceholder = styled.div`
  height: 150px;
  border-radius: 4px;

  background-color: ${(props) => props.theme.palette.background.base};
  animation: ${flicker} 1s linear infinite;
`
