import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'

import InputSearch from '../../components/input-search'
import Tags from './tags'
import BaseView from './base-view'
import SearchView from './search-view'
import PlaceholderView from './placeholder-view'

import { Wrapper, SearchWrapper, LibraryWrapper } from './styles'
import { EditorImageState } from '../../constants/editor/image'

export type LibraryProps = ReturnType<typeof storesToProps>

/**
 * Библиотека фильтров Призма
 */
class Library extends Component<LibraryProps> {
  public componentDidMount() {
    const { loadStyles, loadTags } = this.props.library
    loadStyles()
    loadTags()
  }

  public render() {
    const {
      library,
      isAllLoaded,
      editor: { image: editorImage },
    } = this.props

    return isAllLoaded ? (
      <Wrapper disabled={editorImage.state === EditorImageState.initial}>
        <SearchWrapper>
          <InputSearch
            placeholder={'Search art filters'}
            value={library.visibleSearchQuery}
            onChange={library.setSearchedQuery}
            onFocus={library.clearVisibleQuery}
            onBlur={library.restoreVisibleQuery}
          />
          <Tags
            tags={library.tags}
            onChange={library.setSearchedTags}
            query={library.searchQuery}
            searching={library.searching}
          />
        </SearchWrapper>
        <LibraryWrapper>
          {library.searchQuery.length > 0 ? <SearchView /> : <BaseView />}
        </LibraryWrapper>
      </Wrapper>
    ) : (
      <PlaceholderView />
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  library: store.library,
  editor: store.editor,
  isAllLoaded: store.isAllLoaded,
})

export default connector(storesToProps)(Library)
