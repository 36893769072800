export const IMPORT = {
  // Отправляется на каждое успешно загруженное фото отдельно
  // photo_id: айдишник фотографии, меняется с новой загруженной фотографией;
  // photo_source: local_file, unsplash, example - откуда была импортирована фотография;
  // source: onboarding, editor - импорт фото из экрана онбординга «Quick Start» или из редактора.
  // в user_properties параметр uploaded_photos увеличиваем на 1 (исключая открытие example photos).
  importSuccess: 'import_success',

  // Результаты выдачи поисковых запросов фото после вбивания каждой новой буквы.
  // Логировать запросы после 3х введенных букв.
  // Отправлять только в Фаербейз.
  // search_text: текст поискового запроса;
  // photos_count: количество отданных фото;
  unsplashSearchResult: 'unsplash_search_result',

  // Открытие поп-апа с загрузкой фото (фид из Ансплэша и кнопка загрузки локального файла).
  // source: onboarding, editor
  importPopupShow: 'import_popup_show',
}

export default IMPORT
