import React, { useEffect, useState } from 'react'
import {
  Stripe as StripeType,
  StripeElements as StripeElementsType,
} from '@stripe/stripe-js/types'
import {
  QuickPaymentWrapper,
  StyledPaymentRequestButtonElement,
  Text,
} from './styles'
import { ICreateSubscriptionOptions } from '../../../../api/sections/subscriptions/types'

interface IQuickPaymentButtonProps {
  stripe: StripeType
  elements: StripeElementsType
  getQuickPaymentLoad: () => void
  planId: string
  plan: string
  price: number
  purchase: (
    options: ICreateSubscriptionOptions,
    planId: string
  ) => Promise<any>
}

export const QuickPaymentButton: React.FC<IQuickPaymentButtonProps> = ({
  stripe,
  elements,
  getQuickPaymentLoad,
  planId,
  purchase,
  plan,
  price,
}: IQuickPaymentButtonProps) => {
  const [paymentRequestResult, setPaymentRequest] = useState(null)

  useEffect(() => {
    if (!stripe || !elements) return

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `Prisma ${plan} subscription`,
        amount: price * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(paymentRequest)
      } else {
        getQuickPaymentLoad()
      }

      paymentRequest.on('paymentmethod', async (e) => {
        // await purchase(
        //   {
        //     // @ts-ignore
        //     card: { ...e.paymentMethod?.card, type: 'card' },
        //     billing_details: {
        //       email: e.payerEmail,
        //       name: e.payerName,
        //     },
        //     type: 'card',
        //   },
        //   planId
        // )

        // @ts-ignore
        await purchase(e, planId)
      })
    })
  }, [stripe, elements])

  return (
    <>
      {paymentRequestResult && (
        <QuickPaymentWrapper>
          <StyledPaymentRequestButtonElement
            options={{ paymentRequest: paymentRequestResult }}
            onReady={getQuickPaymentLoad}
          />
          <Text>Or enter payment details</Text>
        </QuickPaymentWrapper>
      )}
    </>
  )
}

export default QuickPaymentButton
