import React from 'react'

import { Platform } from 'src/constants/platforms'
import { STORE_LINKS } from 'src/constants/links'

import appStore from 'assets/images/stores/app_store_mono.svg'
import googlePlay from 'assets/images/stores/google_play_mono.svg'

import { StoresWrapper, StoreButton, StoreIcon } from './styles'
import { Title } from '../styles'

export const StoresBlock: React.FC<{
  appStoreTap: () => void
  googlePlayTap: () => void
}> = (props) => (
  <>
    <Title>Download Prisma mobile app</Title>
    <StoresWrapper>
      <StoreButton
        onClick={props.appStoreTap}
        target="_blank"
        href={STORE_LINKS[Platform.ios]}
      >
        <StoreIcon icon={appStore} />
      </StoreButton>
      <StoreButton
        onClick={props.googlePlayTap}
        target="_blank"
        href={STORE_LINKS[Platform.android]}
      >
        <StoreIcon icon={googlePlay} />
      </StoreButton>
    </StoresWrapper>
  </>
)

export default StoresBlock
