import styled from '../../styles'
import { Button } from 'src/components/button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 940px;
  margin: 0 auto;
  padding: 57px 20px 20px;
  overflow-y: auto;
`

export const HeroImg = styled.img`
  width: 100%;
  height: 320px;
`

export const ButtonStart = styled(Button)`
  margin: 32px auto 51px;
  padding: 13px 31px;

  flex-shrink: 0;
`

export const FooterWrapper = styled.footer`
  margin-top: auto;
`
