import { flow, Instance } from 'mobx-state-tree'

import API from 'src/api'
import { getErrorMessage } from 'src/utils/errors'
import { AuthMethod } from 'src/constants/authentication'
import { INTERNAL_ERROR } from 'src/constants/errors'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'

import { BaseAndAfterAuthModel } from '../index'
import logs from 'src/utils/logs'

export default (self: Instance<typeof BaseAndAfterAuthModel>) => ({
  setAllowEmailSend(allow: boolean) {
    self.allowEmailSend = allow
  },
  authByEmail: flow(function* (email: string) {
    try {
      self.setError('')

      self.userId = yield API.auth.authByEmail({ email })
      self.rootStore.session.setUserId(self.userId)

      self.email = email

      self.startTimer()

      self.openConfirmModal()
    } catch (e) {
      const error = getErrorMessage(e)
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        type_error: 'email authentication error',
        type_text: error,
        source: AEventSource.signInPopup,
      })
      self.setError(error)
    }
  }),
  authByGoogle: flow(function* (code) {
    try {
      self.setError('')

      const { userId, email, token } = yield API.auth.authByGoogle({ code })
      self.email = email

      self.rootStore.session.setSession(userId, token, AuthMethod.google)

      yield self.postAuthActions(AuthMethod.google)
    } catch (e) {
      const error = getErrorMessage(e)
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: 'google authentication error',
        error_text: error,
        source: AEventSource.signInPopup,
      })
      self.setError(error)
    }
  }),
  authByApple: flow(function* ({ error, userId, token }) {
    try {
      self.setError('')
      self.loadingApple = true

      if (error) {
        logs.error('Apple auth error: ' + error)
        if (error !== INTERNAL_ERROR) {
          self.setError(error)
        }
        yield self.rootStore.account.init()
        return
      }

      self.rootStore.session.setSession(userId, token, AuthMethod.apple)

      yield self.postAuthActions(AuthMethod.apple)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      AnalyticsController.sendEventWrapper(EVENT_NAMES.GENERAL.errorShow, {
        error_type: 'apple authentication error',
        error_text: errorMessage,
        source: AEventSource.signInPopup,
      })
      self.setError(errorMessage)
    } finally {
      self.loadingApple = false
    }
  }),
})
