import React, { Component } from 'react'

import { Icon } from 'src/components/icon'
import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'

import ButtonUpload from '../button-upload'
import closeSvg from 'assets/icons/close.svg'

import {
  ExampleImage,
  ExamplesWrapper,
  SubTitle,
  Title,
  Wrapper,
  QuickStartWrapper,
  CloseWrapper,
  ExampleImageWrapper,
} from './styles'
import { AnalyticsController } from '../../../utils/analytics'
import EVENT_NAMES from '../../../constants/analytics/event-names'
import { AEventSource } from '../../../constants/analytics/event-sources'

export type IInitialScreenProps = ReturnType<typeof storesToProps>

class InitialScreen extends Component<IInitialScreenProps> {
  render() {
    const {
      account,
      editor,
      onboarding,
      editor: { exampleImages },
    } = this.props

    return (
      <Wrapper>
        {onboarding.isFloatBlockVisible && (
          <QuickStartWrapper>
            <CloseWrapper>
              <Icon icon={closeSvg} onClick={onboarding.onFloatBlockClose} />
            </CloseWrapper>
            <Title>Quick start with example photos</Title>

            <ExamplesWrapper>
              {exampleImages.images.map((image, index) => (
                <ExampleImageWrapper
                  key={index}
                  onClick={() => {
                    editor.uploadExampleImage(index)
                    AnalyticsController.sendEventWrapper(
                      EVENT_NAMES.IMPORT.importSuccess,
                      {
                        photo_id: editor.image.id,
                        photo_source: 'example',
                        source: AEventSource.onboarding,
                      }
                    )
                  }}
                >
                  <ExampleImage src={image.src} alt={`Example ${index}`} />
                </ExampleImageWrapper>
              ))}
            </ExamplesWrapper>
          </QuickStartWrapper>
        )}

        <SubTitle>
          {onboarding.isFloatBlockVisible && '...or'} drag and drop image
          anywhere on
          <br />
          this page to upload it
        </SubTitle>

        <ButtonUpload
          canUpload={account.canUploadImage}
          onUpload={editor.uploadImage}
          onClick={(openHandler) =>
            editor.onDoesNotHaveUploadPermission(
              openHandler,
              AEventSource.onboarding
            )
          }
          afterUpload={() =>
            AnalyticsController.sendEventWrapper(
              EVENT_NAMES.IMPORT.importSuccess,
              {
                photo_id: editor.image.id,
                photo_source: 'local_file',
                source: AEventSource.onboarding,
              }
            )
          }
        />
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  account: store.account,
  editor: store.editor,
  onboarding: store.onboarding,
})

export default connector(storesToProps)(InitialScreen)
