import { Environment } from '../environments'
import { PaymentPeriod, PlanAlias } from '../payment-flow'

export enum SubType {
  Active = 'active',
  Canceled = 'canceled',
}

// PLAN = 29.99/yearly
export const PLAN =
  ENV !== Environment.Prod
    ? 'plan_FvHNSrCTAc06Ek'
    : 'price_1IqFMFClNwQVdAgY6Rk5UeKr'

const STRIPE_ENTITIES_CONFIGURATIONS = {
  [Environment.Prod]: {
    STRIPE_KEY: 'pk_live_Id6DiZ1OJHW4IKEgkVKRFtXa00bMr86Xzh',
    [PaymentPeriod.yearly]: {
      // backup in comments
      // [PlanAlias.pro]: 'price_1IlvUeClNwQVdAgYnSokRxc0',
      // [PlanAlias.creator]: 'price_1IqFMFClNwQVdAgY6Rk5UeKr',
      [PlanAlias.pro]: 'price_1K42vGClNwQVdAgYhSQJ4Ec5',
      [PlanAlias.creator]: 'price_1K3zAGClNwQVdAgYtEz8JLSj',
    },
    [PaymentPeriod.monthly]: {
      // [PlanAlias.pro]: 'price_1IlvUeClNwQVdAgYnSokRxc0',
      // [PlanAlias.creator]: 'price_1IlvUeClNwQVdAgYnSokRxc0',
      [PlanAlias.pro]: 'price_1K42utClNwQVdAgYSV1yfacI',
      [PlanAlias.creator]: 'price_1K3iaoClNwQVdAgYTwmsPJNh',
    },
  },
  [Environment.Stage]: {
    STRIPE_KEY: 'pk_test_SfjL3znhIgc9q9Srn1oxeSNA00u9X66wFF',
    [PaymentPeriod.yearly]: {
      [PlanAlias.pro]: 'price_1K6XsmClNwQVdAgY1Lj3mZ92',
      [PlanAlias.creator]: 'price_1K6Xr9ClNwQVdAgYcfmek6Ls',
    },
    [PaymentPeriod.monthly]: {
      [PlanAlias.pro]: 'price_1K6XryClNwQVdAgYuV7lvjux',
      [PlanAlias.creator]: 'price_1K6XpxClNwQVdAgYVXg7fzgd',
    },
  },
  [Environment.Dev]: {
    STRIPE_KEY: 'pk_test_SfjL3znhIgc9q9Srn1oxeSNA00u9X66wFF',
    [PaymentPeriod.yearly]: {
      [PlanAlias.pro]: 'price_1K6XsmClNwQVdAgY1Lj3mZ92',
      [PlanAlias.creator]: 'price_1K6Xr9ClNwQVdAgYcfmek6Ls',
    },
    [PaymentPeriod.monthly]: {
      [PlanAlias.pro]: 'price_1K6XryClNwQVdAgYuV7lvjux',
      [PlanAlias.creator]: 'price_1K6XpxClNwQVdAgYVXg7fzgd',
    },
  },
}

export const STRIPE_ENTITY_CONFIGURATION = STRIPE_ENTITIES_CONFIGURATIONS[ENV]

// Available payment types
export const PAYMENT_METHOD_TYPES = 'card'

// Trial
export enum TrialType {
  none = 'none',
  free = 'free',
  paid = 'paid',
}

export enum PAYMENT_INTENT_STATUSES {
  succeeded = 'succeeded',
  canceled = 'canceled',
  processing = 'processing',
  error = 'error',
  requiresAction = 'requires_action',
  requiresCapture = 'requires_capture',
  requiresConfirmation = 'requires_confirmation',
  requiresPaymentMethod = 'requires_payment_method',
}
