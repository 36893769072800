import { IV2GetLibraryAllResponseCollection } from '../../api/sections/styles/types'

export enum StyleType {
  Server = 'server',
  Local = 'local',
  v1 = 'v1',
  v2 = 'v2',
}

export const LOCAL_STYLES_BLOCK: IV2GetLibraryAllResponseCollection = {
  id: 'id.style.local_block',
  name: 'Prisma Essentials',
  styles: [
    {
      id: 'dd794121-fa42-401f-b8b8-5ccb4da9b555',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/dd794121-fa42-401f-b8b8-5ccb4da9b555/9f77b4e6b9f045e554c6144b18b6b1fb',
      artwork: 'Believe',
      model: 'dd794121-fa42-401f-b8b8-5ccb4da9b555',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '861252ce-ff0a-4dab-8633-e5fc56851063',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/861252ce-ff0a-4dab-8633-e5fc56851063/628f729feafcb090e40a2c3c1eafa7ab',
      artwork: 'Camelcade',
      model: '861252ce-ff0a-4dab-8633-e5fc56851063',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '394c8d6b-48dd-46ff-9afc-f551a2e39275',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/394c8d6b-48dd-46ff-9afc-f551a2e39275/4f0bee035e29323e208fbb862f437a70',
      artwork: 'Pepperoni',
      model: '394c8d6b-48dd-46ff-9afc-f551a2e39275',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '450b21f7-9603-447a-8dfd-e224a5e1ecd8',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/450b21f7-9603-447a-8dfd-e224a5e1ecd8/823407987e3707e9277419c9a2f99c1b',
      artwork: 'Cosiness',
      model: '450b21f7-9603-447a-8dfd-e224a5e1ecd8',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '584ff12e-697a-4313-b5ae-d2b10f55c984',
      image_url:
        'https://styles-cdn-stg.neuralprisma.com/covers/15c064f8-311c-4ab2-8a1f-fa29733d2534.jpg',
      artwork: 'Wind',
      model: '584ff12e-697a-4313-b5ae-d2b10f55c984',
      models: [],
      type: StyleType.Local,
      paid: false,
    },
    {
      id: '60ac08c1-819e-4fab-8a3f-596d022f7c97',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/60ac08c1-819e-4fab-8a3f-596d022f7c97/951a696b2441157ae035984d5ea53fb8',
      artwork: 'Snowfall',
      model: '60ac08c1-819e-4fab-8a3f-596d022f7c97',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '5762e0e1-5f55-4078-9fa1-765b722b67ff',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/5762e0e1-5f55-4078-9fa1-765b722b67ff/d48e8cd14660719994725c226ee49de3',
      artwork: 'Passion fruit',
      model: '5762e0e1-5f55-4078-9fa1-765b722b67ff',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: 'e0d1f915-fb90-42ff-8ecc-ee304ec9a048',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/e0d1f915-fb90-42ff-8ecc-ee304ec9a048/29bfa654cd1ed992d8d825743a05d954',
      artwork: 'Storm',
      model: 'e0d1f915-fb90-42ff-8ecc-ee304ec9a048',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '246ed15e-e6d3-463e-89bd-78ee549fcbbf',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/246ed15e-e6d3-463e-89bd-78ee549fcbbf/d7620b7e0bfb60318b65db171c4af32a',
      artwork: 'Kick scooter',
      model: '246ed15e-e6d3-463e-89bd-78ee549fcbbf',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: 'ffb74b74-e5ac-4903-9e5e-3f692f74fa78',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/ffb74b74-e5ac-4903-9e5e-3f692f74fa78/1044e0e5a54f24452733e6a3e89249c7',
      artwork: 'Lizard',
      model: 'ffb74b74-e5ac-4903-9e5e-3f692f74fa78',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: 'c120bfe1-aa52-48e4-8fa3-c59451b9ad9e',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/c120bfe1-aa52-48e4-8fa3-c59451b9ad9e/cae279866b33062badaf93443fe915c7',
      artwork: ' Pattern',
      model: 'c120bfe1-aa52-48e4-8fa3-c59451b9ad9e',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
    {
      id: '19964fdc-351b-43d0-8466-18c8a1533648',
      image_url:
        'https://prismastatic.neuralprisma.com/style/image/19964fdc-351b-43d0-8466-18c8a1533648/483da91d384b8ab33be0aa42f1dea8c5',
      artwork: 'Tiramisu',
      model: '19964fdc-351b-43d0-8466-18c8a1533648',
      models: [],
      paid: true,
      type: StyleType.Local,
    },
  ],
}
