import React, { useEffect, useState } from 'react'
import { ButtonSize, ButtonView } from 'src/components/button'
import { ResendButton, Text } from './styles'
import Loader2 from 'src/components/loader2/Loader'

export interface ResendButtonProps {
  handle: () => void
  id: string
}

export const ResendButtonComponent: React.FC<ResendButtonProps> = ({
  id,
  handle,
}) => {
  const [timer, setTimer] = useState(60)

  const resendAction = () => {
    handle()
    setTimer(60)
  }

  useEffect(() => {
    let seconds: ReturnType<typeof setTimeout>
    if (timer > 0) {
      seconds = setTimeout(() => setTimer((sec) => sec - 1), 1000)
    }

    return () => {
      if (seconds) {
        clearTimeout(seconds)
      }
    }
  }, [timer])

  return (
    <>
      {timer ? (
        <Text>
          <p>Resend login code in {timer} seconds</p>
          <Loader2 size={13} />
        </Text>
      ) : (
        <ResendButton
          id={id}
          view={ButtonView.Text}
          size={ButtonSize.Medium}
          onClick={resendAction}
          disabled={timer !== 0}
        >
          Resend code
        </ResendButton>
      )}
    </>
  )
}
