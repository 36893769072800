import { flow, Instance } from 'mobx-state-tree'

import { BaseEditor } from '../index'
import { Resolution } from '../../../constants/editor/resolution'

export default (self: Instance<typeof BaseEditor>) => ({
  uploadExampleImage: flow(function* (index: number) {
    if (self.exampleImages.selectedIndex === index) {
      return
    }

    if (self.image.uploadId) {
      self.image.resetBeforeUpload()
    }

    const image =
      // self.exampleImages.images[index] maybe just use this <-
      self.image.resolution === Resolution.HD
        ? self.exampleImages.imagesHd[index]
        : self.exampleImages.images[index]

    self.image.setExampleImage(image)
    self.exampleImages.setSelectedIndex(index)

    if (self.image.activeStyle) {
      yield self.image.process()
    }
  }),
})
