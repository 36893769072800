import React from 'react'

import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'

import { ModalName } from '../modal-root'

import {
  StyledModal,
  ErrorBody,
  ErrorTitle,
  ErrorDescription,
  ErrorImage,
} from './styles'
import closeIcon from 'assets/icons/close_new.svg'
import { ModalTitleIcon } from 'src/components/modal/styles'
import ghostImage from 'assets/images/modals/ghost.png'
import ghostImageRetina from 'assets/images/modals/ghost@2x.png'

type ModalErrorPropsType = ReturnType<typeof storesToProps> & {
  message: string
}

class ModalError extends React.Component<ModalErrorPropsType> {
  render() {
    const { modalEternal, message } = this.props
    return (
      <StyledModal width={470} closeOnOverlay={false}>
        <ModalTitleIcon
          onClick={() => modalEternal.close(ModalName.error)}
          position={'top: 20px; right: 20px;'}
          icon={closeIcon}
        />
        <ErrorBody>
          <picture>
            <source srcSet={`${ghostImageRetina} 2x, ${ghostImage} 1x`} />
            <ErrorImage src={ghostImage} width={96} height={80} />
          </picture>
          <div>
            <ErrorTitle>Processing error</ErrorTitle>

            <ErrorDescription>{message}</ErrorDescription>
          </div>
        </ErrorBody>
      </StyledModal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  modalEternal: store.modalEternal,
})

export default connector(storesToProps)(ModalError)
