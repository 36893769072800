import { flow, Instance } from 'mobx-state-tree'

import { EditorImageState } from 'src/constants/editor/image'

import { SecondImageExpansion } from '../index'

export default (self: Instance<typeof SecondImageExpansion>) => ({
  upload: flow(function* (file: File) {
    self.state = EditorImageState.uploading

    if (self.uploadId) {
      self.resetBeforeUpload()
    }

    self.file = file

    try {
      yield self.compressAndUpload()
      self.originalSrc = self.src
      self.state = EditorImageState.uploaded
    } catch (e) {
      self.state = EditorImageState.initial
      throw e
    }
  }),
})
