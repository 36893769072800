export const SETTINGS = {
  // Отправляется при успешном разлогине.
  // account_id: id;
  // source: settings, ...;
  signOutTap: 'sign_out_tap',

  // Отправляется на успешную отмену подписки, после валидации сервером.
  // как user_property передаем subscription_type = free, когда истекает срок подписки.
  // До истечения срока остается старое значение в subscription_type
  // account_id: id;
  // subscription_type: creator_monthly, creator_yearly, pro_monthly, pro_yearly;
  // source: settings, ...;
  purchaseUnsubscribe: 'purchase_unsubscribe',

  // Отправляется на успешную отмену автопродления подписки, после валидации сервером.
  //  как user_property передаем autorenew_status = false
  // account_id: id;
  // subscription_type: creator_monthly, creator_yearly, pro_monthly, pro_yearly;
  // source: settings, ...;
  purchaseCancelAutoRenewal: 'purchase_cancel_autorenewal',

  // Нажатие на кнопки со сторами.
  // В source записывается название активного экрана,
  // где были кнопки (в актуальном дизайне были только на поп-апе настроек) и в platform название платформы.
  // platform: apple, google;
  // source: settings, ...;
  downloadAppTap: 'download_app_tap',
}

export default SETTINGS
