import React from 'react'

import { withRouter } from 'react-router-dom'

import { httpClient } from 'src/api/services/axios-service'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import axios from 'axios'

type RequestInterceptorProps = ReturnType<typeof storesToProps>

class RequestInterceptor extends React.Component<RequestInterceptorProps, {}> {
  componentDidMount() {
    this.createResponseInterceptor()
  }

  public createResponseInterceptor = () => {
    const { session } = this.props

    let isRefreshing: boolean = false
    let failedQueue: Record<string, (arg0: string) => void>[] = []

    const processQueue = (error: string, token: string | null = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error)
        } else {
          prom.resolve(token)
        }
      })

      failedQueue = []
    }

    const interceptor = httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401 && session.isLoggedIn) {
          // httpClient.interceptors.response.eject(interceptor)

          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject })
            })
              .then(() => {
                error.config.headers['prisma-user-token'] = session.token
                return axios(error.config)
              })
              .catch((err) => {
                session.reset()
                return Promise.reject(err)
              })
          }

          error.config._retry = true
          isRefreshing = true

          return new Promise((resolve, reject) => {
            session
              .refreshToken()
              .then(() => {
                error.config.headers['prisma-user-token'] = session.token
                processQueue(null, session.token)
                resolve(axios(error.config))
              })
              .catch((err) => {
                processQueue(err, null)

                reject(err)
              })
              .finally(() => {
                isRefreshing = false
              })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  public render() {
    return <></>
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  session: store.session,
})

export default withRouter(connector(storesToProps)(RequestInterceptor))
