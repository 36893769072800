import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { stripe } from 'src/components/stripe'
import { InjectedCheckoutForm } from './components/card-form'

interface IStripeFormProps {
  plan: string
  price: number
  paymentPeriod: string
  onSuccess: any
}

class StripeForm extends React.Component<IStripeFormProps> {
  public render() {
    const { plan, price, paymentPeriod, onSuccess } = this.props

    return (
      <Elements stripe={stripe}>
        <InjectedCheckoutForm
          plan={plan}
          price={price}
          paymentPeriod={paymentPeriod}
          onSuccess={onSuccess}
        />
      </Elements>
    )
  }
}

export default StripeForm
