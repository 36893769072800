import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'

import ModalAccount from 'src/containers/account/modal-account'
import ModalCancelConfirm from 'src/containers/account/modal-cancel-confirm'
import ModalSignOutConfirm from 'src/containers/account/modal-sign-out-confirm'
import ModalAuthConfirm from 'src/containers/authentication/modal-auth-confirm'
import ModalAuthSign from 'src/containers/authentication/modal-auth-sign'
import ModalLoginRequired from 'src/containers/authentication/modal-login-required'
import ModalError from 'src/containers/modal-error'
import ModalOutOfImports from 'src/containers/account/modal-out-of-imports'
import ModalPaymentFlow from 'src/containers/modal-payment-flow'
import ModalUpgradeHd from 'src/containers/account/modal-upgrade-hd'

export enum ModalName {
  account = 'account',
  cancelConfirm = 'cancel-confirm',
  signOutConfirm = 'sign-out-confirm',
  loginRequired = 'login-required',
  authConfirm = 'auth-confirm',
  authSign = 'auth-sign',
  error = 'error',
  outOfImports = 'out-of-imports',
  paymentFlow = 'payment-flow',
  upgradeHD = 'upgrade-hd',
}

const MODAL_COMPONENTS = {
  [ModalName.account]: ModalAccount,
  [ModalName.cancelConfirm]: ModalCancelConfirm,
  [ModalName.signOutConfirm]: ModalSignOutConfirm,
  [ModalName.authConfirm]: ModalAuthConfirm,
  [ModalName.authSign]: ModalAuthSign,
  [ModalName.loginRequired]: ModalLoginRequired,
  [ModalName.error]: ModalError,
  [ModalName.outOfImports]: ModalOutOfImports,
  [ModalName.paymentFlow]: ModalPaymentFlow,
  [ModalName.upgradeHD]: ModalUpgradeHd,
}

export type ModalRootProps = ReturnType<typeof storesToProps>

class ModalRoot extends Component<ModalRootProps> {
  public render() {
    const { modal, modalEternal } = this.props
    const toRender = [
      ...(modal.current ? [modal.current] : []),
      ...modalEternal.content,
    ]
    return toRender.map((model) => {
      const SpecificsModal = MODAL_COMPONENTS[model.name]

      return <SpecificsModal key={model.name} {...model.props} />
    })
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  modal: store.modal,
  modalEternal: store.modalEternal,
})

export default connector(storesToProps)(ModalRoot)
