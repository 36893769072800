import { RouteComponentProps, withRouter } from 'react-router-dom'
import connector from '../../decorators/connector'
import React, { Component } from 'react'
import { IStoresMap } from 'src/types'
import { ButtonStart, HeroImg, Wrapper, FooterWrapper } from './styles'
import Header from './components/Header'
import hero from 'assets/images/onboarding/camelcade_2.jpg'
import hero2x from 'assets/images/onboarding/camelcade_2@2x.jpg'
import Title from './components/Title'
import { Footer } from 'src/components/footer'
import { ButtonSize, ButtonView } from 'src/components/button'
import QuickStart from './components/QuickStart'
import Authentication from 'src/containers/authentication'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'
import { GLOBAL_ERROR } from '../../constants/errors'
import { AEventSource } from '../../constants/analytics/event-sources'

type OnBoardingPageProps = RouteComponentProps &
  ReturnType<typeof storesToProps>

class OnBoardingPage extends Component<
  OnBoardingPageProps,
  { toExamples: boolean; examples: any }
> {
  constructor(props: OnBoardingPageProps | Readonly<OnBoardingPageProps>) {
    super(props)
    this.state = {
      toExamples: false,
      examples: props.editor.exampleImages,
    }
  }

  public changeLayout = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.setState({ toExamples: true })
    AnalyticsController.sendEventWrapper(
      EVENT_NAMES.ONBOARDING.onboardingGetStartedTap
    )
  }

  public uploadExample = (index: number) => {
    const { editor, onboarding } = this.props
    onboarding.completeFirstEnter()
    editor.uploadExampleImage(index)
    AnalyticsController.sendEventWrapper(EVENT_NAMES.IMPORT.importSuccess, {
      photo_id: editor.image.id,
      photo_source: 'example',
      source: AEventSource.onboarding,
    })
  }

  public openUnsplash = () => {
    const { onboarding, unsplash } = this.props

    onboarding.completeFirstEnter()
    unsplash.open()
  }

  public render() {
    const { toExamples, examples } = this.state

    return (
      <Wrapper>
        <Header />
        {!toExamples && (
          <div>
            <picture>
              <source srcSet={`${hero2x} 2x, ${hero} 1x`} />
              <HeroImg width={900} height={320} src={hero} alt="Hero image" />
            </picture>
            <Title />
            {/* <ButtonStart
              view={ButtonView.Primary}
              size={ButtonSize.Medium}
              onClick={this.changeLayout}
            >
              Get Started
            </ButtonStart> */}
          </div>
        )}

        {toExamples && (
          <QuickStart
            examples={examples}
            setExample={this.uploadExample}
            openUnsplash={this.openUnsplash}
          />
        )}
        <FooterWrapper>
          <Footer />
        </FooterWrapper>

        <Authentication />
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  account: store.account,
  editor: store.editor,
  session: store.session,
  onboarding: store.onboarding,
  unsplash: store.unsplash,
})

export default withRouter(connector(storesToProps)(OnBoardingPage))
