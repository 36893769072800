import React from 'react'
import { Modal } from 'src/components/modal'

import connector from 'src/decorators/connector'
import { IStoresMap } from 'src/types'
import { Body } from './styles'
import Plans from './Steps/Plans'
import Checkout from './Steps/Checkout'
import Success from './Steps/Success'
import {
  defaultPaymentPeriod,
  defaultPaymentStep,
  defaultPlan,
  PaymentPeriod,
  PaymentStep,
  PlanOptions,
} from 'src/constants/payment-flow'
import { AnalyticsController } from '../../utils/analytics'
import EVENT_NAMES from '../../constants/analytics/event-names'

export type ModalPaymentFlowProps = ReturnType<typeof storesToProps> & {
  source: string
}

class ModalPaymentFlow extends React.Component<
  ModalPaymentFlowProps,
  {
    paymentPeriod: string
    plan: string
    step: string
  }
> {
  // Test state
  constructor(props: ModalPaymentFlowProps) {
    super(props)
    this.state = {
      paymentPeriod: defaultPaymentPeriod,
      plan: defaultPlan,
      step: defaultPaymentStep,
    }

    AnalyticsController.sendEventWrapper(
      EVENT_NAMES.PURCHASE.purchaseViewShow,
      {
        source: props.source,
      }
    )
  }

  public setPaymentPeriod = (yearlySubscribe: boolean) => {
    this.setState({
      paymentPeriod: yearlySubscribe
        ? PaymentPeriod.monthly
        : PaymentPeriod.yearly,
    })
  }

  public setPlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      plan: event.target.value,
    })
  }

  public setStep = (step: string) => {
    if (step === PaymentStep.checkout) {
      AnalyticsController.sendEventWrapper(
        EVENT_NAMES.PURCHASE.purchaseCheckOutShow,
        {
          account_id: this.props.session.userID,
          subscription_type: `${this.state.plan}_${this.state.paymentPeriod}`,
          source: this.props.source,
        }
      )
    } else if (step === PaymentStep.success) {
      const price =
        PlanOptions[this.state.plan].price *
        (this.state.paymentPeriod === PaymentPeriod.monthly ? 1 : 12)
      AnalyticsController.sendEventWrapper(
        EVENT_NAMES.PURCHASE.purchaseSuccess,
        {
          account_id: this.props.session.userID,
          subscription_type: `${this.state.plan}_${this.state.paymentPeriod}`,
          subscription_price: `USD_${price}`,
          source: this.props.source,
          payment_method: 'card',
        }
      )
    }
    this.setState({ step })
  }

  public onSuccess = async () => {
    const { modal, account } = this.props
    await account.init()
    modal.resolve()
  }

  render() {
    const { modal, account } = this.props
    return null

    return (
      <Modal width={754}>
        <Body>
          {
            {
              [PaymentStep.plans]: (
                <Plans
                  isUserCreator={
                    account.subscription?.plan.toLowerCase() === 'creator'
                  }
                  plan={this.state.plan}
                  setPlan={this.setPlan}
                  paymentPeriod={this.state.paymentPeriod}
                  setPaymentPeriod={this.setPaymentPeriod}
                  onClick={() => this.setStep(PaymentStep.checkout)}
                  onClose={modal.close}
                />
              ),
              [PaymentStep.checkout]: (
                <Checkout
                  plan={this.state.plan}
                  paymentPeriod={this.state.paymentPeriod}
                  onBack={() => this.setStep(PaymentStep.plans)}
                  onSuccess={() => this.setStep(PaymentStep.success)}
                />
              ),
              [PaymentStep.success]: (
                <Success plan={this.state.plan} onClose={this.onSuccess} />
              ),
            }[this.state.step]
          }
        </Body>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  modal: store.modal,
  account: store.account,
  session: store.session,
})

export default connector(storesToProps)(ModalPaymentFlow)
