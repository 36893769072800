import React from 'react'
import styled from '../../../styles'

const MainTitle = styled.h1`
  margin-top: 60px;
  text-align: center;
  ${({ theme }) => theme.typography.objToCss('bold30')};
`

const SubTitle = styled.p`
  margin-top: 20px;
  text-align: center;
  ${({ theme }) => theme.typography.objToCss('reg16')};
`

const Title: React.FC = () => {
  return (
    <div>
      <MainTitle>Create Illustrations in One Click</MainTitle>
      <SubTitle>
        Apply art filters on photos to make your content stand out.
        <br />
        Create a unique cover image for your next blog post, <br />
        Instagram story, or YouTube thumbnail.
      </SubTitle>
    </div>
  )
}

export default Title
