import React, { Component } from 'react'

import { Wrapper, Content, Image, Title, Description } from './styles'
import ghostImage from 'assets/images/modals/ghost.png'
import ghostImageRetina from 'assets/images/modals/ghost@2x.png'
import { Button, ButtonSize, ButtonView } from 'src/components/button'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'

export type ProcessingErrorPropsType = ReturnType<typeof storesToProps> & {
  imageWidth: number
  imageHeight: number
}

class ProcessingError extends Component<ProcessingErrorPropsType> {
  public closeError() {
    const {
      editor: { image: editorImage },
      editor,
    } = this.props

    editor.setActiveStyle(editorImage?.errorStyle)
    editorImage.setRetryState()
  }

  render() {
    return (
      <Wrapper
        imageWidth={this.props.imageWidth}
        imageHeight={this.props.imageHeight}
      >
        <Content>
          <picture>
            <source srcSet={`${ghostImageRetina} 2x, ${ghostImage} 1x`} />
            <Image src={ghostImage} width={120} height={100} />
          </picture>

          <Title>Processing error</Title>

          <Description>
            Something went wrong while
            <br />
            processing your image
          </Description>

          <Button
            view={ButtonView.Secondary}
            size={ButtonSize.Medium}
            onClick={() => {
              this.closeError()
            }}
          >
            Try again
          </Button>
        </Content>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  editor: store.editor,
})

export default connector(storesToProps)(ProcessingError)
