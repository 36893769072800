import styled from 'src/styles'
import { Typography, TypographyView } from 'src/components/typography'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  padding: 40px 0 40px;
`

export const Title = styled(Typography).attrs({
  view: TypographyView.Bold20,
})`
  text-align: center;
`

export const LockImage = styled.img`
  margin-bottom: 40px;
`

export const Text = styled.div`
  ${({ theme }) => theme.typography.objToCss('reg13')};
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
`
