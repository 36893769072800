export enum AEventSource {
  header = 'header',
  footer = 'footer',

  profile = 'profile',

  styleLibrary = 'style_library',
  premiumStyle = 'premium_style',

  uploadPhoto = 'upload_photo',

  alertImportLoginRequired = 'alert_import_login_required',
  alertPaidFeature = 'alert_paid_feature',
  onboarding = 'onboarding',
  unsplash = 'unsplash',

  import = 'import',

  editor = 'editor',
  editorHd = 'editor_hd',

  signInPopup = 'sign_in_popup',
  signInPopupTryAgain = 'sign_popup_try_again',
}
