import { createApi } from 'unsplash-js'

export const unsplash = createApi({
  accessKey: 'dFk08B7BYeVxCKvST79Zsmxos5ekhjedY8Wi5LkNE3s',
})

export function getSearchedUnsplashPhotos(
  query: string,
  page: number,
  perPage: number,
  callback?: (arg0: any) => any
) {
  return unsplash.search
    .getPhotos({
      query,
      page,
      perPage,
    })
    .then((response) => (callback ? callback(response) : response))
}
