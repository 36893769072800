import styled from 'styled-components'

export const Modal = styled.div<{ className?: string }>`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${({ theme }) => theme.palette.overlay.base};

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1000;

  // Enter animations
  &.is-enter {
    opacity: 0;
    visibility: visible;
    z-index: 1000;

    .modal-content {
      opacity: 0;
    }
  }

  &.is-enter-active {
    opacity: 1;
    z-index: 1000;
    transition: 250ms ease-out;

    .modal-content {
      opacity: 1;
      transform: none;
      transition: 250ms ease-out 100ms;
    }
  }

  &.is-enter-done {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    z-index: 1000;

    .modal-content {
      opacity: 1;
      transform: none;
    }
  }

  // Exit animations
  &.is-exit {
    opacity: 1;
    visibility: visible;
    pointer-events: none;
    z-index: 1000;

    .modal-content {
      opacity: 1;
      transform: none;
    }
  }

  &.is-exit-active {
    opacity: 0;
    transition: 250ms ease-out 100ms;

    .modal-content {
      opacity: 0;
      transform: translateY(30px);
      transition: 250ms ease-out;
    }
  }

  &.is-exit-done {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -1000;

    .modal-content {
      opacity: 0;
      transform: translateY(30px);
    }
  }
`

export const Content = styled.div`
  width: 1280px;
  height: 100%;
  margin: 80px auto 0;

  background-color: ${({ theme }) => theme.palette.background.base};

  box-shadow: ${({ theme }) => theme.shadows.float};
  border-radius: 20px 20px 0 0;

  overflow: hidden;

  opacity: 0;
  transform: translateY(30px);

  position: relative;
  z-index: 100;

  &:focus {
    outline: none;
  }

  @media (max-width: 1279px) {
    width: 80%;
    min-width: 550px;
  }
`
