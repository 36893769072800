import styled from '../../../../../styles'
import { Button } from 'src/components/button'

export const ResendButton = styled(Button)`
  height: auto;
  margin: 0 auto 108px;
  padding: 0;
  color: ${(props) => props.theme.palette.label.dark};
  transition: color 300ms ease-out;
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 108px;

  ${(props) => props.theme.typography.objToCss('reg13')};
`
