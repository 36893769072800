import { css } from 'src/styles'

/**
 * Кнопки большого размера
 * Используются:
 */
export default css`
  ${({ theme }) => {
    return `
      height: 60px;
      padding: 20px 36px;
      ${theme.typography.objToCss('bold16')}
      border-radius: 10px;
    `
  }}
`
