import React from 'react'

import styled from 'src/styles'
import { Typography, TypographyView } from 'src/components/typography'
import { ButtonAppleLogin as ButtonAppleLoginBase } from 'src/components/button-apple-login'

export const ButtonAppleLogin = styled(ButtonAppleLoginBase)`
  margin-bottom: 16px;
`

export const TermsAndPrivacyMessage = styled(Typography).attrs({
  view: TypographyView.Bold10,
})`
  color: ${(props) => props.theme.palette.label.dark};

  margin-top: 40px;
  padding: 0 45px;

  text-align: center;
  line-height: 16px;
`

export const Link = styled.a`
  color: ${(props) => props.theme.palette.label.dark};
  text-decoration: underline;
`
