import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { CardNumberElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { QuickPaymentButton } from '../quick-payment-button'
import {
  FormWrapper,
  PaymentWrapper,
  CardCvc,
  CardElementsWrapper,
  CardExpiry,
  CardNumber,
  CardInput,
  PayButton,
  PayButtonWrapper,
  Title,
  ErrorMessage,
  cardStyles,
  LoaderWrapper,
} from './styles'
import {
  Stripe as StripeType,
  StripeElements as StripeElementsType,
} from '@stripe/stripe-js/types'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { PaymentPeriod, PlanAlias } from 'src/constants/payment-flow'
import {
  STRIPE_ENTITY_CONFIGURATION,
  PAYMENT_INTENT_STATUSES,
} from 'src/constants/subscription'
import { isEmailHiddenByApple } from 'src/containers/account/modal-account/user-block/utils'
import Loader2 from '../../../../components/loader2/Loader'
import { ICreateSubscriptionOptions } from '../../../../api/sections/subscriptions/types'

interface ICheckoutFormStripeProps {
  stripe: StripeType
  elements: StripeElementsType
  plan: PlanAlias
  price: number
  paymentPeriod: PaymentPeriod
  onSuccess: any
}

interface ICheckoutFormState {
  quickPaymentLoaded: boolean
  loading: boolean
  numberFilled: boolean
  expiryFilled: boolean
  cvcFilled: boolean
  name: string
  errorMessage: string | null
}

type ICheckoutFormProps = RouteComponentProps &
  ReturnType<typeof storesToProps> &
  ICheckoutFormStripeProps

class CheckoutForm extends React.Component<
  ICheckoutFormProps,
  ICheckoutFormState
> {
  constructor(props: ICheckoutFormProps) {
    super(props)

    this.state = {
      quickPaymentLoaded: false,
      loading: false,
      numberFilled: false,
      expiryFilled: false,
      cvcFilled: false,
      name: '',
      errorMessage: null,
    }
  }

  public handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value })
  }

  public isFormValid = (): boolean => {
    const { name, numberFilled, expiryFilled, cvcFilled } = this.state

    return numberFilled && expiryFilled && cvcFilled && name.length > 0
  }

  public handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.setState({ loading: true, errorMessage: null })
    const {
      elements,
      subscription,
      plan,
      paymentPeriod,
      account,
      onSuccess,
    } = this.props
    const { name } = this.state
    // @ts-ignore
    const planId = STRIPE_ENTITY_CONFIGURATION[paymentPeriod][plan]

    if (!elements) return

    const purchaseResult = await subscription.purchase(
      {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          email: account.user?.email,
          name,
        },
      },
      planId
    )

    if (purchaseResult.status === PAYMENT_INTENT_STATUSES.succeeded) {
      onSuccess()
    }

    if (purchaseResult.status === PAYMENT_INTENT_STATUSES.error) {
      this.setState({
        errorMessage:
          purchaseResult.type === 'create_subscription_error'
            ? 'Unexpected error.\n' + 'Please try again.'
            : 'Payment declined',
      })

      this.setState({ loading: false })
    }
  }

  public purchaseByQuickPayment = async (
    options: ICreateSubscriptionOptions,
    idPlan: string
  ) => {
    const { subscription, onSuccess } = this.props

    const result = await subscription.purchaseApplePay(options, idPlan)

    if (result.status === PAYMENT_INTENT_STATUSES.succeeded) {
      // @ts-ignore
      options.complete('success')
      onSuccess()
    }

    if (result.status === PAYMENT_INTENT_STATUSES.error) {
      // @ts-ignore
      options.complete('fail')
      this.setState({
        errorMessage:
          result.type === 'create_subscription_error'
            ? 'Unexpected error.\n' + 'Please try again.'
            : 'Payment declined',
      })
      this.setState({ loading: false })
    }
  }
  public render() {
    const {
      stripe,
      elements,
      price,
      account,
      session,
      plan,
      paymentPeriod,
    } = this.props
    const { name, errorMessage, quickPaymentLoaded } = this.state

    return (
      <FormWrapper>
        {!quickPaymentLoaded && (
          <LoaderWrapper>
            <Loader2 className={'loader'} />
          </LoaderWrapper>
        )}
        <PaymentWrapper quickPaymentLoaded={quickPaymentLoaded}>
          <QuickPaymentButton
            getQuickPaymentLoad={() => {
              this.setState({ quickPaymentLoaded: true })
            }}
            stripe={stripe}
            elements={elements}
            plan={plan}
            price={price}
            // @ts-ignore
            planId={STRIPE_ENTITY_CONFIGURATION[paymentPeriod][plan]}
            purchase={this.purchaseByQuickPayment}
          />

          <Title>Email</Title>
          <CardInput
            value={
              isEmailHiddenByApple(account.user?.email, session.tokenType)
                ? 'Email is hidden by Apple'
                : account.user?.email
            }
            fullWidth
            disabled
          />

          <Title>Card information</Title>
          <CardElementsWrapper>
            <CardNumber
              onChange={({ complete }) => {
                this.setState({ numberFilled: complete, errorMessage: null })
              }}
              options={{
                showIcon: true,
                iconStyle: 'solid',
                style: cardStyles,
              }}
            />
            <CardExpiry
              onChange={({ complete }) => {
                this.setState({ expiryFilled: complete, errorMessage: null })
              }}
              options={{ style: cardStyles }}
            />
            <CardCvc
              onChange={({ complete }) => {
                this.setState({ cvcFilled: complete, errorMessage: null })
              }}
              options={{ style: cardStyles }}
            />
          </CardElementsWrapper>

          <Title>Name on card</Title>
          <CardInput
            placeholder="John Smith"
            value={name}
            onChange={this.handleNameChange}
            fullWidth
          />

          <PayButtonWrapper>
            <PayButton
              isLoading={this.state.loading}
              onClick={(event) => {
                this.handleSubmit(event)
              }}
              fullWidth
              disabled={!this.isFormValid()}
            >
              Pay ${price}
            </PayButton>

            {errorMessage && (
              <ErrorMessage>
                <span>{errorMessage}</span>
              </ErrorMessage>
            )}
          </PayButtonWrapper>
        </PaymentWrapper>
      </FormWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  subscription: store.subscription,
  authentication: store.authentication,
  account: store.account,
  session: store.session,
})

const CheckoutFormWithProps = withRouter(connector(storesToProps)(CheckoutForm))

export const InjectedCheckoutForm = ({
  plan,
  price,
  paymentPeriod,
  onSuccess,
}: {
  plan: string
  price: number
  paymentPeriod: string
  onSuccess: any
}) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutFormWithProps
        plan={plan}
        price={price}
        paymentPeriod={paymentPeriod}
        onSuccess={onSuccess}
        stripe={stripe}
        elements={elements}
      />
    )}
  </ElementsConsumer>
)
