import styled, { css } from 'src/styles'
import { BaseButton } from '../button/styles'

export const TagStyledSpan = css`
  ${({ theme }) => theme.typography.objToCss('bold12')}
  display: inline-block;
  padding: 5px 8px;
  color: ${({ theme }) => theme.tag.inactiveText};
  background-color: ${({ theme }) => theme.tag.inactiveBG};
  border-radius: 4px;
  white-space: nowrap;
`

export const TagWrapper = styled(BaseButton)<{ active: boolean }>`
  ${TagStyledSpan};
  ${({ active, theme }) => {
    if (active) {
      return `
        color: ${theme.tag.activeText};
        background-color: ${theme.tag.activeBG};
      `
    }
  }}

  &.hide {
    display: none;
  }
`
