import React, { Component } from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'

import { Modal } from 'src/components/modal'
import { ModalName } from 'src/containers/modal-root'

import closeIcon from 'assets/icons/close_new.svg'

import StoresBlock from './stores-block'
import SubscriptionBlock from './subscription-block'
import UserBlock from './user-block'

import { ModalBlock, ModalBody, ModalClose, ModalTitle } from './styles'
import { AnalyticsController } from '../../../utils/analytics'
import EVENT_NAMES from '../../../constants/analytics/event-names'

export type ModalAccountProps = ReturnType<typeof storesToProps>

class ModalAccount extends Component<ModalAccountProps, {}> {
  public handleCancel = () => {
    const { modal } = this.props
    modal
      .open(ModalName.cancelConfirm)
      .then(() => {
        AnalyticsController.sendEventWrapper(
          EVENT_NAMES.SETTINGS.purchaseCancelAutoRenewal,
          {
            account_id: this.props.session.userID,
            subscription_type: this.props.account.subscription?.plan,
            source: 'settings',
          }
        )
      })
      .catch(() => modal.open(ModalName.account))
  }

  public handleUpgrade = async () => {
    const { subscription } = this.props
    await subscription.openPurchaseModalFromSubscription('settings')
  }

  downloadAppTap(platform: 'google' | 'apple') {
    AnalyticsController.sendEventWrapper(EVENT_NAMES.SETTINGS.downloadAppTap, {
      platform,
      source: 'settings',
    })
  }

  public render() {
    const { modal, account } = this.props

    return (
      <Modal width={420} onClose={modal.close}>
        <ModalBody>
          <ModalTitle>Settings</ModalTitle>
          <ModalClose icon={closeIcon} onClick={modal.close} />
          <ModalBlock>
            <UserBlock />
          </ModalBlock>
          <ModalBlock>
            <SubscriptionBlock
              counter={account.realCounter}
              subscription={account.subscription}
              onCancel={this.handleCancel}
              onUpgrade={this.handleUpgrade}
            />
          </ModalBlock>
          <ModalBlock>
            <StoresBlock
              appStoreTap={() => this.downloadAppTap('apple')}
              googlePlayTap={() => this.downloadAppTap('google')}
            />
          </ModalBlock>
        </ModalBody>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  account: store.account,
  modal: store.modal,
  session: store.session,
  subscription: store.subscription,
})

export default connector(storesToProps)(ModalAccount)
