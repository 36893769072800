import React, { Component } from 'react'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { Wrapper, HistoryImageWrapper, HistoryImage, Cover } from './styles'
import Svg from 'src/components/svg'
import checkmark from 'assets/icons/checkmark.svg'

interface IFilterHistoryState {
  lastFilterShown: boolean
}

export type FiltersHistoryProps = ReturnType<typeof storesToProps>

class FiltersHistory extends Component<
  FiltersHistoryProps,
  IFilterHistoryState
> {
  public render() {
    const {
      editor,
      editor: { image: editorImage },
    } = this.props

    return (
      <Wrapper>
        {editor.image.activeStyleHistory.slice(-5).map((style, index) => (
          <HistoryImageWrapper key={index}>
            <HistoryImage
              key={index}
              src={style.image_url}
              alt={style.artwork}
              onClick={() => editor.setActiveStyle(style)}
            />

            {editorImage.activeStyle?.id === style.id && (
              <Cover>
                <Svg icon={checkmark} width={24} height={24} />
              </Cover>
            )}
          </HistoryImageWrapper>
        ))}
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap) => ({
  editor: store.editor,
})

export default connector(storesToProps)(FiltersHistory)
