import React from 'react'

import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { validateEmail } from 'src/utils'
import { Input } from 'src/components/input'
import { InputView } from 'src/components/input/types'
import { ButtonSize, ButtonView } from 'src/components/button'
import { AnalyticsController } from 'src/utils/analytics'
import EVENT_NAMES from 'src/constants/analytics/event-names'
import { AEventSource } from 'src/constants/analytics/event-sources'
import { AuthMethod } from 'src/constants/authentication'

import { InputWrapper, Button } from '../../styles'

export const EMAIL_ERRORS = {
  EmptyEmail: 'Enter an email address',
  IncorrectEmail: 'Incorrect email address',
}

export type EmailFormProps = ReturnType<typeof storesToProps>

export interface IEmailFormState {
  email: string
  loading: boolean
}

class EmailForm extends React.Component<EmailFormProps, IEmailFormState> {
  public constructor(props: EmailFormProps) {
    super(props)

    this.state = {
      email: '',
      loading: false,
    }
  }

  public handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value })
    this.handleEmailFocus()
    this.props.authentication.resetTimer()
  }

  public handleEmailFocus = () => {
    this.props.authentication.setError('')
  }

  public isEmailValid = (email: string): string => {
    if (email.length === 0) {
      return EMAIL_ERRORS.EmptyEmail
    }

    return validateEmail(email) ? '' : EMAIL_ERRORS.IncorrectEmail
  }

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const { authentication } = this.props

    authentication.setError('')

    // Validation
    const { email } = this.state
    const error = this.isEmailValid(email)
    if (error) {
      authentication.setError(error)
      return
    }

    AnalyticsController.sendEventWrapper(EVENT_NAMES.SIGN_IN.signInTap, {
      method: AuthMethod.email,
      source: authentication.source,
    })

    // Authorization
    try {
      this.setState({ loading: true })
      await authentication.authByEmail(email.toLowerCase())
    } catch (e) {
      this.setState({ loading: false })
      throw e
    }
  }

  public render() {
    const { authentication } = this.props
    const { email, loading } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <InputWrapper>
          <Input
            placeholder="john@gmail.com"
            label="Email"
            value={email}
            onChange={this.handleEmailChange}
            onFocus={this.handleEmailFocus}
            error={authentication.error}
            view={InputView.Square}
            fullWidth
            showError
          />
        </InputWrapper>

        <Button
          id={
            authentication.isCheckoutFlow
              ? 'prisma_checkout-flow_sign_btn-continue'
              : undefined
          }
          view={ButtonView.Primary}
          size={ButtonSize.Medium}
          isLoading={loading}
          disabled={authentication.timer !== null || !email}
          fullWidth
        >
          {authentication.timer === null
            ? 'Continue'
            : `Try again in ${authentication.timer} seconds`}
        </Button>
      </form>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
})

export default connector(storesToProps)(EmailForm)
