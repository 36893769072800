import { Instance } from 'mobx-state-tree'

import { AuthType } from 'src/constants/authentication'
import { AEventSource } from 'src/constants/analytics/event-sources'

import { FirstEditorExpansion } from '../index'
import { formatBytes } from 'src/utils/file/bytes'

export default (self: Instance<typeof FirstEditorExpansion>) => ({
  onDoesNotHaveUploadPermission(openHandler: () => void, source: string) {
    const { authentication, session, subscription, unsplash } = self.rootStore

    unsplash.close(true)

    if (session.isLoggedIn) {
      subscription.openOutOfImportsModal(source)
    } else {
      authentication.openLoginRequiredModal({
        type: AuthType.paywallUploads,
        delayedAction: openHandler,
        source,
        signInSource: AEventSource.alertImportLoginRequired,
      })
    }
  },
  async uploadImage(file: File) {
    if (!file) return

    self.rootStore.modal.close()
    self.rootStore.unsplash.close(true)

    // TODO: сделать нормальное решение без увеличения размеров оригинала
    // Для сохранения консистентности отображаемых размеров данных с учётом мержа
    await self.image.upload(file)
    self.image.setFileSize(
      formatBytes(Math.round(((await self.image.imageDataUrl).length * 3) / 4))
    )
  },
})
