import styled from '../../styles'
import { Typography, TypographyView } from '../typography'

export const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
  position: relative;
`

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  &:checked + .view::before {
    visibility: visible;
  }

  &:checked ~ .text {
    visibility: visible;
  }
`

export const View = styled.span`
  width: 28px;
  height: 28px;

  border: 2px solid ${({ theme }) => theme.palette.black[100]};
  border-radius: 100%;

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    box-sizing: border-box;
    left: 3px;
    top: 3px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.black[100]};
    visibility: hidden;
  }
`

export const Label = styled(Typography).attrs({
  view: TypographyView.Reg13,
})`
  color: ${({ theme }) => theme.palette.black[100]};
  margin-top: 5px;
  visibility: hidden;
`
