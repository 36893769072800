import styled from 'src/styles'

import { TypographyProps } from './types'

export const StyledTypography = styled.div<TypographyProps>`
  color: ${(props) => props.theme.palette.label.primary};

  ${(props) => {
    if (!props.view) {
      return ''
    }

    return props.theme.typography.objToCss(props.view)
  }}
`
