import styled from '../../styles'
import { Typography, TypographyView } from '../typography'
import Svg from '../svg'

export const Wrapper = styled.div<{
  isOpen: boolean
  top?: string
  left?: string
  right?: string
  bottom?: string
}>`
  position: absolute;
  z-index: 5;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  top: ${({ top }) => (top ? top : 'auto')};
  bottom: ${({ bottom }) => (bottom ? bottom : 'auto')};
  left: ${({ left }) => (left ? left : 'auto')};
  right: ${({ right }) => (right ? right : 'auto')};
`

export const Body = styled.div`
  position: relative;
  padding: 16px 53px 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.blue[100]};

  &:after {
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 45%;
    left: -9px;
    background-color: ${({ theme }) => theme.palette.blue[100]};
    transform: rotate(45deg);
  }
`

export const Header = styled(Typography).attrs({
  view: TypographyView.Bold13,
})<{ width?: string }>`
  width: ${({ width }) => (width ? width : 'auto')};
  margin-bottom: 3px;
  color: ${({ theme }) => theme.palette.white[100]};
  text-align: left;
`

export const Text = styled(Typography).attrs({
  view: TypographyView.Reg13,
})<{ width?: string }>`
  width: ${({ width }) => (width ? width : 'auto')};
  margin-bottom: 12px;
  color: ${({ theme }) => theme.palette.white[100]};
  text-align: left;
`

export const Close = styled(Svg)`
  position: absolute;
  top: 16px;
  right: 16px;
  fill: white;
  cursor: pointer;
`
