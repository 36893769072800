import React from 'react'
import {
  Title,
  OutOfImportsSubscriptionTitle,
  OutOfImportsBody,
  OutOfImportsImage,
  Button,
  Dismiss,
} from './styles'
import { Modal } from 'src/components/modal'
import { ModalTitleIcon } from 'src/components/modal/styles'
import bucketImage from 'assets/images/modals/bucket.png'
import bucketImageRetina from 'assets/images/modals/bucket@2x.png'
import closeIcon from 'assets/icons/close_new.svg'
import { IStoresMap } from 'src/types'
import connector from 'src/decorators/connector'
import { ButtonSize, ButtonView } from 'src/components/button'

type ModalModalOutOfImportsProps = ReturnType<typeof storesToProps>

class ModalOutOfImports extends React.Component<
  ModalModalOutOfImportsProps,
  {}
> {
  public handleUpgrade = async () => {
    const { subscription, modal } = this.props
    modal.close()
    await subscription.openPurchaseModal('alert_out_of_imports')
  }

  public render() {
    const { account, modal } = this.props

    return (
      <Modal width={420} onClose={modal.close}>
        <ModalTitleIcon
          onClick={modal.close}
          position={'top: 40px; right: 40px;'}
          icon={closeIcon}
        />
        <OutOfImportsBody>
          <picture>
            <source srcSet={`${bucketImageRetina} 2x, ${bucketImage} 1x`} />
            <OutOfImportsImage src={bucketImage} width={100} height={100} />
          </picture>

          <Title>Out of imports today</Title>
          <OutOfImportsSubscriptionTitle>
            You’ve reached the daily limit of imported photos set in your
            current plan. You can wait until tomorrow when the limit will reset.
          </OutOfImportsSubscriptionTitle>
          <Dismiss onClick={modal.close}>Dismiss</Dismiss>
        </OutOfImportsBody>
      </Modal>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  subscription: store.subscription,
  modal: store.modal,
  account: store.account,
})

export default connector(storesToProps)(ModalOutOfImports)
